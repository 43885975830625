import bootbox from "bootbox";

export const authorize = (setData) => {
    // Get the params from URL
    const urlParams = new URLSearchParams(window.location.search);
    // Get Discord Auth Code from URL
    const code = urlParams.get('code');
    // Get Discord Server ID from URL
    const serverId = urlParams.get('guild_id');
    // Hit the API to pull discord data from API
    fetch(`/api/register/auth/${code}/${serverId}`)
    .then((response) => response.json())
    .then((json) => {
        // Check the response
        if (json.success) {
            console.log(json.data);
            // Set Data
            setData(json.data);
        } else {
            window.location.href = "/register";
        }
    });
}

export const register = (form, setLoading) => {
    fetch("/api/register", {method: "POST", body: JSON.stringify(form), headers: {"Content-Type": "application/json" }})
    .then((response) => response.json())
    .then((json) => {
        // Check the response
        if (json.success) {
            // Set Data
            window.location.href = "/dashboard";
        } else {
            setLoading(false);
            return bootbox.alert(json.error);
        }
    })
    .catch((err) => {
        console.log(err);
        return bootbox.alert("Failed request", () => {
            window.location.href = "/register";
        })
    })
    
}



export const applyCoupon = (code, setCouponUsed, setDiscount, setTotal, setCouponApplied) => {
    fetch(`/api/register/coupon/${code}`, {method: "GET", headers: {"Content-Type": "application/json" }})
    .then((response) => response.json())
    .then((json) => {
        // Check the response
        if (json.success) {
            setCouponUsed(code);
            var total = 50;
            if (json.discountType === "percent") {
                setDiscount(`${json.discount}%`);
                total = total - (total * (parseInt(json.discount)/100));
            } else {
                setDiscount(`$${parseInt(json.discount)}`)
                total -= parseInt(json.discount);
            }
            setTotal(total);
            setCouponApplied(true);
        } else {
            setDiscount("");
            setCouponUsed("");
            setTotal(50);
            setCouponApplied(false);
        }
    })
    .catch((err) => {
        setDiscount("");
        setCouponUsed("");
        setTotal(50);
        setCouponApplied(false);
    })
}