import React, { useEffect, useState } from "react";
import {editUserSubmit} from "../../../Actions/Dashboard/users";

const EditUserModal = ({ mode, editUser, pullUser, pullUsers }) => {    
    const [key, setKey] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [discordId, setDiscordId] = useState("");
    const [referrals, setReferrals] = useState(0);

    useEffect(() => {
    }, [])

    useEffect(() => {
        if (editUser.key) {
            setKey(editUser.key);
            setCustomerId(editUser.customerId);
            setCustomerEmail(editUser.customerEmail);
            setDiscordId(editUser.discordId);
            setReferrals(editUser.totalReferrals);
        }
    }, [editUser])


    const submit = (event) => {
        event.preventDefault();
        var form = {
            editkey: key,
            editcustomerid: customerId,
            editcustomeremail: customerEmail,
            editdiscordid: discordId,
            editreferrals: parseInt(referrals)
        }
        editUserSubmit(form, pullUsers, pullUser, mode)
    }


    return (
        <div className="modal fade" id="editModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit User</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.6129 0.209705L1.70711 0.292893L8.07107 6.65607L14.435 0.292893C14.8256 -0.0976311 15.4587 -0.0976311 15.8492 0.292893C16.2097 0.653377 16.2375 1.22061 15.9324 1.6129L15.8492 1.70711L9.48607 8.07107L15.8492 14.435C16.2398 14.8256 16.2398 15.4587 15.8492 15.8492C15.4888 16.2097 14.9215 16.2375 14.5292 15.9324L14.435 15.8492L8.07107 9.48607L1.70711 15.8492C1.31658 16.2398 0.683418 16.2398 0.292893 15.8492C-0.0675907 15.4888 -0.0953203 14.9215 0.209705 14.5292L0.292893 14.435L6.65607 8.07107L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.623337 -0.0375504 1.12751 -0.0883879 1.5114 0.140381L1.6129 0.209705Z" fill="black"/>
                            </svg>
                        </button>
                    </div>
                    <form onSubmit={(event) => {submit(event)}} id="edit-form">
                    <div className="modal-body">
                        <div className="form-group">
                        <label for="editCstmrID" className="col-form-label">Customer ID:</label>
                        <input value={customerId} onChange={(event) => {setCustomerId(event.target.value)}} type="text" name="editcustomerid" className="form-control" id="editCstmrID" required />
                        <small className="form-text text-muted">Find your customers <a target="_blank" href="https://dashboard.stripe.com/customers">here</a> (copy the customer ID)</small>
                        </div>
                        <div className="form-group">
                        <label for="editCstmrEmail" className="col-form-label">Customer Email:</label>
                        <input value={customerEmail} onChange={(event) => {setCustomerEmail(event.target.value)}}  type="email" name="editcustomeremail" className="form-control" id="editCstmrEmail" required />
                        </div>
                        <div className="form-group">
                        <label for="editDiscID" className="col-form-label">User's Discord ID:</label>
                        <input value={discordId} onChange={(event) => {setDiscordId(event.target.value)}}  type="text" name="editdiscordid" className="form-control" id="editDiscID" />
                        <small className="form-text text-muted">If you need help on finding this click <a target="_blank" href="https://www.swipetips.com/how-to-find-your-discord-user-id/s">here</a></small>
                        </div>
                        <div className="form-group">
                        <label for="editDiscID" className="col-form-label">Total Referrals:</label>
                        <input value={referrals} onChange={(event) => {setReferrals(event.target.value)}}  type="number" min="0" name="editreferrals" className="form-control" id="editReferrals" required />
                        </div>
                        <div className="alert alert-danger" id="editErr" role="alert">
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-main-type" style={{"display":"block","margin":"0 auto","float":"none"}}>Save Changes</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    )
}

export default EditUserModal;

