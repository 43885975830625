import $ from "jquery"
import bootbox from "bootbox";
var userTable;
var invoiceTable;
var paymentTable;
export const getUsers = (setUsers, setLoading, setUser, setPage) => {
  $.get("/api/dashboard/users",
    (data, status) => {
      if (status == "success") {
        if (data.success == true) {
          setUsers(data.users);
          setLoading(false);
          createUserTable(setUser, setPage);
          return;
        } else {
          return window.location.href = "/logout";
        }
      } else {
        return window.location.href = "/logout";
      }
    });
}



const createUserTable = (setUser, setPage) => {
  if ($("#userTable").length) {
    $(".checkbox-menu").on("change", "input[type='checkbox']", function () {
      $(this).closest("li").toggleClass("active", this.checked);
    });
    $(document).on('click', '.allow-focus .dropdown-menu', function (e) {
      e.stopPropagation();
    });

    $("#userTable .clickable").on("click", function (e) {
      var user = JSON.parse($(this).parent().attr("data-identify"));
      setUser(user);
      setPage("user");
    });


    userTable = $('#userTable').DataTable({
      ordering: false,
      lengthChange: false,
      initComplete: function (settings, json) {
        $('body').find('.dataTables_scrollBody').addClass("scrollbar");
        $("#userTable").wrap("<div class='userTableScroll' style='overflow:auto; width:100%;position:relative;'></div>");
      }
    });



    $("#user-search").on("keyup change", function () {
      userTable.search(this.value).draw();
    });

    $(".checkbox-input-all-users").on("click", function () {
      var rows = document.getElementById("userTable").getElementsByTagName("tbody")[0].getElementsByTagName("tr");
      for (var i = 0; i < rows.length; i++) {
        var row = rows[i];
        row.getElementsByClassName("checkbox-input")[0].checked = document.getElementsByClassName("checkbox-input-all-users")[0].checked;
      }
    });

    
    $(document).on('click', '#userTable_previous', function(){
      var rows = document.getElementById("userTable").getElementsByTagName("tbody")[0].getElementsByTagName("tr");
      for (var i = 0; i < rows.length; i++) {
          var row = rows[i];
          row.getElementsByClassName("checkbox-input")[0].checked = document.getElementsByClassName("checkbox-input-all-users")[0].checked;
      }
    });


    $(document).on('click', '#userTable_next', function(){
        var rows = document.getElementById("userTable").getElementsByTagName("tbody")[0].getElementsByTagName("tr");
        for (var i = 0; i < rows.length; i++) {
            var row = rows[i];
            row.getElementsByClassName("checkbox-input")[0].checked = document.getElementsByClassName("checkbox-input-all-users")[0].checked;
        }
    });


  } else {
    setTimeout(() => {
      createUserTable(setUser, setPage)
    }, 50);
  }
}

export const callAddUser = () => {
  $("#addModal").modal('toggle');
}

export const addUserSubmit = (form, resetValues, pullData) => {
  document.getElementById("add-form").getElementsByClassName("btn-main-type")[0].disabled = true;
  document.getElementById("add-form").getElementsByClassName("btn-main-type")[0].innerHTML = "...";
  $.ajax({
    url: "/api/dashboard/users",
    type: "POST",
    contentType: 'application/json',
    data: JSON.stringify(form),
    success: function (data) {
      if (data.success) {
        document.getElementById("add-form").getElementsByClassName("btn-main-type")[0].disabled = false;
        document.getElementById("add-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Add User";
        $("#addModal").modal('toggle');
        resetValues();
        pullData();
        bootbox.alert("Successfully Added User");
      } else {
        $("#addErr").text(data.error);
        $("#addErr").show();
        $("#addErr").delay(5000).hide(1);
        document.getElementById("add-form").getElementsByClassName("btn-main-type")[0].disabled = false;
        document.getElementById("add-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Add User";
      }
    }
  }).fail(function (jqXHR, textStatus, error) {
    $("#addModal").modal('toggle');
    bootbox.alert("Failed request... Please try again", () => {
      window.location.reload();
    });
  });
}

export const callDeleteUsers = (pullUsers) => {
  bootbox.confirm({
    message: `Are you sure you want to delete user(s)`,
    centerVertical: true,
    buttons: {
      confirm: {
        label: 'Yes',
        className: 'btn-main-type'
      },
      cancel: {
        label: 'No',
        className: 'btn-second-type'
      }
    },
    callback: function (result) {
      if (result == true) {
        var selectedUsers = document.getElementsByClassName("checkbox-input");
        let usersArr = [];
        for (var i = 0; i < selectedUsers.length; i++) {
          if (selectedUsers[i].checked == true) {
            let row = selectedUsers[i].parentNode.parentNode.parentNode;
            var key = JSON.parse(row.getAttribute("data-identify")).key;
            usersArr.push({
              "key": key.trim("")
            });
          }
        }
        $.ajax({
          url: "/api/dashboard/users",
          type: "DELETE",
          contentType: 'application/json',
          data: JSON.stringify({
            users: usersArr
          }),
          success: function (data) {
            if (data.success) {
              pullUsers();
              bootbox.alert("Successfully Deleted User(s)");
            } else {
              bootbox.alert(data.error);
            }
          }
        }).fail(function (jqXHR, textStatus, error) {
          bootbox.alert("Failed request... Please try again");
        });
      }
      return;
    }
  });
}


export const callEditUser = (setEditUser) => {
  let selectedUsers = document.getElementsByClassName("checkbox-input");
  var selected = null;
  for (var i = 0; i < selectedUsers.length; i++) {
    if (selectedUsers[i].checked == true) {
      selected = selectedUsers[i].parentNode.parentNode.parentNode;
    }
  }
  if (selected != null) {
    var user = JSON.parse(selected.getAttribute("data-identify"));
    setEditUser(user);
    $("#editModal").modal('toggle');
  } else {
    // show error message stating to click a user in the list to edit
    bootbox.alert("Please select a user");
  }
}

export const editUserSubmit = (form, pullUsers, pullUser, mode) => {
  document.getElementById("edit-form").getElementsByClassName("btn-main-type")[0].disabled = true;
  document.getElementById("edit-form").getElementsByClassName("btn-main-type")[0].innerHTML = "...";
  $.ajax({
    url: "/api/dashboard/users",
    type: "PUT",
    contentType: 'application/json',
    data: JSON.stringify(form),
    success: function (data) {
      if (data.success) {
        document.getElementById("edit-form").getElementsByClassName("btn-main-type")[0].disabled = false;
        document.getElementById("edit-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Save Changes";
        $("#editModal").modal('toggle');
        bootbox.alert("Successfully Edited User");
        pullUsers();
        if (mode == "user") {
          pullUser();
        }
      } else {
        $("#editErr").text(data.error);
        $("#editErr").show();
        $("#editErr").delay(5000).hide(1);
        document.getElementById("edit-form").getElementsByClassName("btn-main-type")[0].disabled = false;
        document.getElementById("edit-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Save Changes";
      }
    }
  }).fail(function (jqXHR, textStatus, error) {
    $("#editModal").modal('toggle');
    bootbox.alert("Failed request... Please try again", () => {
      window.location.reload();
    });
  });
}


export const getUser = (key, setUserData, setLoading) => {
  // Make a get REQUEST
  $.get(`/api/dashboard/users/${key}`, function (data) {
    if (data.success) {
      setUserData(data.user);
      setLoading(false);
      createInvoiceTable();
      createPaymentTable();
      return;
      // $("#user-page").find(".shine").css("display", "none");
    } else {
      bootbox.alert("Failed getting data", function () {
        window.location.href = "/logout";
      })
    }
  });
}

export const createInvoiceTable = () => {
  if ($("#invoiceTable").length) {
    // if (invoiceTable) {
    //   $('#invoiceTable').DataTable().clear().destroy();
    // }
    invoiceTable = $('#invoiceTable').DataTable({
      lengthChange: false,
      ordering: false,
      initComplete: function (settings, json) {
        $('body').find('.dataTables_scrollBody').addClass("scrollbar");
        $("#invoiceTable").wrap("<div class='userTableScroll' style='margin-top:15px;overflow:auto; width:100%;position:relative;'></div>");
      }
    });

    $("#invoiceTable .clickable").on("click", function (e) {
      window.open(JSON.parse($(this).parent().attr("data-identify")).hosted_invoice_url, '_blank');
    });
    $("#invoiceTable .download").on("click", function (e) {
      window.open(JSON.parse($(this).parent().attr("data-identify")).invoice_pdf, '_blank');
    });
    // if (invoices && invoices.length > 0) {
    //   $("#invoiceTable .dataTables_empty").remove();
    // }
    return;
  } else {
    setTimeout(() => {
      createInvoiceTable()
    }, 50);
  }
}

export const createPaymentTable = () => {
  if ($("#paymentTable").length) {
    // if (paymentTable) {
    //   $('#paymentTable').DataTable().clear().destroy();
    // }
    paymentTable = $('#paymentTable').DataTable({
      lengthChange: false,
      ordering: false,
      initComplete: function (settings, json) {
        $('body').find('.dataTables_scrollBody').addClass("scrollbar");
        $("#paymentTable f").wrap("<div class='userTableScroll' style='margin-top:15px;overflow:auto; width:100%;position:relative;'></div>");
      }
    });


    $("#paymentTable .clickable").on("click", function (e) {
      window.open(`https://dashboard.stripe.com/payments/${JSON.parse($(this).parent().attr("data-identify")).id}`, '_blank');
    });

    // if (payments && payments.length > 0) {
    //   $("#paymentTable .dataTables_empty").remove();
    // }

  } else {
    setTimeout(() => {
      createPaymentTable();
    }, 50);
  }
}

export const callToggleMenu = () => {
  if ($('.dropdown-menu').hasClass("display")) {
    $('.dropdown-menu').removeClass("display")
  } else {
    $('.dropdown-menu').addClass("display")
  }
}


export const callEditSpecificUser = (user, setEditUser) => {
  setEditUser(user);
  $("#editModal").modal('toggle');
}


export const delSpecificUser = (key, pullUsers, setPage) => {
  bootbox.confirm({
    message: `Are you sure you want to delete user`,
    centerVertical: true,
    buttons: {
      confirm: {
        label: 'Yes',
        className: 'btn-main-type'
      },
      cancel: {
        label: 'No',
        className: 'btn-second-type'
      }
    },
    callback: function (result) {
      if (result == true) {
        let usersArr = [{
          key: key
        }];
        $.ajax({
          url: "/api/dashboard/users",
          type: "DELETE",
          contentType: 'application/json',
          data: JSON.stringify({
            users: usersArr
          }),
          success: function (data) {
            if (data.success) {
              bootbox.alert("Successfully Deleted User", () => {
                setPage("users");
                pullUsers();
              })
            } else {
              bootbox.alert(data.error);
            }
          }
        }).fail(function (jqXHR, textStatus, error) {
          bootbox.alert("Failed request... Please try again", () => {
            window.location.reload();
          });
        });
      }
      return;
    }
  });

}