import React, { useEffect, useState, useRef } from "react";
import { createReward } from "../../../Actions/Dashboard/rewards";
import $ from "jquery"

const CreateRewardModal = ({ pullRewards }) => {    
    const [reward, setReward] = useState("");
    const [referral, setReferral] = useState(0);
    const [applyCredits, setApplyCredits] = useState(true);
    const [credits, setCredits] = useState(0);

    useEffect(() => {
    }, [])

    const callSubmit = (event) => {
        event.preventDefault();
        if (!referral > 0) {
            $("#rewardAddErr").text("Please enter an amount greater than 0");
            $("#rewardAddErr").show();
            $("#rewardAddErr").delay(5000).hide(1);
            return;
        }
        var form = {
            reward: reward,
            referral: referral,
            applyCredits: applyCredits,
            credits: credits
        };
        createReward(form, pullRewards);
    }

    return (
        <div className="modal fade" id="addRewardModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Create Reward</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                        d="M1.6129 0.209705L1.70711 0.292893L8.07107 6.65607L14.435 0.292893C14.8256 -0.0976311 15.4587 -0.0976311 15.8492 0.292893C16.2097 0.653377 16.2375 1.22061 15.9324 1.6129L15.8492 1.70711L9.48607 8.07107L15.8492 14.435C16.2398 14.8256 16.2398 15.4587 15.8492 15.8492C15.4888 16.2097 14.9215 16.2375 14.5292 15.9324L14.435 15.8492L8.07107 9.48607L1.70711 15.8492C1.31658 16.2398 0.683418 16.2398 0.292893 15.8492C-0.0675907 15.4888 -0.0953203 14.9215 0.209705 14.5292L0.292893 14.435L6.65607 8.07107L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.623337 -0.0375504 1.12751 -0.0883879 1.5114 0.140381L1.6129 0.209705Z"
                        fill="black" />
                    </svg>
                    </button>
                </div>
                    <form onSubmit={(event) => {callSubmit(event)}} id="reward-add-form">
                        <div className="modal-body">
                        <div className="form-group">
                            <label for="reward" className="col-form-label">Reward</label>
                            <input value={reward} onChange={(event) => {setReward(event.target.value)}} placeholder="1 Month Free" type="text" name="reward" className="form-control"
                            id="reward" required />
                        </div>

                        <div className="form-group">
                            <label className="checkbox-container modal-checkbox-container">
                                <input type="checkbox" value={applyCredits} onChange={(event) => {setApplyCredits(event.target.checked)}} checked={applyCredits}/>
                                <span className="checkmark"></span>
                                <label className="col-form-label">Credits:</label>
                            </label>


                            {applyCredits === true ? (
                             <div className="input-group mb-3">
                             <div className="input-group-addon">
                                 <span className="input-group-text">$</span>
                             </div>
                             <input value={credits} onChange={(event) => {setCredits(parseFloat(event.target.value))}} min="0" placeholder="25" step=".01" type="number" name="credits" className="form-control" required />
                             </div>
                            ) : null}
                        </div>

                

                        <div className="form-group">
                            <label for="referral" className="col-form-label">Referral Amount</label>
                            <input value={referral} onChange={(event) => {setReferral(event.target.value)}} placeholder="3" type="number" min="0" name="referral"
                            className="form-control" id="referral" required />
                        </div>
                        <div className="alert alert-danger" id="rewardAddErr" role="alert">
                        </div>
                        </div>
                        <div className="modal-footer">
                            <button style={{"margin":"auto"}} type="submit" className="btn btn-main-type">Add
                                Reward</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


export default CreateRewardModal

