import bootbox from "bootbox";
import $ from "jquery"
var teamTable;

export const getTeam = (setData, setLoading) => {
    $.get("/api/dashboard/settings/team",
      (data, status) => {
        if (status == "success") {
          if (data.success == true) {
            setLoading(false);
            setData(data.accounts);
            return;
          } else {
            return window.location.href = "/logout";
          }
        } else {
          return window.location.href = "/logout";
        }
      });
}


export const createTeamTable = () => {


    teamTable = $('#teamTable').DataTable({
      ordering: false,
      lengthChange: false,
      initComplete: function (settings, json) {
          $('body').find('.dataTables_scrollBody').addClass("scrollbar");
          $("#teamTable").wrap("<div class='userTableScroll' style='overflow:auto; width:100%;position:relative;'></div>");
      }
    });

    $("#team-search").on("keyup change", function () {
        teamTable.search(this.value).draw();
    });

  }


export const callAddTeam = () => {
    $("#addTeamModal").modal('toggle');
}

export const addTeammate = (form, setEmail, pullTeam) => {
  document.getElementById("add-teammate-form").getElementsByClassName("btn-main-type")[0].disabled = true;
        document.getElementById("add-teammate-form").getElementsByClassName("btn-main-type")[0].innerHTML = "...";
        $.ajax({
            url: "/api/dashboard/settings/team",
            type: "POST",
            contentType: 'application/json',
            data: JSON.stringify(form),
            success: function (data) {
                if (data.success) {
                    $("#addTeamModal").modal('toggle');
                    setEmail("");
                    bootbox.alert("Successfully Added Teammate", () => {
                        document.getElementById("add-teammate-form").getElementsByClassName("btn-main-type")[0].disabled = false;
                        document.getElementById("add-teammate-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Invite";
                        pullTeam();
                    });
                } else {
                    $("#addTeamErr").text(data.error);
                    $("#addTeamErr").show();
                    $("#addTeamErr").delay(5000).hide(1);
                    document.getElementById("add-teammate-form").getElementsByClassName("btn-main-type")[0].disabled = false;
                    document.getElementById("add-teammate-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Invite";
                }
            }
        }).fail(function (jqXHR, textStatus, error) {
            $("#addTeamErr").text("Failed request... Please try again");
            $("#addTeamErr").show();
            $("#addTeamErr").delay(5000).hide(1);
            document.getElementById("add-teammate-form").getElementsByClassName("btn-main-type")[0].disabled = false;
            document.getElementById("add-teammate-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Invite";
        });
}

export const inviteTeammate = (form) => {
  $.ajax({
    url: `/api/dashboard/settings/team/invite`,
    contentType: 'application/json',
    data: JSON.stringify(form),
    type: "POST",
    success: function(data) {
      if (data.success) {
        bootbox.alert("Successfully Invited Teammate(s)");
      } else {
        bootbox.alert(data.error);
      }
    }
  }).fail(function(jqXHR, textStatus, error) {
    bootbox.alert("Failed request... Please try again");
  });
}

export const deleteTeammate = (form, pullTeam) => {
  $.ajax({
    url: `/api/dashboard/settings/team`,
    contentType: 'application/json',
    data: JSON.stringify(form),
    type: "DELETE",
    success: function(data) {
      if (data.success) {
        pullTeam();
        bootbox.alert("Successfully Removed Teammate(s)");
      } else {
        bootbox.alert(data.error);
      }
    }
  }).fail(function(jqXHR, textStatus, error) {
    bootbox.alert("Failed request... Please try again");
  });
}