import React, { useEffect, useState, useRef } from "react";
import CircleLoader from "../../Components/CircleLoader";
import PageNavigation from "../PageNavigation/PageNavigation";
import emojis from "./emojis.json";
import $ from "jquery"

import { connectDiscord, disconnectDiscord, submitBotSettings, submitDiscord, submitEmbeds, getBotSettings, getDiscord, getEmbeds } from "../../Actions/Dashboard/discord";

const Discord = ({ setPage, data, display, setDiscord, setEmbeds }) => {
    const [help, showHelp] = useState(false);
    const [loading, setLoading] = useState(true);
    const [embedLoading, setEmbedLoading] = useState(true);
    const [serverId, setServerId] = useState("");
    const [webhook, setWebhook] = useState("");
    const [rewardsWebhook, setRewardsWebhook] = useState("");
    const [donationsWebhook, setDonationsWebhook] = useState("");
    const [clientId, setClientId] = useState("");
    const [botStatus, setBotStatus] = useState("");
    const [welcomeMessage, setWelcomeMessage] = useState("");
    const [failedMessage, setFailedMessage] = useState("");
    const [expiredMessage, setExpiredMessage] = useState("");
    const [contact, setContact] = useState("");
    const [video, setVideo] = useState("");
    // Bot settings
    const [enableNewUserMessage, setEnableNewUserMessage] = useState(false);
    const [enableRoleReact, setEnableRoleReact] = useState(false);
    const [newUserMessageChannel, setNewUserMessageChannel] = useState("");
    const [newUserMessage, setNewUserMessage] = useState("");
    const [roleReactChannel, setRoleReactChannel] = useState("");
    const [roleReactMessage, setRoleReactMessage] = useState("React to this message to be granted the role.");
    const [roleTabValues, setRoleTabValues] = useState([]);
    const [emojiTabValues, setEmojiTabValues] = useState([]);
    const [botResp, setBotResp] = useState();


    const pullDiscord = () => {
        // getDiscord(data, setDiscord, setLoading);
        getDiscord().then((resp) => {
            setBotResp(resp.discord.botStatus);
            setServerId(resp.discord.serverId);
            setBotStatus(resp.discord.botStatus);
            setWebhook(resp.discord.webhook);
            console.log(resp.discord)
            if (resp.discord.reward_webhook) {
                setRewardsWebhook(resp.discord.reward_webhook);
            } else {
                setRewardsWebhook(resp.discord.webhook);
            }
            if (resp.discord.donations_webhook) {
                setDonationsWebhook(resp.discord.donations_webhook);
            } else {
                setDonationsWebhook(resp.discord.webhook);
            }

            setClientId(resp.discord.clientId);
            setDiscord({
                botStatus: resp.discord.botStatus,
                serverId: resp.discord.serverId,
                webhook: resp.discord.webhook,
                clientId: resp.discord.clientId
            });
            setLoading(false);
        })
        .catch((err) => {
            return window.location.href = "/login";
        });
    }

    const pullEmbeds = () => {
        getEmbeds().then((resp) => {
            setWelcomeMessage(resp.embeds.welcomeMessage);
            setFailedMessage(resp.embeds.failedMessage);
            setExpiredMessage(resp.embeds.expiredMessage);
            setContact(resp.embeds.contact);
            setVideo(resp.embeds.video);
            setEmbeds({video: resp.embeds.video, contact: resp.embeds.contact, bound: resp.embeds.welcomeMessage, failed: resp.embeds.failedMessage, expired: resp.embeds.expired})
            setEmbedLoading(false);
        })
        .catch((err) => {
            return window.location.href = "/login";
        });
    }

    const pullBotSettings = () =>{
        getBotSettings().then((resp) => {
            console.log(resp);
            if (resp.welcomeMessage && resp.welcomeChannel) {
                setEnableNewUserMessage(true);
            }
            setNewUserMessage(resp.welcomeMessage);
            setNewUserMessageChannel(resp.welcomeChannel);

            if (resp.reactions && resp.reactions.message && Object.keys(resp.reactions.roles).length > 0) {
                console.log("---------- REACTIONS EXISTS ----------------");
                setEnableRoleReact(true);
                setRoleReactMessage(resp.reactions.message);
                setRoleReactChannel(resp.reactions.channelId);
                var localEmojiValues = Object.keys(resp.reactions.roles).map(item => {return item});
                var localRoleValues = Object.keys(resp.reactions.roles).map(item => {return resp.reactions.roles[item]});
                setRoleTabValues(localRoleValues);
                setEmojiTabValues(localEmojiValues);
                // setEmojiTabValues(resp.reactions.reactions.ma);
                // Load reactions here
                // setRoleTabs(resp.reactions.reactions);
            }   
        })
        .catch((err) => {
            console.log(err);
            // return window.location.href = "/login";
        });
    }

    const callSubmitBot = (event) => {
        event.preventDefault();

        var reactToRoles = {};

        var reactions = $(".reactEmoji");
        var roles = $(".reactRole");
  
        for (var i = 0; i < reactions.length; i++) {
          if (reactions[i].value) {
              reactToRoles[reactions[i].value] = roles[i].value;
          }
        }

        var form = {
            enabled:enableNewUserMessage,
            message: newUserMessage,
            channelId: newUserMessageChannel,
            reactChannelId: roleReactChannel,
            reactMessage: roleReactMessage,
            getRoleEnabled: enableRoleReact,
            reactToRoles: reactToRoles
        };

        console.log(form);
        submitBotSettings(form, pullBotSettings);
    }

    const callSubmitDiscord = (event) => {
        event.preventDefault();
        var form = {
            serverid: serverId,
            webhook: webhook,
            reward_webhook: rewardsWebhook,
            donations_webhook: donationsWebhook
        }
        submitDiscord(form, pullDiscord);
    }

    const callSubmitEmbeds = (event) => {
        event.preventDefault();
        var form = {
            welcomeMessage: welcomeMessage,
            failedMessage: failedMessage,
            expiredMessage: expiredMessage,
            contact: contact,
            video: ""
        }
        if (video != "") {
            form.video = video;
        }
        submitEmbeds(form, pullEmbeds);
    }

    const addTabDiscord = () => {

        let id =  Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        var div = document.createElement("div");
        div.setAttribute("id", id)
        div.classList.add("form-group");
        div.style = "margin-top:15px;";
        // div.classList.add("col-md-12");
        div.innerHTML = `

            <label>Emoji Reaction:</label> 
            <select class="form-control select reactEmoji">    
            </select>

            <label style="margin-top:15px;">Role ID:</label> 
            <select class="form-control select reactRole">
               
            </select>

            
            <button onclick="document.getElementById('${id}').remove()" 
            style="margin-top:15px; text-align: center; font-size: 14px; color:#11142D; border-radius: 16px; background-color: #E4E4E4;padding: 12px 33px;"
            type="button" class="btn">Remove</button>
        `  ;
        for (var i = 0; i < emojis.length; i++) {
            var emoji = emojis[i];
            var option = document.createElement("option");
            option.value = emoji;
            option.innerHTML = emoji;
            div.getElementsByClassName("reactEmoji")[0].append(option);
        } 
        if (botResp && botResp.roles && botResp.roles.length > 0) {
            var roles = botResp.roles;
            for (var i = 0; i < roles.length; i++) {
                var role = roles[i];
                var option = document.createElement("option");
                option.value = role.id;
                option.innerHTML = role.name;
                div.getElementsByClassName("reactRole")[0].append(option);
            } 
        }

        document.getElementById("role-tabs").append(div);    
    }


    useEffect(() => {
        pullDiscord();
        pullEmbeds();
        pullBotSettings();
    }, [])

    return (
        <div id="discord" style={{display: display === true ? true : 'none'}}>
            <div className="container-fluid row">
                <div className="col-md-12">
                    <h1 className="mt-4">Connections</h1>
                    <PageNavigation pages={[{title: "Discord", active: true,  onclick: () => {} }, {title: "Payment Gateways", active: false, onclick: () => {setPage("stripe")}}, {title: "Tracking Pixels", active: false, onclick: () => {setPage("pixels")}}]} />

                    <div className="user-corner-panel">
                    <svg style={{"verticalAlign":"middle","marginRight":"10px","cursor":"pointer"}} onClick={() => {showHelp(!help)}} width="30"
                        height="30" viewdiv="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                        d="M14.8261 0C6.63787 0 0 6.63787 0 14.8261C0 23.0143 6.63787 29.6522 14.8261 29.6522C23.0143 29.6522 29.6522 23.0143 29.6522 14.8261C29.6522 6.63787 23.0143 0 14.8261 0ZM14.8261 2.69565C21.5255 2.69565 26.9565 8.12663 26.9565 14.8261C26.9565 21.5255 21.5255 26.9565 14.8261 26.9565C8.12663 26.9565 2.69565 21.5255 2.69565 14.8261C2.69565 8.12663 8.12663 2.69565 14.8261 2.69565Z"
                        fill="#808191" />
                        <path fillRule="evenodd" clipRule="evenodd"
                        d="M14.832 20.2174C15.5764 20.2174 16.1798 20.8208 16.1798 21.5652C16.1798 22.2564 15.6595 22.8261 14.9892 22.904L14.832 22.913C14.0817 22.913 13.4782 22.3096 13.4782 21.5652C13.4782 20.874 13.9986 20.3043 14.6689 20.2264L14.832 20.2174ZM18.4572 7.2585L18.6701 7.46842C20.4876 9.31629 20.4778 12.2194 18.73 14.0234L18.4132 14.332C17.9546 14.769 17.4886 15.1181 16.7977 15.6071C16.1485 16.0936 16.0071 16.3252 16.0071 17.05C16.0071 17.7944 15.4036 18.3978 14.6592 18.3978C13.9149 18.3978 13.3114 17.7944 13.3114 17.05C13.3114 15.3795 13.9064 14.4052 15.1813 13.4499L15.7904 13.0084C16.0136 12.8429 16.1825 12.7104 16.3343 12.5795L16.6725 12.2654C17.4784 11.5231 17.555 10.3359 16.883 9.50925L16.7483 9.35868L16.6144 9.2259C15.5484 8.22739 13.8659 8.20049 12.7665 9.12547L12.5889 9.28844L11.9297 9.94753C11.4033 10.4738 10.5499 10.4737 10.0236 9.94732C9.53775 9.4614 9.50046 8.69686 9.91164 8.16817L10.0238 8.0412L10.683 7.38212C12.8001 5.26548 16.2709 5.21072 18.4572 7.2585Z"
                        fill="#808191" />
                    </svg>
                    <div className="help-dropdown" style={{display: help === true ? 'block' : 'none'}}>
                        <ul>
                        <li><a target="_blank" href="https://ternarydev.notion.site/Ternary-4adf412dd4444fcb90c0a457d2a3607c">Helpful Articles</a>
                        </li>
                        <li><a target="_blank" href="https://docs.ternarydev.com/ternary-developments/change-log">Release Notes</a></li>
                        <li><a href="mailto:support@ternarydev.com">Contact Us</a></li>
                        </ul>
                    </div>


                    <a className="settings-button" onClick={() => {setPage('subscription')}}>
                        <svg style={{"verticalAlign":"middle","cursor":"pointer"}} width="30" height="30" viewdiv="0 0 30 30"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM15 2.72729C21.7781 2.72729 27.2727 8.22198 27.2727 15C27.2727 21.7781 21.7781 27.2727 15 27.2727C8.22198 27.2727 2.72729 21.7781 2.72729 15C2.72729 8.22198 8.22198 2.72729 15 2.72729Z"
                            fill="#808191" />
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M14.9999 5.45459C10.4812 5.45459 6.81812 9.11771 6.81812 13.6364C6.81812 18.1551 10.4812 21.8182 14.9999 21.8182C19.5186 21.8182 23.1818 18.1551 23.1818 13.6364C23.1818 9.11771 19.5186 5.45459 14.9999 5.45459ZM14.9997 8.18188C18.0122 8.18188 20.4543 10.624 20.4543 13.6364C20.4543 16.6489 18.0122 19.091 14.9997 19.091C11.9873 19.091 9.54517 16.6489 9.54517 13.6364C9.54517 10.624 11.9873 8.18188 14.9997 8.18188Z"
                            fill="#808191" />
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M15.0066 19.0911C19.4226 19.0911 22.7977 20.6431 24.9792 23.743C25.4126 24.3589 25.2647 25.2095 24.6488 25.6429C24.0329 26.0764 23.1822 25.9284 22.7488 25.3125C21.1085 22.9817 18.5787 21.8183 15.0066 21.8183C11.4268 21.8183 8.89371 22.9866 7.25357 25.328C6.82148 25.9448 5.97116 26.0946 5.35433 25.6625C4.7375 25.2304 4.58774 24.3801 5.01983 23.7632C7.20065 20.65 10.5808 19.0911 15.0066 19.0911Z"
                            fill="#808191" />
                        </svg>
                    </a>

                    </div>
                </div>


                <form id="bot-form" onSubmit={(event) => {callSubmitBot(event)}}>
                {loading === true ? (
                        <CircleLoader />
                    ) : (
                        <>
                 
                            <div className="form-row">
                                <div className="form-group">
                                    <h2 className="mt-4" style={{"fontSize":"23px","marginBottom":"35px"}}>Bot Settings</h2>
                                    <label>Welcome New User:</label> 
                                    <div style={{marginBottom:-30}}>
                                        <label className="switch">
                                            <input name="enableNewUserMessage" id="enableNewUserMessage" value={enableNewUserMessage} checked={enableNewUserMessage} onChange={(event) => {setEnableNewUserMessage(event.target.checked)}} type="checkbox" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    {
                                        enableNewUserMessage ? (
                                            <>
                                            <label>Channel ID</label>
                                           <input style={{marginBottom:15}} required type="text" className="form-control" id="newUserMessageChannel" name="newUserMessageChannel"
                                                placeholder="Channel ID" value={newUserMessageChannel} onChange={(event) => {setNewUserMessageChannel(event.target.value)}}/>

                                             <label>Message</label>
                                            <input required type="text" className="form-control" id="newUserMessage" name="newUserMessage"
                                                placeholder="Welcome to the new server!" value={newUserMessage} onChange={(event) => {setNewUserMessage(event.target.value)}}/>
                                            </>
                                        ) : null
                                    }
                                    <small className="form-text text-muted">When a new user joins your server the bot will leave a custom message in the channel of your choice!</small>

                                </div>
                                
                            </div>

                            <div className="form-row">
                                <div className="form-group">
                                    <label>Grant Role on Reaction:</label> 
                                    <div style={{marginBottom:-30}}>
                                        <label className="switch">
                                            <input name="enableRoleReact" id="enableRoleReact" value={enableRoleReact} checked={enableRoleReact} onChange={(event) => {setEnableRoleReact(event.target.checked)}} type="checkbox" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    {
                                        enableRoleReact ? (
                                            <>
                                            
                                            <label>Channel ID:</label> 
                                            <input style={{marginBottom:15}} required type="text" className="form-control" id="newUserMessageChannel" name="newUserMessageChannel"
                                                placeholder="Channel ID" value={roleReactChannel} onChange={(event) => {setRoleReactChannel(event.target.value)}}/>

                                             <label>Message:</label> 
                                            <input value={roleReactMessage} onChange={(event) => {setRoleReactMessage(event.target.value)}} style={{marginBottom:15}} required type="text" className="form-control" id="roleReactMessage" name="roleReactMessage" placeholder="Message" />

                                            

                                            <div id="role-tabs">

                                                {emojiTabValues.length > 0 ?                                            
                                                    
                                                    emojiTabValues.map((item, id) => {
                                                        return (
                                                            <>
                                                            <label>Emoji Reaction:</label> 
                                                            <select className="form-control select reactEmoji">
                                                                {emojis.map(emoji => {
                                                                    return (
                                                                        <option selected={emoji == item ? true : false} value={emoji}>
                                                                            {emoji}
                                                                        </option>
                                                                    )
                                                                })}
                                                                
                                                            </select>
    
                                                            <label style={{marginTop:15}}>Role ID:</label> 
                                                            <select className="form-control select reactRole">
                                                                {
                                                                    botResp && botResp.roles && botResp.roles.length > 0 ? (
                                                                        <>
                                                                        {
                                                                            botResp.roles.map(role => {
                                                                                return (
                                                                                    <option selected={role.id == roleTabValues[id] ? true : false} value={role.id}>{role.name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                        </>
                                                                    ) : null
                                                                }
                                                            </select>
                                                            </>
                                                        )
                                                    })
                                                    
                                                : null}


                                            </div>
       



                                            {/* Select of Emojis */}
                                            {/* Select of Roles */}
                                            </>
                                        ) : null
                                    }
                                  

                                </div>
                                
                            </div>
                         
                            <div className="form-row">
                                <div style={{marginTop:-30}} className="form-group col-md-12">
                                    <button type="submit" className="btn btn-primary" style={{"textAlign":"center","width":"auto","minWidth":"120px","fontSize":"14px","fontWeight":"700","color":"#fff","borderRadius":"16px","marginTop":"40px","marginRight":"20px","backgroundColor":"#F55B6A","padding":"12px 33px"}}>Save</button>
                                    {
                                        enableRoleReact ? (
                                            <button onClick={() => {addTabDiscord()}} type="button" className="btn"
                                            style={{"textAlign":"center","width":"auto","minWidth":"120px","fontSize":"14px","fontWeight":"700","color":"#fff","marginTop":"40px","borderRadius":"16px","marginRight":"20px","backgroundColor":"#333333","padding":"12px 33px"}}>Add Role</button>
                                        ) : null
                                    }                                    
                                </div>

                            </div>
                        </>
                    )}
                </form>

                <form onSubmit={(event) => {callSubmitDiscord(event)}} id="discord-form">
                    {loading === true ? (
                        <CircleLoader />
                    ) : (
                        <>
                 
                            <div className="form-row">
                                <div className="form-group">
                                    <h2 className="mt-4" style={{"fontSize":"23px","marginBottom":"35px"}}>Webhook Settings</h2>
                                    <label for="webhook">Webhook:</label>
                                    <input required type="url" className="form-control" id="webhook" name="webhook"
                                        placeholder="Webhook" value={webhook} onChange={(event) => {setWebhook(event.target.value)}}
                                        aria-describedby="webhookBlock" />
                                    <small id="webhookBlock" className="form-text text-muted">
                                        The webhook is used to send you realtime logs on purchases, cancellations and any
                                        errors occurring on your app. <a target="_blank"
                                        href="https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks">For
                                        help
                                        setting up a webhook.</a>
                                    </small>
                                </div>
                                
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label for="webhook">Donations Webhook:</label>
                                    <input required type="url" className="form-control" name="donationsWebhook"
                                        placeholder="Webhook" value={donationsWebhook} onChange={(event) => {setDonationsWebhook(event.target.value)}}
                                       />
                                    <small className="form-text text-muted">
                                        The webhook is used to send you realtime logs on any donations made to you. <a target="_blank"
                                        href="https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks">For
                                        help
                                        setting up a webhook.</a>
                                    </small>
                                </div>
                                
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label for="webhook">Rewards Webhook:</label>
                                    <input required type="url" className="form-control" name="rewardsWebhook"
                                        placeholder="Webhook" value={rewardsWebhook} onChange={(event) => {setRewardsWebhook(event.target.value)}}
                                       />
                                    <small className="form-text text-muted">
                                        The webhook is used to send you realtime logs and to track rewards requested by your members. <a target="_blank"
                                        href="https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks">For
                                        help
                                        setting up a webhook.</a>
                                    </small>
                                </div>
                                
                            </div>
                            <div className="form-row">
                            <div className="form-group">
                                    {
                                        botStatus.server !== true ? (
                                            <button id="botButton" type="button" 
                                            onClick={() => {connectDiscord(clientId)}}
                                            className="btn" 
                                            style={{"textAlign":"center","minWidth":"120px","width":"auto","fontSize":"14px","fontWeight":"700","color":"#11142D","marginRight":"20px","marginBottom":"15px","borderRadius":"16px","backgroundColor":"#E4E4E4","padding":"12px 33px"}}>
                                                Add Bot to Server
                                            </button>
                                        ) : (
                                            <button id="botButton" type="button" 
                                            onClick={() => {disconnectDiscord(pullDiscord)}}
                                            className="btn"
                                            style={{"textAlign":"center","minWidth":"120px","width":"auto","fontSize":"14px","fontWeight":"700","color":"#11142D","marginRight":"20px","marginBottom":"15px","borderRadius":"16px","backgroundColor":"#E4E4E4","padding":"12px 33px"}}>
                                                Remove Bot from Server
                                            </button>
                                        )
                                    }
                                    <button style={{marginBottom:15}} type="submit" className="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </>
                    )}
                </form>
                <form onSubmit={(event) => {callSubmitEmbeds(event)}} id="embed-form">
                    {embedLoading === true ? (
                        <CircleLoader />
                    ) : (
                        <> 
                            <div className="form-row">
                                <div className="form-group">
                                    <h2 className="mt-4" style={{"fontSize":"23px","marginBottom":"35px"}}>Embed Messages</h2>
                                    <label for="welcomeMessage">Welcome Message:</label>
                                    <input required type="text" className="form-control" name="welcomeMessage"
                                        id="welcomeMessage" placeholder="Thank you for subscribing to our community!"
                                        value={welcomeMessage} onChange={(event) => {setWelcomeMessage(event.target.value)}} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label for="failedMessage">Failed Payment Message:</label>
                                    <input required type="text" className="form-control" name="failedMessage"
                                        id="failedMessage"
                                        placeholder="There seems to have been an error with your membership payment! If you believe there was an error with your payment method please login to the Dashboard to edit/adjust your payment method!"
                                        value={failedMessage} onChange={(event) => {setFailedMessage(event.target.value)}} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label for="expiredMessage">Expired Subscription Message:</label>
                                    <input required type="text" className="form-control" name="expiredMessage"
                                        id="expiredMessage"
                                        placeholder="Your membership expired! We hope to see you again soon!"
                                        value={expiredMessage} onChange={(event) => {setExpiredMessage(event.target.value)}} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label for="contactLink">Contact Link:</label>
                                    <input required type="url" className="form-control" name="contact" id="contactLink"
                                        placeholder="https://ternarydev.com/contact"
                                        value={contact} onChange={(event) => {setContact(event.target.value)}} />
                                    <small className="form-text text-muted">
                                        This is a link for where your customers could reach you if they have any trouble
                                        with their subscription.
                                    </small>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label for="welcomeVideo">Welcome Video:</label>
                                    <input type="url" className="form-control" id="welcomeVideo" name="video"
                                        placeholder="https://youtube.com/watch" value={video} onChange={(event) => {setVideo(event.target.value)}} />
                                    <small className="form-text text-muted">
                                        If you happen to have a tutorial video for your new members you may link it here.
                                        If not leave empty.
                                    </small>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </>
                    )}
                </form>
            </div>
        </div>
    )
}


export default Discord
