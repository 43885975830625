import React, { useEffect, useState } from "react";
import {editReleaseSubmit} from "../../../Actions/Dashboard/releases";

const EditReleaseModal = ({ discord, product, releases, pullReleases }) => {
    const [kick, setKick] = useState("");
    const [roles, setRoles] = useState([]);
    const [trialRoles, setTrialRoles] = useState([]);
    const [addOns, setAddOns] = useState([]);
    const [upgrades, setUpgrades] = useState([]);
    const [roleRemoval, setRoleRemoval] = useState([]);
    const [stock, setStock] = useState(0);
    const [trialDays, setTrialDays] = useState(0);
    const [password, setPassword] = useState("");

    useEffect(() => {
    });

    useEffect(() => {
        if (product.password) {
            setRoles(product.roles);
            setKick(!product.roleRemoval || product.roleRemoval.length == 0 ? 'kick' : 'role');
            setAddOns(product.addOns);
            setUpgrades(product.upgradeTo);
            setRoleRemoval(product.roleRemoval);
            setStock(product.stock);
            setTrialDays(product.trialDays);
            setTrialRoles(product.trialRoles ? product.trialRoles : []);
            setPassword(product.password === "null" ? "" : product.password);
        }
    }, [product])


    const submit = (event) => {
        event.preventDefault();
        var form = {
            releaseid: product._id,
            editstock: stock,
            editkickuser: kick,
            editplantrialdays: trialDays,
            editplantrialroles: trialDays > 0 ? trialRoles : [],
            editpass: password,
            editdiscordrolesremoval: roleRemoval,
            editupgradeplans: upgrades,
            editaddonplans: addOns,
            editroles: roles
        }
        editReleaseSubmit(event, form, pullReleases);
    }

    return (
        <div className="modal fade" id="relEditModal" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Product</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.6129 0.209705L1.70711 0.292893L8.07107 6.65607L14.435 0.292893C14.8256 -0.0976311 15.4587 -0.0976311 15.8492 0.292893C16.2097 0.653377 16.2375 1.22061 15.9324 1.6129L15.8492 1.70711L9.48607 8.07107L15.8492 14.435C16.2398 14.8256 16.2398 15.4587 15.8492 15.8492C15.4888 16.2097 14.9215 16.2375 14.5292 15.9324L14.435 15.8492L8.07107 9.48607L1.70711 15.8492C1.31658 16.2398 0.683418 16.2398 0.292893 15.8492C-0.0675907 15.4888 -0.0953203 14.9215 0.209705 14.5292L0.292893 14.435L6.65607 8.07107L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.623337 -0.0375504 1.12751 -0.0883879 1.5114 0.140381L1.6129 0.209705Z" fill="black"/>
                  </svg>
                </button>
              </div>
              <form onSubmit={(event) => {submit(event)}} id="release-edit-form">
              <div className="modal-body">
                <div className="form-group">
                  <label for="editStock" className="col-form-label">Stock:</label>
                  <input value={stock} onChange={(event) => {setStock(parseInt(event.target.value))}} min="0" type="number" name="editstock" className="form-control" id="editStock" required />
                </div>


                {
                    product.interval !== 'one time' ? 
                    <div id="editPlanTrialGroup" className="form-group">
                        <label for="editPlanTrial" className="col-form-label">Trial Days:</label>
                        <div className="input-group mb-3">
                        <input value={trialDays} onChange={(event) => {setTrialDays(parseInt(event.target.value))}} min="0" max="730" placeholder="30" step="1" type="number" name="editplantrialdays"
                            className="form-control" id="editPlanTrial" required />
                        <div className="input-group-append">
                            <span className="input-group-text">Days</span>
                        </div>
                        </div>
                        <small id="trialHelp" className="form-text text-muted">Set to 0 for no trial period.</small>
                    </div>
                    : null
                }
                
                <div className="form-group">
                  <label for="editPass" className="col-form-label">URL Slug:</label>
                  <input value={password} onChange={(event) => {setPassword(event.target.value)}} type="text" name="editpass" className="form-control" id="editPass" />
                </div>

                <div className="form-group">
                    <label for="editRoles" className="col-form-label">Discord Roles:</label>
                    <select value={roles}
                        onChange={(event) => {
                            var values = [];
                            var options = event.target.options;
                            for (var i = 0; i < options.length;  i++) {
                                if (options[i].selected) {
                                    values.push(options[i].value);
                                }
                            }
                            setRoles(values);
                        }}
                         multiple className="form-control" name="editroles"
                         id="editRoles"
                            >
                            {
                            discord.botStatus.roles.map(role => {
                                return (
                                    <option value={role.id}>{role.name}</option>
                                )
                            })
                        }
                    </select>
                    <small className="form-text text-muted">You can select multiple roles by using control, cmd, or shift click.</small>
                </div>

                {
                    trialDays > 0 ?
                    (
                        <div className="form-group">
                            <label for="editRoles" className="col-form-label">Discord Roles For Trial Period:</label>
                            <select value={trialRoles}
                                onChange={(event) => {
                                    var values = [];
                                    var options = event.target.options;
                                    for (var i = 0; i < options.length;  i++) {
                                        if (options[i].selected) {
                                            values.push(options[i].value);
                                        }
                                    }
                                    setTrialRoles(values);
                                }}
                                multiple className="form-control"
                                    >
                                    {
                                    discord.botStatus.roles.map(role => {
                                        return (
                                            <option value={role.id}>{role.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <small className="form-text text-muted">hese roles will be applied to the user while on trial. Once trial ends and regular subscription begins, configured roles for the regular product will automatically replace the trial roles.</small>
                        </div>
                    ) : null
                }



                <div className="form-group">
                  <label for="editKickUser" className="col-form-label">User removal:</label>
                  <select value={kick} onChange={(event) => {setKick(event.target.value)}} describedby="editKickHelp" className="form-control select" name="editkickuser"
                    id="editKickUser" required>
                    <option value="kick">Kick user</option>
                    <option value="role">Remove role</option>
                  </select>
                  <small id="editKickHelp" className="form-text text-muted">Kick user will remove user from Discord server
                    when his subscription expires, if you select remove role the selected will be removed when the
                    subscription expires.</small>
                </div>

                {
                    kick === 'role' ? (
                        <div id="editRoleRemovalGroup" className="form-group">
                            <label for="editRemoveRoles" className="col-form-label">Role(s) to remove:</label>
                            <select value={roleRemoval}
                                    onChange={(event) => {
                                        var values = [];
                                        var options = event.target.options;
                                        for (var i = 0; i < options.length;  i++) {
                                            if (options[i].selected) {
                                                values.push(options[i].value);
                                            }
                                        }
                                        setRoleRemoval(values);
                                    }}
                                    describedby="editRemovalHelp" multiple className="form-control" name="editdiscordrolesremoval"
                                 id="editRemoveRoles">
                                {
                                        discord.botStatus.roles.map(role => {
                                            return (
                                                <option value={role.id}>{role.name}</option>
                                            )
                                        })
                                }
                            </select>
                            <small id="editRemovalHelp" className="form-text text-muted">You can select multiple products by using control, cmd, or shift click.</small>
                        </div>
                    ) : null
                }
                

                  <div className="form-group">
                    <label for="editUpgradePlans" className="col-form-label">Switch to Product(s):</label>
                    <select
                    value={upgrades}
                    onChange={(event) => {
                        var values = [];
                        var options = event.target.options;
                        for (var i = 0; i < options.length;  i++) {
                            if (options[i].selected) {
                                values.push(options[i].value);
                            }
                        }
                        setUpgrades(values);
                    }}
                    multiple className="form-control" name="editupgradeplans" className="form-control" id="editUpgradePlans" aria-describedby="editUpgradeHelp">
                        {
                            releases.map(release => {
                                return (
                                    release._id !== product._id && release.stock > 0 ? 
                                    (<option value={release.planId}>{release.name} ${release.price}</option>) : null
                                )
                            })
                        }
                    </select>
                    <small id="editUpgradeHelp" className="form-text text-muted">You can select multiple products by using control, cmd, or shift click.</small>
                  </div>

                  <div className="form-group">
                    <label for="editAddOnPlans" className="col-form-label">Add-on Product(s):</label>
                    <select 
                        value={addOns}
                        onChange={(event) => {
                            var values = [];
                            var options = event.target.options;
                            for (var i = 0; i < options.length;  i++) {
                                if (options[i].selected) {
                                    values.push(options[i].value);
                                }
                            }
                            setAddOns(values);
                        }}
                    multiple className="form-control" name="editaddonplans" className="form-control" id="editAddOnPlans" aria-describedby="editAddOnHelp">
                        {
                            releases.map(release => {
                                return (
                                    release._id !== product._id && release.stock > 0 ?
                                    (<option value={release.planId}>{release.name} ${release.price}</option>) : null
                                )
                            })
                        }
                    </select>
                    <small id="editAddOnHelp" className="form-text text-muted">Add-on products are products that members will be able to add-on to their additional subscriptions at a later date. You can select multiple products by using control, cmd, or shift click.</small>
                  </div>

                  <div className="alert alert-danger" id="relEditErr" role="alert">
                  </div>
                </div>
                <div className="modal-footer">
                  <button style={{"display":"block","margin":"0 auto","float":"none"}} type="submit" className="btn btn-main-type">Save Changes</button>
                </div>
              </form>
            </div>
          </div>
        </div>
    )
}

export default EditReleaseModal;

