import React, { useEffect, useState, useRef } from "react";
import './Alert.css';

const Alert = ({ message, update }) => {

    useEffect(() => {
    }, [])

    return (
        <div style={{cursor: update ? "pointer" : ""}} onClick={() => {
            if (update) {
                window.open("https://docs.ternarydev.com/ternary-developments/documentation/change-log", '_blank').focus();
            }
        }} className="alert">
            {message}
        </div>
    )

}

export default Alert
