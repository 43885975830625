import React, { useEffect, useState, useRef } from "react";
import { loadAnalytics } from "../../Actions/Dashboard/overview";

const Overview = ({ setPage, data, releases, display }) => {
    const [help, showHelp] = useState(false);
    useEffect(() => {
        if (data.stripe.connected || data.authorizeNet) {
            console.log("get analytics")
            // pull info
            loadAnalytics(data, releases);
        }
    }, [releases])

    return (
        <div id="overview" style={{display: display === true ? true : 'none'}}>
            <div className="container-fluid row">
                <div className="col-md-12">
                    <h1 className="mt-4">Overview</h1>
                    <div className="user-corner-panel">

                    <svg style={{"verticalAlign":"middle","marginRight":"10px","cursor":"pointer"}} onClick={() => {showHelp(!help)}} width="30"
                        height="30" viewdiv="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                        d="M14.8261 0C6.63787 0 0 6.63787 0 14.8261C0 23.0143 6.63787 29.6522 14.8261 29.6522C23.0143 29.6522 29.6522 23.0143 29.6522 14.8261C29.6522 6.63787 23.0143 0 14.8261 0ZM14.8261 2.69565C21.5255 2.69565 26.9565 8.12663 26.9565 14.8261C26.9565 21.5255 21.5255 26.9565 14.8261 26.9565C8.12663 26.9565 2.69565 21.5255 2.69565 14.8261C2.69565 8.12663 8.12663 2.69565 14.8261 2.69565Z"
                        fill="#808191" />
                        <path fillRule="evenodd" clipRule="evenodd"
                        d="M14.832 20.2174C15.5764 20.2174 16.1798 20.8208 16.1798 21.5652C16.1798 22.2564 15.6595 22.8261 14.9892 22.904L14.832 22.913C14.0817 22.913 13.4782 22.3096 13.4782 21.5652C13.4782 20.874 13.9986 20.3043 14.6689 20.2264L14.832 20.2174ZM18.4572 7.2585L18.6701 7.46842C20.4876 9.31629 20.4778 12.2194 18.73 14.0234L18.4132 14.332C17.9546 14.769 17.4886 15.1181 16.7977 15.6071C16.1485 16.0936 16.0071 16.3252 16.0071 17.05C16.0071 17.7944 15.4036 18.3978 14.6592 18.3978C13.9149 18.3978 13.3114 17.7944 13.3114 17.05C13.3114 15.3795 13.9064 14.4052 15.1813 13.4499L15.7904 13.0084C16.0136 12.8429 16.1825 12.7104 16.3343 12.5795L16.6725 12.2654C17.4784 11.5231 17.555 10.3359 16.883 9.50925L16.7483 9.35868L16.6144 9.2259C15.5484 8.22739 13.8659 8.20049 12.7665 9.12547L12.5889 9.28844L11.9297 9.94753C11.4033 10.4738 10.5499 10.4737 10.0236 9.94732C9.53775 9.4614 9.50046 8.69686 9.91164 8.16817L10.0238 8.0412L10.683 7.38212C12.8001 5.26548 16.2709 5.21072 18.4572 7.2585Z"
                        fill="#808191" />
                    </svg>
                    <div className="help-dropdown" style={{display: help === true ? 'block' : 'none'}}>
                        <ul>
                        <li><a target="_blank" href="https://ternarydev.notion.site/Ternary-4adf412dd4444fcb90c0a457d2a3607c">Helpful Articles</a>
                        </li>
                        <li><a target="_blank" href="https://docs.ternarydev.com/ternary-developments/change-log">Release Notes</a></li>
                        <li><a href="mailto:support@ternarydev.com">Contact Us</a></li>
                        </ul>
                    </div>


                    <a className="settings-button" onClick={() => {setPage('subscription')}}>
                        <svg style={{"verticalAlign":"middle","cursor":"pointer"}} width="30" height="30" viewdiv="0 0 30 30"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM15 2.72729C21.7781 2.72729 27.2727 8.22198 27.2727 15C27.2727 21.7781 21.7781 27.2727 15 27.2727C8.22198 27.2727 2.72729 21.7781 2.72729 15C2.72729 8.22198 8.22198 2.72729 15 2.72729Z"
                            fill="#808191" />
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M14.9999 5.45459C10.4812 5.45459 6.81812 9.11771 6.81812 13.6364C6.81812 18.1551 10.4812 21.8182 14.9999 21.8182C19.5186 21.8182 23.1818 18.1551 23.1818 13.6364C23.1818 9.11771 19.5186 5.45459 14.9999 5.45459ZM14.9997 8.18188C18.0122 8.18188 20.4543 10.624 20.4543 13.6364C20.4543 16.6489 18.0122 19.091 14.9997 19.091C11.9873 19.091 9.54517 16.6489 9.54517 13.6364C9.54517 10.624 11.9873 8.18188 14.9997 8.18188Z"
                            fill="#808191" />
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M15.0066 19.0911C19.4226 19.0911 22.7977 20.6431 24.9792 23.743C25.4126 24.3589 25.2647 25.2095 24.6488 25.6429C24.0329 26.0764 23.1822 25.9284 22.7488 25.3125C21.1085 22.9817 18.5787 21.8183 15.0066 21.8183C11.4268 21.8183 8.89371 22.9866 7.25357 25.328C6.82148 25.9448 5.97116 26.0946 5.35433 25.6625C4.7375 25.2304 4.58774 24.3801 5.01983 23.7632C7.20065 20.65 10.5808 19.0911 15.0066 19.0911Z"
                            fill="#808191" />
                        </svg>
                    </a>

                    </div>
                </div>
                {data.stripe.connected === true || data.authorizeNet ? (
                     <div className="col-md-12">
                     <div className="overview row">
                     <div className="col-sm-4">
                         <svg width="22" height="22" viewdiv="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M18.4361 3H3.56389C2.61418 3 2.11574 3.08612 1.59152 3.33697C1.09102 3.57646 0.685688 3.93912 0.408779 4.4024C0.106184 4.90864 0 5.40063 0 6.294V18.706C0 19.5994 0.106184 20.0914 0.408779 20.5976C0.685688 21.0609 1.09102 21.4235 1.59152 21.663C2.11574 21.9139 2.61418 22 3.56389 22H18.4361C19.3858 22 19.8843 21.9139 20.4085 21.663C20.909 21.4235 21.3143 21.0609 21.5912 20.5976C21.8938 20.0914 22 19.5994 22 18.706V6.294C22 5.40063 21.8938 4.90864 21.5912 4.4024C21.3143 3.93912 20.909 3.57646 20.4085 3.33697C19.8843 3.08612 19.3858 3 18.4361 3ZM3.39058 5.00095L18.4361 5C19.112 5 19.3286 5.03742 19.5452 5.14106C19.6966 5.21348 19.8028 5.30855 19.8745 5.42851C19.9587 5.56944 19.993 5.70322 19.999 6.13764L20 18.706C20 19.2671 19.9681 19.4149 19.8745 19.5715C19.8028 19.6915 19.6966 19.7865 19.5452 19.8589C19.3483 19.9532 19.1514 19.9926 18.6094 19.9991L3.56389 20C2.88796 20 2.67138 19.9626 2.45479 19.8589C2.30344 19.7865 2.19719 19.6915 2.12549 19.5715C2.04125 19.4306 2.007 19.2968 2.00099 18.8624L2 6.294C2 5.73287 2.03189 5.5851 2.12549 5.42851C2.19719 5.30855 2.30344 5.21348 2.45479 5.14106C2.65169 5.04684 2.84858 5.00735 3.39058 5.00095Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M16 0C16.5128 0 16.9355 0.38604 16.9933 0.883379L17 1V4C17 4.55228 16.5523 5 16 5C15.4872 5 15.0645 4.61396 15.0067 4.11662L15 4V1C15 0.447715 15.4477 0 16 0Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M6 0C6.51284 0 6.93551 0.38604 6.99327 0.883379L7 1V4C7 4.55228 6.55228 5 6 5C5.48716 5 5.06449 4.61396 5.00673 4.11662L5 4V1C5 0.447715 5.44772 0 6 0Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M6.00439 7.5C6.55668 7.5 7.00439 7.94772 7.00439 8.5C7.00439 9.01284 6.61835 9.43551 6.12101 9.49327L6.00439 9.5C5.44772 9.5 5 9.05228 5 8.5C5 7.98716 5.38604 7.56449 5.88338 7.50673L6.00439 7.5Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M11.0088 7.5C11.5611 7.5 12.0088 7.94772 12.0088 8.5C12.0088 9.01284 11.6227 9.43551 11.1254 9.49327L11.0088 9.5C10.4521 9.5 10.0044 9.05228 10.0044 8.5C10.0044 7.98716 10.3904 7.56449 10.8878 7.50673L11.0088 7.5Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M16.0132 7.5C16.5655 7.5 17.0132 7.94772 17.0132 8.5C17.0132 9.01284 16.6271 9.43551 16.1298 9.49327L16.0132 9.5C15.4565 9.5 15.0088 9.05228 15.0088 8.5C15.0088 7.98716 15.3948 7.56449 15.8922 7.50673L16.0132 7.5Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M6.00439 11.5C6.55668 11.5 7.00439 11.9477 7.00439 12.5C7.00439 13.0128 6.61835 13.4355 6.12101 13.4933L6.00439 13.5C5.44772 13.5 5 13.0523 5 12.5C5 11.9872 5.38604 11.5645 5.88338 11.5067L6.00439 11.5Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M11.0088 11.5C11.5611 11.5 12.0088 11.9477 12.0088 12.5C12.0088 13.0128 11.6227 13.4355 11.1254 13.4933L11.0088 13.5C10.4521 13.5 10.0044 13.0523 10.0044 12.5C10.0044 11.9872 10.3904 11.5645 10.8878 11.5067L11.0088 11.5Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M16.0132 11.5C16.5655 11.5 17.0132 11.9477 17.0132 12.5C17.0132 13.0128 16.6271 13.4355 16.1298 13.4933L16.0132 13.5C15.4565 13.5 15.0088 13.0523 15.0088 12.5C15.0088 11.9872 15.3948 11.5645 15.8922 11.5067L16.0132 11.5Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M6.00439 15.5C6.55668 15.5 7.00439 15.9477 7.00439 16.5C7.00439 17.0128 6.61835 17.4355 6.12101 17.4933L6.00439 17.5C5.44772 17.5 5 17.0523 5 16.5C5 15.9872 5.38604 15.5645 5.88338 15.5067L6.00439 15.5Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M11.0088 15.5C11.5611 15.5 12.0088 15.9477 12.0088 16.5C12.0088 17.0128 11.6227 17.4355 11.1254 17.4933L11.0088 17.5C10.4521 17.5 10.0044 17.0523 10.0044 16.5C10.0044 15.9872 10.3904 15.5645 10.8878 15.5067L11.0088 15.5Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M18.4361 3H3.56389C2.61418 3 2.11574 3.08612 1.59152 3.33697C1.09102 3.57646 0.685688 3.93912 0.408779 4.4024C0.106184 4.90864 0 5.40063 0 6.294V18.706C0 19.5994 0.106184 20.0914 0.408779 20.5976C0.685688 21.0609 1.09102 21.4235 1.59152 21.663C2.11574 21.9139 2.61418 22 3.56389 22H18.4361C19.3858 22 19.8843 21.9139 20.4085 21.663C20.909 21.4235 21.3143 21.0609 21.5912 20.5976C21.8938 20.0914 22 19.5994 22 18.706V6.294C22 5.40063 21.8938 4.90864 21.5912 4.4024C21.3143 3.93912 20.909 3.57646 20.4085 3.33697C19.8843 3.08612 19.3858 3 18.4361 3ZM3.39058 5.00095L18.4361 5C19.112 5 19.3286 5.03742 19.5452 5.14106C19.6966 5.21348 19.8028 5.30855 19.8745 5.42851C19.9587 5.56944 19.993 5.70322 19.999 6.13764L20 18.706C20 19.2671 19.9681 19.4149 19.8745 19.5715C19.8028 19.6915 19.6966 19.7865 19.5452 19.8589C19.3483 19.9532 19.1514 19.9926 18.6094 19.9991L3.56389 20C2.88796 20 2.67138 19.9626 2.45479 19.8589C2.30344 19.7865 2.19719 19.6915 2.12549 19.5715C2.04125 19.4306 2.007 19.2968 2.00099 18.8624L2 6.294C2 5.73287 2.03189 5.5851 2.12549 5.42851C2.19719 5.30855 2.30344 5.21348 2.45479 5.14106C2.65169 5.04684 2.84858 5.00735 3.39058 5.00095Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M16 0C16.5128 0 16.9355 0.38604 16.9933 0.883379L17 1V4C17 4.55228 16.5523 5 16 5C15.4872 5 15.0645 4.61396 15.0067 4.11662L15 4V1C15 0.447715 15.4477 0 16 0Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M6 0C6.51284 0 6.93551 0.38604 6.99327 0.883379L7 1V4C7 4.55228 6.55228 5 6 5C5.48716 5 5.06449 4.61396 5.00673 4.11662L5 4V1C5 0.447715 5.44772 0 6 0Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M6.00439 7.5C6.55668 7.5 7.00439 7.94772 7.00439 8.5C7.00439 9.01284 6.61835 9.43551 6.12101 9.49327L6.00439 9.5C5.44772 9.5 5 9.05228 5 8.5C5 7.98716 5.38604 7.56449 5.88338 7.50673L6.00439 7.5Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M11.0088 7.5C11.5611 7.5 12.0088 7.94772 12.0088 8.5C12.0088 9.01284 11.6227 9.43551 11.1254 9.49327L11.0088 9.5C10.4521 9.5 10.0044 9.05228 10.0044 8.5C10.0044 7.98716 10.3904 7.56449 10.8878 7.50673L11.0088 7.5Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M16.0132 7.5C16.5655 7.5 17.0132 7.94772 17.0132 8.5C17.0132 9.01284 16.6271 9.43551 16.1298 9.49327L16.0132 9.5C15.4565 9.5 15.0088 9.05228 15.0088 8.5C15.0088 7.98716 15.3948 7.56449 15.8922 7.50673L16.0132 7.5Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M6.00439 11.5C6.55668 11.5 7.00439 11.9477 7.00439 12.5C7.00439 13.0128 6.61835 13.4355 6.12101 13.4933L6.00439 13.5C5.44772 13.5 5 13.0523 5 12.5C5 11.9872 5.38604 11.5645 5.88338 11.5067L6.00439 11.5Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M11.0088 11.5C11.5611 11.5 12.0088 11.9477 12.0088 12.5C12.0088 13.0128 11.6227 13.4355 11.1254 13.4933L11.0088 13.5C10.4521 13.5 10.0044 13.0523 10.0044 12.5C10.0044 11.9872 10.3904 11.5645 10.8878 11.5067L11.0088 11.5Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M16.0132 11.5C16.5655 11.5 17.0132 11.9477 17.0132 12.5C17.0132 13.0128 16.6271 13.4355 16.1298 13.4933L16.0132 13.5C15.4565 13.5 15.0088 13.0523 15.0088 12.5C15.0088 11.9872 15.3948 11.5645 15.8922 11.5067L16.0132 11.5Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M6.00439 15.5C6.55668 15.5 7.00439 15.9477 7.00439 16.5C7.00439 17.0128 6.61835 17.4355 6.12101 17.4933L6.00439 17.5C5.44772 17.5 5 17.0523 5 16.5C5 15.9872 5.38604 15.5645 5.88338 15.5067L6.00439 15.5Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M11.0088 15.5C11.5611 15.5 12.0088 15.9477 12.0088 16.5C12.0088 17.0128 11.6227 17.4355 11.1254 17.4933L11.0088 17.5C10.4521 17.5 10.0044 17.0523 10.0044 16.5C10.0044 15.9872 10.3904 15.5645 10.8878 15.5067L11.0088 15.5Z"
                             fill="black" />
                         </svg>
                         <h5>This Month's Stats</h5>
                         <span>{data.group_name}</span>
                     </div>
                     <div className="col-sm-4">
                         <svg width="23" height="22" viewdiv="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M9.69776 12.4254C10.2487 12.3866 10.7267 12.8019 10.7654 13.3528C10.8041 13.9037 10.3889 14.3817 9.83799 14.4204C5.83556 14.7018 3.38322 16.46 2.32275 19.815L2.2663 19.999L21.0003 20C21.5131 20 21.9358 20.386 21.9936 20.8834L22.0003 21C22.0003 21.5128 21.6143 21.9355 21.1169 21.9933L21.0003 22H1.0003C0.374166 22 -0.0979582 21.4311 0.0174319 20.8157C0.984169 15.6598 4.28669 12.8057 9.69776 12.4254Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M11.0002 0C13.8578 0 16.3578 1.92298 17.3116 4.73153C17.4892 5.25448 17.2092 5.82239 16.6863 5.99998C16.1633 6.17757 15.5954 5.89761 15.4178 5.37465C14.7312 3.3528 12.9724 2 11.0002 2C8.41522 2 6.28589 4.30677 6.28589 7.19048C6.28589 9.69567 7.90769 11.8137 10.089 12.2841C10.6289 12.4006 10.9721 12.9326 10.8557 13.4725C10.7392 14.0124 10.2072 14.3556 9.66731 14.2392C6.54351 13.5654 4.28589 10.6171 4.28589 7.19048C4.28589 3.23637 7.27331 0 11.0002 0Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M20.7167 7.37866C21.0599 6.94594 21.6889 6.87335 22.1216 7.21653C22.5211 7.53332 22.6136 8.09363 22.3556 8.5183L22.2838 8.62142L17.5253 14.6214C17.1672 15.0729 16.5108 15.1239 16.0877 14.7567L16.0008 14.6716L12.7593 11.0955C12.3884 10.6863 12.4195 10.0539 12.8287 9.68299C13.2064 9.34061 13.7743 9.34073 14.1512 9.66461L14.2412 9.75232L16.6903 12.455L20.7167 7.37866Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M19.1383 16.4992C19.6082 16.209 20.2244 16.3547 20.5145 16.8246C21.2063 17.9447 21.6962 19.2862 21.983 20.8157C22.0848 21.3585 21.7273 21.8811 21.1844 21.9829C20.6416 22.0846 20.1191 21.7271 20.0173 21.1843C19.7737 19.8852 19.368 18.7743 18.8129 17.8754C18.5227 17.4055 18.6684 16.7893 19.1383 16.4992Z"
                             fill="black" />
                         </svg>
                         <h5>Active Members</h5>
                         <div className="shine"></div>
                         <span id="members"></span>
                     </div>
                     <div className="col-sm-4">
                         <svg width="22" height="22" viewdiv="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M13 4C13.5128 4 13.9355 4.38604 13.9933 4.88338L14 5V8H17C17.5523 8 18 8.44772 18 9C18 9.51284 17.614 9.93551 17.1166 9.99327L17 10H14V13C14 13.5523 13.5523 14 13 14C12.4872 14 12.0645 13.614 12.0067 13.1166L12 13V10H9C8.44772 10 8 9.55228 8 9C8 8.48716 8.38604 8.06449 8.88338 8.00673L9 8H12V5C12 4.44772 12.4477 4 13 4Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M1 8C1.51284 8 1.93551 8.38604 1.99327 8.88338L2 9V18C2 19.0544 2.81588 19.9182 3.85074 19.9945L4 20H12C12.5523 20 13 20.4477 13 21C13 21.5128 12.614 21.9355 12.1166 21.9933L12 22H4C1.8578 22 0.108921 20.316 0.00489531 18.1996L0 18V9C0 8.44772 0.447715 8 1 8Z"
                             fill="black" />
                         <path fillRule="evenodd" clipRule="evenodd"
                             d="M19 0H7C5.34315 0 4 1.34315 4 3V15C4 16.6569 5.34315 18 7 18H19C20.6569 18 22 16.6569 22 15V3C22 1.34315 20.6569 0 19 0ZM7 2H19C19.5523 2 20 2.44772 20 3V15C20 15.5523 19.5523 16 19 16H7C6.44772 16 6 15.5523 6 15V3C6 2.44772 6.44772 2 7 2Z"
                             fill="black" />
                         </svg>
                         <h5>New Subscribers</h5>
                         <div className="shine"></div>
                         <span id="orders"></span>
                     </div>
                     </div>

                     {
                        data.stripe.connected && (
                            <>
                            <div className="row stats-container">
 
                                <div className="stats stats-right">
                                    <div className="header">
                                    <h5>Gross Volume</h5>
                                    <span>30 days</span>
                                    </div>
                                    <div className="body">
                                    <div className="shine"></div>
                                    <span id="totalRevenue"></span>
                                    <div className="shine box-shine"></div>
                                    <canvas id="revenue"></canvas>
                                    </div>
                                </div>

                                <div className="stats">
                                    <div className="header">
                                    <h5>New Subscribers</h5>
                                    <span>30 days</span>
                                    </div>
                                    <div className="body">
                                    <div className="shine"></div>
                                    <span id="members2"></span>
                                    <div className="shine box-shine"></div>
                                    <canvas id="membersChart"></canvas>
                                    </div>
                                </div>

                                <div className="stats member-products stats-right">
                                    <div className="header">
                                    <h5>New Subscribers per Product</h5>
                                    <span>30 days</span>
                                    </div>
                                    <div className="body">
                                    <div className="shine"></div>
                                    <span id="memberPerProductProducts"></span>
                                    <div className="shine box-shine"></div>
                                    <canvas id="memberPerProduct"></canvas>
                                    </div>
                                </div>
                                </div>

                                <div className="row stats-container">
                                <div className="stats stats-right">
                                    <div className="header">
                                    <h5>Cancellations</h5>
                                    <span>30 days</span>
                                    </div>
                                    <div className="body">
                                    <div className="shine"></div>
                                    <span id="totalCancels"></span>
                                    <div className="shine box-shine"></div>
                                    <canvas id="cancels"></canvas>
                                    </div>
                                </div>

                                <div className="stats stats-right">
                                    <div className="header">
                                    <h5>Refunds</h5>
                                    <span>30 days</span>
                                    </div>
                                    <div className="body">
                                    <div className="shine"></div>
                                    <span id="totalRefunds"></span>
                                    <div className="shine box-shine"></div>
                                    <canvas id="refunds"></canvas>
                                    </div>
                                </div>
                                </div>

                                <div className="row stats-container">
                                <div className="stats">
                                    <div className="header">
                                    <h5>Reason for Cancellations</h5>
                                    </div>
                                    <div className="body">
                                    <div className="shine box-shine"></div>
                                    <canvas id="cancelReasons"></canvas>
                                    </div>
                                </div>

                                </div>
                            </>
                        )
                     }
                     
 

                 </div>
                ) : 
                    (
                        <div className="col-sm-12">
                            <label>Please connect to a stripe account and connect to a Discord server before selling any products.</label>
                        </div>
                    )
                }
               
            </div>
        </div>
    )
}
  
  
export default Overview
