import bootbox from "bootbox";
import $ from "jquery"
var transactionsTable;

export const getTransactions = (first, mode, setLatest, startingAfter, setStartingAfter, endingBefore, setEndingBefore, setHasMore, setTransactions, setLoading) => {
    var useStartingAfter = "none";
    var useEndingBefore = "none";
    if (mode === "next") {
        useStartingAfter = startingAfter;
    } else {
        useEndingBefore = endingBefore;
    }
    $.get(`/api/dashboard/transactions/${useEndingBefore}/${useStartingAfter}`,
        (data, status) => {
            if (status == "success") {
                if (data.success == true) {
                    setLoading(false);
                    setStartingAfter(data.startingAfter);
                    setEndingBefore(data.endingBefore);
                    setHasMore(data.hasMore);
                    if (first) {
                        if (data.transactions.length > 0) {
                            console.log(data.transactions[0]);
                            setLatest(data.transactions[0].id);
                        }
                    }
                    setTransactions(data.transactions);
                    createTransactionTable();
                    return;
                } else {
                    return window.location.href = "/logout";
                }
            } else {
                return window.location.href = "/logout";
            }
    });
}


const createTransactionTable = () => {
    if ($("#transactionsTable").length) {

        $("#transactionsTable .clickable").on("click", function (e) {
            var id = $(this).parent().attr("id");
            window.open(`https://dashboard.stripe.com/payments/${id}`, '_blank');
        });

        transactionsTable= $('#transactionsTable').DataTable({
            ordering: false,
            lengthChange: false,
            pageLength:15,
            initComplete: function (settings, json) {
                $('body').find('.dataTables_scrollBody').addClass("scrollbar");
                $("#transactionsTable").wrap("<div class='userTableScroll' style='overflow:auto; width:100%;position:relative;'></div>");
            }
        });
        
        $("#transactions-search").on("keyup change", function () {
            transactionsTable.search(this.value).draw();
        });


    } else {
        setTimeout(createTransactionTable, 50);
    }
}



