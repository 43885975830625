import $ from "jquery"
import bootbox from "bootbox";
import Axios from "axios";
var catalogueTable;

// Trigger catalogue modal
export const addCatalogue = () => {
    $("#catalogueModal").modal('toggle');
}

// Pull all releases from API
export const getCatalogue = (setCatalogue, setOpenCatalogue, setPage, setLoading) => {
    $.get("/api/dashboard/catalogue",
        (data, status) => {
            if (status == "success") {
                if (data.success == true) {
                    setLoading(false);
                    setCatalogue(data.catalogue);
                    createCatalogueTable(setPage, setOpenCatalogue);
                    return;
                } else {
                    return window.location.href = "/logout";
                }
            } else {
                return window.location.href = "/logout";
            }
        });
}



const createCatalogueTable = (setPage, setOpenCatalogue) => {
    if ($("#catalogueTable").length) {

        $("#catalogueTable .clickable").on("click", function (e) {
            var catalogue = JSON.parse($(this).parent().attr("data-identify"));
            setOpenCatalogue({_id: catalogue._id, title: catalogue.title, description: catalogue.description, posts: catalogue.posts});
            setPage("catalogue")
        });

        catalogueTable = $('#catalogueTable').DataTable({
            ordering: false,
            lengthChange: false,
            initComplete: function (settings, json) {
                $('body').find('.dataTables_scrollBody').addClass("scrollbar");
                $("#catalogueTable").wrap("<div class='userTableScroll' style='overflow:auto; width:100%;position:relative;'></div>");
            }
        });

        $("#catalogue-search").on("keyup change", function () {
            catalogueTable.search(this.value).draw();
        });

    
    } else {
        setTimeout(() => {
            createCatalogueTable(setPage, setOpenCatalogue);
        }, 50);
    }
}


// Create a new catalogue
export const createCatalogue = (form, pullCatalogue, pullPosts, resetValues) => {
    document.getElementById("catalogue-form").getElementsByClassName("btn-main-type")[0].disabled = true;
    document.getElementById("catalogue-form").getElementsByClassName("btn-main-type")[0].innerHTML = "...";
    $.ajax({
        url: "/api/dashboard/catalogue",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(form),
        success: function (data) {
            if (data.success) {
                $("#catalogueModal").modal('toggle');
                document.getElementById("catalogue-form").getElementsByClassName("btn-main-type")[0].disabled = false;
                document.getElementById("catalogue-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Add Product";
                pullCatalogue();
                pullPosts();
                resetValues();
                bootbox.alert("Successfully Created Catalogue");
            } else {
                $("#catalogueAddErr").text(data.error);
                $("#catalogueAddErr").show();
                $("#catalogueAddErr").delay(5000).hide(1);
                document.getElementById("catalogue-form").getElementsByClassName("btn-main-type")[0].disabled = false;
                document.getElementById("catalogue-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Add Product";
            }
        }
    }).fail(function (jqXHR, textStatus, error) {

        $("#catalogueAddErr").text("Failed request... Please try again");
        $("#catalogueAddErr").show();
        $("#catalogueAddErr").delay(5000).hide(1);
        document.getElementById("catalogue-form").getElementsByClassName("btn-main-type")[0].disabled = false;
        document.getElementById("catalogue-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Add Product";
    });
}


// Delete catalogue(s)
export const callDeleteCatalogue = (pullCatalogue) => {
    var catalogueId;
    var selected = document.getElementsByClassName("checkbox-input-catalogue");
    for (var i = 0; i < selected.length; i++) {
        if (selected[i].checked == true) {
            let row = selected[i].parentNode.parentNode.parentNode;
            catalogueId = row.getAttribute("id");
            break;
        }
    }

    if (catalogueId) {
        bootbox.confirm({
            message: `Are you sure you want to delete catalogue`,
            centerVertical: true,
            buttons: {
                confirm: {
                    label: 'Yes',
                    className: 'btn-main-type'
                },
                cancel: {
                    label: 'No',
                    className: 'btn-second-type'
                }
            },
            callback: function (result) {
                if (result == true) {
                    $.ajax({
                        url: `/api/dashboard/catalogue/${catalogueId}`,
                        type: "DELETE",
                        contentType: 'application/json',
                        success: function (data) {
                            if (data.success) {
                                bootbox.alert("Successfully Deleted Catalogue", () => {
                                    pullCatalogue();
                                })
                            } else {
                                bootbox.alert(data.error, () => {
                                    pullCatalogue();
                                });
                            }
                        }
                    }).fail(function (jqXHR, textStatus, error) {
                        bootbox.alert("Failed request... Please try again", () => {
                            window.location.reload();
                        });
                    });
                }
            }
        })
        return;
    } else {
        bootbox.alert("Please select catalogue you would like to delete");
    }
};

// Trigger Edit catalogue
export const callEditCatalogue = (setEditCatalogue) => {
    let selectedCatalogues = document.getElementsByClassName("checkbox-input-catalogue");
    var selected = null;
    for (var i = 0; i < selectedCatalogues.length; i++) {
      if (selectedCatalogues[i].checked == true) {
        selected = selectedCatalogues[i].parentNode.parentNode.parentNode;
      }
    }
    if (selected != null) {
      var catalogue = JSON.parse(selected.getAttribute("data-identify"));
      setEditCatalogue(catalogue);
      $("#editCatalogueModal").modal('toggle');
    } else {
      // show error message stating to click a user in the list to edit
      bootbox.alert("Please select a catalogue");
    }
}


// Edit catalogue form
export const editCatalogue = (id, form, pullCatalogue, pullPosts, resetValues) => {
    document.getElementById("edit-catalogue-form").getElementsByClassName("btn-main-type")[0].disabled = true;
    document.getElementById("edit-catalogue-form").getElementsByClassName("btn-main-type")[0].innerHTML = "...";
    $.ajax({
        url: `/api/dashboard/catalogue/${id}`,
        type: "PUT",
        contentType: 'application/json',
        data: JSON.stringify(form),
        success: function (data) {
            if (data.success) {
                $("#editCatalogueModal").modal('toggle');
                bootbox.alert("Successfully Edited Catalogue", () => {
                    document.getElementById("edit-catalogue-form").getElementsByClassName("btn-main-type")[0].disabled = false;
                    document.getElementById("edit-catalogue-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Edit Catalogue";
                    pullCatalogue();
                    pullPosts();
                    resetValues();
                });
            } else {
                $("#catalogueEditErr").text(data.error);
                $("#catalogueEditErr").show();
                $("#catalogueEditErr").delay(5000).hide(1);
                document.getElementById("edit-catalogue-form").getElementsByClassName("btn-main-type")[0].disabled = false;
                document.getElementById("edit-catalogue-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Edit Catalogue";
            }
        }
    }).fail(function (jqXHR, textStatus, error) {
        $("#editCatalogueModal").modal('toggle');
        bootbox.alert("Failed request... Please try again", () => {
            window.location.reload();
        });
    });
};
