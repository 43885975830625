import React, { useEffect, useState, useRef } from "react";
import CircleLoader from "../../Components/CircleLoader"
import PageNavigation from "../PageNavigation/PageNavigation";
import { getTransactions } from "../../Actions/Dashboard/transactions";
import bootbox from "bootbox";
import currencies from "./currencies";
import $ from "jquery"

const Transaction = ({ setPage, data, display }) => {
    const [help, showHelp] = useState(false);
    const [loading, setLoading] = useState(true)
    const [transactions, setTransactions] = useState([]);
    const [startingAfter, setStartingAfter] = useState("none");
    const [endingBefore, setEndingBefore] = useState("none");
    const [hasMore, setHasMore] = useState(false);
    const [latest, setLatest] = useState("");
    var stripePages = [{title: "Products", active: false, onclick: () => {setPage('releases')}}, {title: "Transactions", active: true, onclick: () => {setPage('transactions')} }, {title: "Coupons", active: false, onclick: () => {setPage('coupons')}}, {title: "Rewards", active: false, onclick: () => {setPage('rewards')}}];
    var authorizeNetPages = [{title: "Products", active: false, onclick: () => {setPage('releases')}}, {title: "Transactions", active: true, onclick: () => {setPage('transactions')} }];


    
    const pullTransactions = (first, mode) => {
        if (data.stripe.connected) {
            if ($.fn.DataTable.isDataTable( '#transactionsTable' )) {
                $("#transactionsTable").dataTable().fnDestroy();
            }
            setLoading(true);
            getTransactions(first, mode, setLatest, startingAfter, setStartingAfter, endingBefore, setEndingBefore, setHasMore, setTransactions, setLoading);
        }
    }

    useEffect(() => {
        pullTransactions(true, "next");
    }, [])


    useEffect(() => {
        if (transactions.length > 0) {
            checkDisabledButtons();
        }
    }, [transactions])

    // Check pagination buttons and disable proper one's based off buttons
    const checkDisabledButtons = () => {
        if ($.fn.DataTable.isDataTable( '#transactionsTable' )) {
            // Enable next button
            if (hasMore) {            
                $("#transactionsTable_next").on("click", function (e) {
                    pullTransactions(false, "next");
                });
                $("#transactionsTable_next").removeClass("disabled");
            } else {
                $("#transactionsTable_next").addClass("disabled");
            }

            if (latest != transactions[0].id) {
                // Enable previous button
                $("#transactionsTable_previous").on("click", function (e) {
                    pullTransactions(false, "previous");
                });
                $("#transactionsTable_previous").removeClass("disabled");
                return;
            } else {
                $("#transactionsTable_previous").addClass("disabled");
            }
        } else {
            setTimeout(checkDisabledButtons, 500);
        }   
    }


    // Styling for the status of a transaction
    const statusStyle = {
        'marginRight': '5px',
        "color": '#f55b6a',
        "fontSize": "12px",
        "borderRadius": "3px",
        "textAlign": "center",
        "paddingLeft": "5px",
        "paddingRight": "5px"
    }




    return (
        <div id="transactions" style={{display: display === true ? true : 'none'}}>
            <div className="container-fluid row">
                <div className="col-md-12">
                        <h1 className="mt-4">Products</h1>
                        <PageNavigation pages={
                            data.authorizeNet ? authorizeNetPages : stripePages
                        } />
                        <div className="user-corner-panel">
                        <svg style={{"verticalAlign":"middle","marginRight":"10px","cursor":"pointer"}} onClick={() => {showHelp(!help)}} width="30"
                            height="30" viewdiv="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                            d="M14.8261 0C6.63787 0 0 6.63787 0 14.8261C0 23.0143 6.63787 29.6522 14.8261 29.6522C23.0143 29.6522 29.6522 23.0143 29.6522 14.8261C29.6522 6.63787 23.0143 0 14.8261 0ZM14.8261 2.69565C21.5255 2.69565 26.9565 8.12663 26.9565 14.8261C26.9565 21.5255 21.5255 26.9565 14.8261 26.9565C8.12663 26.9565 2.69565 21.5255 2.69565 14.8261C2.69565 8.12663 8.12663 2.69565 14.8261 2.69565Z"
                            fill="#808191" />
                            <path fillRule="evenodd" clipRule="evenodd"
                            d="M14.832 20.2174C15.5764 20.2174 16.1798 20.8208 16.1798 21.5652C16.1798 22.2564 15.6595 22.8261 14.9892 22.904L14.832 22.913C14.0817 22.913 13.4782 22.3096 13.4782 21.5652C13.4782 20.874 13.9986 20.3043 14.6689 20.2264L14.832 20.2174ZM18.4572 7.2585L18.6701 7.46842C20.4876 9.31629 20.4778 12.2194 18.73 14.0234L18.4132 14.332C17.9546 14.769 17.4886 15.1181 16.7977 15.6071C16.1485 16.0936 16.0071 16.3252 16.0071 17.05C16.0071 17.7944 15.4036 18.3978 14.6592 18.3978C13.9149 18.3978 13.3114 17.7944 13.3114 17.05C13.3114 15.3795 13.9064 14.4052 15.1813 13.4499L15.7904 13.0084C16.0136 12.8429 16.1825 12.7104 16.3343 12.5795L16.6725 12.2654C17.4784 11.5231 17.555 10.3359 16.883 9.50925L16.7483 9.35868L16.6144 9.2259C15.5484 8.22739 13.8659 8.20049 12.7665 9.12547L12.5889 9.28844L11.9297 9.94753C11.4033 10.4738 10.5499 10.4737 10.0236 9.94732C9.53775 9.4614 9.50046 8.69686 9.91164 8.16817L10.0238 8.0412L10.683 7.38212C12.8001 5.26548 16.2709 5.21072 18.4572 7.2585Z"
                            fill="#808191" />
                        </svg>
                        <div className="help-dropdown" style={{display: help === true ? 'block' : 'none'}}>
                            <ul>
                            <li><a target="_blank" href="https://ternarydev.notion.site/Ternary-4adf412dd4444fcb90c0a457d2a3607c">Helpful Articles</a>
                            </li>
                            <li><a target="_blank" href="https://docs.ternarydev.com/ternary-developments/change-log">Release Notes</a></li>
                            <li><a href="mailto:support@ternarydev.com">Contact Us</a></li>
                            </ul>
                        </div>
                        <a className="settings-button" onClick={() => {setPage('subscription')}}>
                            <svg style={{"verticalAlign":"middle","cursor":"pointer"}} width="30" height="30" viewdiv="0 0 30 30"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM15 2.72729C21.7781 2.72729 27.2727 8.22198 27.2727 15C27.2727 21.7781 21.7781 27.2727 15 27.2727C8.22198 27.2727 2.72729 21.7781 2.72729 15C2.72729 8.22198 8.22198 2.72729 15 2.72729Z"
                                fill="#808191" />
                            <path fillRule="evenodd" clipRule="evenodd"
                                d="M14.9999 5.45459C10.4812 5.45459 6.81812 9.11771 6.81812 13.6364C6.81812 18.1551 10.4812 21.8182 14.9999 21.8182C19.5186 21.8182 23.1818 18.1551 23.1818 13.6364C23.1818 9.11771 19.5186 5.45459 14.9999 5.45459ZM14.9997 8.18188C18.0122 8.18188 20.4543 10.624 20.4543 13.6364C20.4543 16.6489 18.0122 19.091 14.9997 19.091C11.9873 19.091 9.54517 16.6489 9.54517 13.6364C9.54517 10.624 11.9873 8.18188 14.9997 8.18188Z"
                                fill="#808191" />
                            <path fillRule="evenodd" clipRule="evenodd"
                                d="M15.0066 19.0911C19.4226 19.0911 22.7977 20.6431 24.9792 23.743C25.4126 24.3589 25.2647 25.2095 24.6488 25.6429C24.0329 26.0764 23.1822 25.9284 22.7488 25.3125C21.1085 22.9817 18.5787 21.8183 15.0066 21.8183C11.4268 21.8183 8.89371 22.9866 7.25357 25.328C6.82148 25.9448 5.97116 26.0946 5.35433 25.6625C4.7375 25.2304 4.58774 24.3801 5.01983 23.7632C7.20065 20.65 10.5808 19.0911 15.0066 19.0911Z"
                                fill="#808191" />
                            </svg>
                        </a>
                    </div>
                </div>


                {data.stripe.connected === true || data.authorizeNet ? (
                    <div className="input-group col-md-12">
                        <div className="col-md-12" style={{"marginTop":"30px"}}>
                            <div className="row">
                            <div className="col-md-auto">
                                <label className="search-bar-icon">
                                <svg style={{top:'55%'}} className="icon" width="21" height="22" viewBox="0 0 21 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                    d="M9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0ZM9 2C12.866 2 16 5.13401 16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2Z"
                                    fill="#808191" />
                                    <path fillRule="evenodd" clipRule="evenodd"
                                    d="M14.3596 14.2325C14.7838 13.8788 15.4143 13.9359 15.768 14.3601L20.7688 20.357C21.1225 20.7812 21.0654 21.4118 20.6412 21.7655C20.2171 22.1192 19.5865 22.0621 19.2328 21.6379L14.232 15.6409C13.8783 15.2168 13.9354 14.5862 14.3596 14.2325Z"
                                    fill="#808191" />
                                </svg>
                                <input id="transactions-search" className="form-control"
                                    style={{"width":"100%","margin":"auto"}} type="text"
                                    placeholder="Search" />
                                </label>
                            </div>
                            </div>

                        </div>
                        <div style={{"marginTop":"0px"}} className="col-md-12">
                            <div className="table-responsive">
                            <table className="table" id="transactionsTable">
                                {
                                    loading === true ? (
                                        <CircleLoader />
                                    ) : (
                                        <>
                                            <thead className="thead">
                                            <tr>
                                                <th>Amount</th>
                                                <th>Status</th>
                                                <th>Description</th>
                                                <th>Customer</th>
                                                <th>Date</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                transactions.map(transaction => {
                                                    return (
                                                        <tr id={transaction.id}>
                                                            <td className="clickable">{currencies[transaction.currency.toUpperCase()].symbol.default.display}{transaction.amount_received/100}</td>
                                                            <td className="clickable">
                                                                <div style={{display: 'flex'}}>
                                                                    <div style={{
                                                                        'marginRight': '5px',
                                                                        "fontSize": "12px",
                                                                        "borderRadius": "3px",
                                                                        "textAlign": "center",
                                                                        "paddingLeft": "5px",
                                                                        "paddingRight": "5px",
                                                                        color:  transaction.status !== 'succeeded' ? '#f55b6a' : 'rgba(44, 216, 161, 1)',
                                                                        backgroundColor: transaction.status !== 'succeeded' ? "rgba(245, 91, 106, 0.3)" : 'rgba(44, 216, 161, 0.25)'}}>
                                                                          {transaction.status}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="clickable">{transaction.description}</td>
                                                            <td className="clickable">{transaction.customer}</td>
                                                            <td className="clickable">{new Date(transaction.created*1000).toLocaleString()}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </>
                                    )
                                }
                                
                            </table>
                            </div>

                        </div>
                    </div>
                ) : 
                    (
                        <div className="col-sm-12">
                            <label>Please connect to a stripe account and connect to a Discord server before selling any products.</label>
                        </div>
                    )
                }



            </div>
        
                
    </div>
    )
}


export default Transaction
