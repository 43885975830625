import React, { useEffect, useState, useRef } from "react";
import {submitPost} from "../../../Actions/Dashboard/posts";
import $ from "jquery"
import { Progress } from "reactstrap";


const AddPostModal = ({ pullPosts }) => {    
    const [title, setTitle] = useState("");
    const [visibility, setVisibility] = useState("live");
    const [visibleOn, setVisibleOn] = useState(new Date().toISOString());
    const [description, setDescription] = useState("");
    const [premium, setPremium] = useState(false);
    const [price, setPrice] = useState(0);
    const [file, setFile] = useState("");

    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [showProgressBar, setProgressBarVisibility] = useState(false);
  

    useEffect(() => {
    }, [])

    const resetValues = () => {
        setTitle("");
        setVisibility("live");
        setVisibleOn(new Date().toISOString());
        setDescription("");
        setPremium(false);
        setPrice(0);
        setFile("");
        setProgressBarVisibility(false);
        setUploadPercentage(0);
        $("#media-input").val("");

    }

    const handleFile = (files) => {
        setFile(files[0]);
    }

    const callSubmit = (event) => {
        event.preventDefault();
        var form = {
            title: title,
            visibility: visibility,
            visibleOn: visibleOn,
            description: description,
            premium: premium,
            price: price
        }
        setProgressBarVisibility(true);
        submitPost(file, form, resetValues, pullPosts, setUploadPercentage, setProgressBarVisibility);

    }

    return (
        <div className="modal fade" id="postAddModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title">Create a Post</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                        d="M1.6129 0.209705L1.70711 0.292893L8.07107 6.65607L14.435 0.292893C14.8256 -0.0976311 15.4587 -0.0976311 15.8492 0.292893C16.2097 0.653377 16.2375 1.22061 15.9324 1.6129L15.8492 1.70711L9.48607 8.07107L15.8492 14.435C16.2398 14.8256 16.2398 15.4587 15.8492 15.8492C15.4888 16.2097 14.9215 16.2375 14.5292 15.9324L14.435 15.8492L8.07107 9.48607L1.70711 15.8492C1.31658 16.2398 0.683418 16.2398 0.292893 15.8492C-0.0675907 15.4888 -0.0953203 14.9215 0.209705 14.5292L0.292893 14.435L6.65607 8.07107L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.623337 -0.0375504 1.12751 -0.0883879 1.5114 0.140381L1.6129 0.209705Z"
                        fill="black" />
                    </svg>
                </button>
                </div>
                <form onSubmit={(event) => {callSubmit(event)}} id="add-post-form">
                <div className="modal-body">

                    <div className="form-group">
                        <label className="col-form-label">Title</label>
                        <input value={title} onChange={(event) => {setTitle(event.target.value)}} placeholder="Title" type="text" name="title"
                            className="form-control" required />
                    </div>


                    <div className="form-group">
                        <label className="col-form-label">Visibility</label>
                        <select onChange={(event) => { setVisibility(event.target.value);}} value={visibility} className="form-control select" name="visiblity" required id="relRec">
                            <option value="live">Visible</option>
                            <option value="hidden">Hidden</option>
                        </select>
                    </div>


                    <div className="form-group">
                        <label className="col-form-label">Description</label>
                        <input value={description} onChange={(event) => {setDescription(event.target.value)}} placeholder="Post description" type="text" name="description"
                            className="form-control" required />
                    </div>

                    <div className="form-group">
                        <label className="col-form-label">Content</label>
                        <input onChange={(event) => {handleFile(event.target.files)}} type="file" name="description" id="media-input"
                            className="form-control" required />
                        <small className="form-text text-muted">Please do not refresh the page while post uploads.</small>
                    </div>

                    <div className="alert alert-danger" id="addPostErr" role="alert">
                    </div>
                </div>
                {showProgressBar ? <div className="form-group">
                <div className="progress-text text-center">
                    {parseInt(uploadPercentage) !== 100
                    ? `${uploadPercentage}%`
                    : "Processing..."}
                </div>
                <Progress
                    animated={parseInt(uploadPercentage) !== 100}
                    value={uploadPercentage}
                    style={{border: '2px solid #F55B6A', width: '65%', height: 30, margin: 'auto'}}
                />
                </div> : null}
                <div className="modal-footer">
                    <button style={{margin:'auto'}} type="submit"
                    className="btn btn-main-type">Create</button>
                </div>
                </form>
                
            </div>
            </div>
        </div>
    )
}


export default AddPostModal
