import $ from "jquery"
import bootbox from "bootbox";


// Pull 
export const getRewards = (setData, setLoading) => {
    $.get("/api/dashboard/rewards",
    (data, status) => {
        if (status == "success") {
            if (data.success == true) {
                setData(data.rewards);
                setLoading(false);
            } else {
                window.location.href = "/logout";
            }
        } else {
            window.location.href =  "/logout";        
        }
    });
}

// Add reward
export const createReward = (form, pullData) => {
    document.getElementById("reward-add-form").getElementsByClassName("btn-main-type")[0].disabled = true;
    document.getElementById("reward-add-form").getElementsByClassName("btn-main-type")[0].innerHTML = "...";
    $.ajax({
        url: "/api/dashboard/rewards",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(form),
        success: function(data) {
          if (data.success) {
            $("#addRewardModal").modal('toggle');
            pullData();
            bootbox.alert("Successfully Created Reward");
          } else {
            $("#rewardAddErr").text(data.error);
            $("#rewardAddErr").show();
            $("#rewardAddErr").delay(5000).hide(1);
            document.getElementById("reward-add-form").getElementsByClassName("btn-main-type")[0].disabled = false;
            document.getElementById("reward-add-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Add Reward";
          }
        }
      }).fail(function(jqXHR, textStatus, error) {
        $("#addRewardModal").modal('toggle');
         bootbox.alert("Failed request... Please try again", () => {
            window.location.reload();
        });
      });
}


export const deleteReward = (rewardId, pullData) => {
    bootbox.confirm({
        message: `Are you sure you want to delete this reward`,
        centerVertical: true,
        buttons: {
          confirm: {
            label: 'Yes',
            className: 'btn-main-type'
          },
          cancel: {
            label: 'No',
            className: 'btn-second-type'
          }
        },
        callback: function(result) {
          if (result == true) {
            $.ajax({
              url: `/api/dashboard/rewards/${rewardId}`,
              type: "DELETE",
              success: function(data) {
                if (data.success) {
                    pullData();
                    bootbox.alert("Successfully Deleted Reward");
                } else {
                    bootbox.alert(data.error);
                }
              }
            }).fail(function(jqXHR, textStatus, error) {
                bootbox.alert("Failed request... Please try again", () => {
                  window.location.reload();
              });
            });
          }
          return;
        }
      });
}

export const callAddReward = () => {
    $("#addRewardModal").modal('toggle');
}