import $ from "jquery"
import bootbox from "bootbox";


// Pull 
export const getDiscord = () => {
    return new Promise((resolve, reject) => {
        $.get("/api/dashboard/discord",
        (data, status) => {
            if (status == "success") {
                if (data.success == true) {
                    resolve(data)
                } else {
                    reject(data.error);
                }
            } else {
                reject("Failed request");
            }
        });
    })
}

// Submit updated Discord
export const submitDiscord = (form, pullDiscord) => {
    $.ajax({
        url: "/api/dashboard/discord",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(form),
        success: function (data) {
            if (data.success) {
                bootbox.alert("Successfully Updated Discord", () => {
                    pullDiscord();
                });
            } else {
               bootbox.alert(data.error);
            }
        }
    }).fail(function (jqXHR, textStatus, error) {
        bootbox.alert("Failed request... Please try again");
    });
}

// Pull embeds from API
export const getEmbeds = () => {
    return new Promise((resolve, reject) => {
        $.get("/api/dashboard/discord/embeds",
            (data, status) => {
                if (status == "success") {
                    if (data.success == true) {
                        resolve(data)
                    } else {
                        reject(data.error);
                    }
                } else {
                    reject("Failed request");
                }
            });
    });
}

// Submit updated embeds
export const submitEmbeds = (form, pullEmbeds) => {
    $.ajax({
        url: "/api/dashboard/discord/embeds",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(form),
        success: function (data) {
            if (data.success) {
                bootbox.alert("Successfully Updated Embeds", () => {
                    pullEmbeds();
                });
            } else {
                bootbox.alert(data.error)
            }
        }
    }).fail(function (jqXHR, textStatus, error) {
        bootbox.alert("Failed request... Please try again");
    });
}

export const connectDiscord = (clientId) => {
    window.open(`https://discord.com/api/oauth2/authorize?client_id=${clientId}&permissions=8&redirect_uri=https%3A%2F%2Fdashboard.ternarydev.com%2Fapi%2Fonboarding%2Fdiscord&scope=bot`, '_blank');
}

export const disconnectDiscord = (pullDiscord) => {
    $.post("/api/dashboard/discord/disconnect", function(data) {
        if (data.success) {
          bootbox.alert("Successfully Disconnected Discord Bot", function() {
            pullDiscord();
          });
        } else {
          bootbox.alert(data.error);
        }
      })
      .fail(function(jqXHR, textStatus, error) {
        bootbox.alert("Failed request... Please try again");
    });
}

// Pull bot settings
export const getBotSettings = () => {
    return new Promise((resolve, reject) => {
        $.get("/api/dashboard/discord/bot",
        (data, status) => {
            if (status == "success") {
                if (data.success == true) {
                    resolve(data)
                } else {
                    reject(data.error);
                }
            } else {
                reject("Failed request");
            }
        });
    })
}

// Submit bot settings
export const submitBotSettings = (form, pullBotSettings) => {
    $.ajax({
        url: "/api/dashboard/discord/bot",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(form),
        success: function (data) {
            if (data.success) {
                bootbox.alert("Successfully Updated Bot Settings", () => {
                    pullBotSettings();
                });
            } else {
                bootbox.alert(data.error)
            }
        }
    }).fail(function (jqXHR, textStatus, error) {
        bootbox.alert("Failed request... Please try again");
    });
}