import $ from "jquery"
import bootbox from "bootbox";
import Picker from 'vanilla-picker';
var picker1;
var picker2;
var domainTable;
var apiKeyTable;

export const submitDomain = (form, pullDomains) => {
    $.ajax({
        url: "/api/dashboard/settings/domain",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(form),
        success: function (data) {
            console.log(data);
            if (data.success) {            
                bootbox.alert("Successfully Added Domain", () => {
                    pullDomains();
                });
            } else {
               bootbox.alert(data.error);
            }
        }
    }).fail(function (jqXHR, textStatus, error) {
        bootbox.alert("Failed request... Please try again");
    });
}


export const submitDefaultDomain = (domain, pullData, pullDomains) => {
    $.ajax({
        url: `/api/dashboard/settings/domain/${domain}`,
        type: "POST",
        contentType: 'application/json',
        success: function (data) {
            if (data.success) {            
                pullData();
                pullDomains();
                bootbox.alert("Successfully Set Default Domain");
            } else {
               bootbox.alert(data.error);
            }
        }
    }).fail(function (jqXHR, textStatus, error) {
        bootbox.alert("Failed request... Please try again");
    });
}


export const deleteDomain = (form, pullDomains) => {
    $.ajax({
        url: "/api/dashboard/settings/domain",
        type: "DELETE",
        contentType: 'application/json',
        data: JSON.stringify(form),
        success: function (data) {
            console.log(data);
            if (data.success) {            
                bootbox.alert("Successfully Deleted Domain", () => {
                    pullDomains();
                });
            } else {
               bootbox.alert(data.error);
            }
        }
    }).fail(function (jqXHR, textStatus, error) {
        bootbox.alert("Failed request... Please try again");
    });
}


export const getDomains = (setDomains) => {
    $.get("/api/dashboard/settings/domains",
        (data, status) => {
            if (status == "success") {
                if (data.success == true) {
                    setDomains(data.domains)
                    createDomainTable();
                } else {
                    // window.location.href = "/logout";
                }
            } else {
                // window.location.href = "/logout";
            }
        });
}

export const getApiKeys = (setApiKeys) => {
    $.get("/api/dashboard/settings/api",
    (data, status) => {
        if (status == "success") {
            if (data.success == true) {
                setApiKeys(data.secret_keys)
                createApiKeysTable();
            } else {
                window.location.href = "/logout";
            }
        } else {
            window.location.href = "/logout";
        }
    });
}

export const submitSettings = (form, setGroupName) => {
    $.ajax({
        url: "/api/dashboard/settings/branding",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(form),
        success: function (data) {
            console.log(data);
            if (data.success) {
                setGroupName(form.group_name);
                bootbox.alert("Successfully Updated Branding");
            } else {
               bootbox.alert(data.error);
            }
        }
    }).fail(function (jqXHR, textStatus, error) {
        var errMsg = "Failed request... Please try again";
        bootbox.alert(errMsg);
    });
}

export const submitEmailSettings = (form) => {
    $.ajax({
        url: "/api/dashboard/settings/emails",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(form),
        success: function (data) {
            console.log(data);
            if (data.success) {
                bootbox.alert("Successfully Updated Email Settings");
            } else {
               bootbox.alert(data.error);
            }
        }
    }).fail(function (jqXHR, textStatus, error) {
        var errMsg = "Failed request... Please try again";
        bootbox.alert(errMsg);
    });
}


export const submitScripts = (form) => {
    $.ajax({
        url: "/api/dashboard/settings/scripts",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(form),
        success: function (data) {
            console.log(data);
            if (data.success) {
                bootbox.alert("Successfully Updated Scripts");
            } else {
               bootbox.alert(data.error);
            }
        }
    }).fail(function (jqXHR, textStatus, error) {
        var errMsg = "Failed request... Please try again";
        bootbox.alert(errMsg);
    });
}



export const uploadImg = (files, setGroupLogo) => {
    // Upload image
    const formData = new FormData();
    var file = files[0];
    var type = file.type;
    var blob = file.slice(0, file.size, type);
    var newFile = new File([blob], `${makeid(25)}.${type.split("/")[1]}`, {
        type: type
    });
    formData.append('upload', newFile);
    // // post form data
    const xhr = new XMLHttpRequest();

    // log response
    xhr.onload = () => {
        var response = JSON.parse(xhr.responseText);
        if (response.success) {
            setGroupLogo(response.image);
            return bootbox.alert("Successfully Uploaded Logo");
        } else {
            return bootbox.alert(response.error);
        }
    };
    // create and send the reqeust
    xhr.open('POST', "/api/dashboard/settings/image");
    xhr.send(formData);
}



export const uploadBg = (files, setBackground) => {
    if (!files) {
        // Remove bg POST
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            var response = JSON.parse(xhr.responseText);
            if (response.success) {
                setBackground("");
                return bootbox.alert("Successfully Removed Background");
            }
        };
        xhr.open('POST', "/api/dashboard/settings/background/remove");
        xhr.send();
    } else {
        // Upload image
        const formData = new FormData();
        var file = files[0];
        var type = file.type;
        var blob = file.slice(0, file.size, type);
        var newFile = new File([blob], `${makeid(25)}.${type.split("/")[1]}`, {
            type: type
        });
        formData.append('upload', newFile);
        // // post form data
        const xhr = new XMLHttpRequest();

        // log response
        xhr.onload = () => {
            var response = JSON.parse(xhr.responseText);
            if (response.success) {
                setBackground(response.image);
                return bootbox.alert("Successfully Updated Background");
            } else {
                return bootbox.alert(response.error);
            }
        };
        // create and send the reqeust
        xhr.open('POST', "/api/dashboard/settings/background");
        xhr.send(formData);
    }
}


export const initiatePicker = (primaryColor, setPrimaryColor, secondaryColor, setSecondaryColor) => {
    picker1 = new Picker({
        parent: document.querySelector('#primary_color_popup'),
        popup: 'bottom',
        color: primaryColor
    });
    
    picker2 = new Picker({
        parent: document.querySelector('#secondary_color_popup'),
        popup: 'bottom',
        color: secondaryColor
    });

    picker1.onChange = (color) => {
        setPrimaryColor(color.hex);
    };
    
    picker2.onChange = (color) => {
        setSecondaryColor(color.hex);
    };
}

export const showPicker1 = () => {
    picker1.show();
}

export const showPicker2 = () => {
    picker2.show();
}


function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}




const createDomainTable = () => {
    if ($("#domain-table").length) {
      domainTable = $('#domain-table').DataTable({
        lengthChange: false,
        ordering: false,
        initComplete: function (settings, json) {
          $('body').find('.dataTables_scrollBody').addClass("scrollbar");
          $("#domain-table").wrap("<div class='userTableScroll' style='margin-top:15px;overflow:auto; width:100%;position:relative;'></div>");
        }
      });
      return;
    } else {
      setTimeout(() => {
        createDomainTable()
      }, 50);
    }
  }

const createApiKeysTable = () => {
    if ($("#api-key-table").length) {
        apiKeyTable = $('#api-key-table').DataTable({
          lengthChange: false,
          ordering: false,
          initComplete: function (settings, json) {
            $('body').find('.dataTables_scrollBody').addClass("scrollbar");
            $("#api-key-table").wrap("<div class='userTableScroll' style='margin-top:15px;overflow:auto; width:100%;position:relative;'></div>");
          }
        });
        return;
      } else {
        setTimeout(() => {
            createApiKeysTable()
        }, 50);
      }
}


// Function that will trigger the API endpoint for rolling the key
export const rollApiKey = (key, setApiKeys) => {
    bootbox.confirm({
        message: 'Are you sure you want to roll this API key? Rolling will block this API key and generate a new one',
        centerVertical: true,
        buttons: {
            confirm: {
                label: 'Yes',
                className: 'btn-main-type'
            },
            cancel: {
                label: 'No',
                className: 'btn-second-type'
            }
        },
        callback: function (result) {
            if (result == true) {
                $.get(`/api/dashboard/settings/api/roll/${key}`,
                (data, status) => {
                    if (status == "success") {
                        if (data.success == true) {
                            setApiKeys(data.secret_keys);
                        } else {
                            return bootbox.alert(data.error);
                        }
                    } else {
                        return window.location.href = "/logout";
                    }
            });
            }
        }
    });
}