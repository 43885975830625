// USER LOGIN PAGE
import React, { useEffect, useState, useRef } from "react";
import {
  useParams
} from "react-router-dom";
import "bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../Components/Register/register.css';
import {authorize, applyCoupon, register} from '../Actions/register';
import { Helmet } from 'react-helmet'
import {loadStripe} from '@stripe/stripe-js';
import { Elements, CardElement } from '@stripe/react-stripe-js';
import StripeCardElementGrabber from "../Components/StripeCardElementGrabber"

const Register = () => {  
  const [data, setData] = useState({
    serverId: "",
    groupName: "",
    groupLogo: "",
    email: "",
    username: "",
    avatar: ""
  })
  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")
  const [loading, setLoading] = useState(false);
  const [stripePromise, setStripePromise] = useState(false)
  const grabberRef = useRef();

  // Pricing states
  const [total, setTotal] = useState(50);
  const [subtotal, setSubtotal] = useState(50);
  const [discount, setDiscount] = useState("");
  const [couponUsed, setCouponUsed] = useState("");
  const [coupon, setCoupon] = useState("");
  const [couponApplied, setCouponApplied] = useState(false);

  useEffect(() => {
    authorize(setData);
    var stripePromiseVar = loadStripe("pk_live_51HALOUB7YuVHBjztfMPBwP8b6oqnxa7gUOjBC774SC7s7LqwAnYVNWh6IAvIRff6ZNka02sJPghHth1NJdj9ePCJ00618iqVgj");
    setStripePromise(stripePromiseVar);
  }, [])
  

  const submitForm = () => {
    // Disable button
    setLoading(true);
    grabberRef.current.getToken().then((result) => {
      var form = {
        groupName: data.groupName,
        groupLogo: data.groupLogo,
        serverId: data.serverId,
        email: data.email,
        password: password,
        confirmPassword: password2,
        token: result.token.id,
        coupon: couponUsed
      }
      // Submit
      register(form, setLoading);
      return;
    }).catch((err) => {})
  }

  return (
    <div id="login-page">
      {
        stripePromise !== false ? (
          <div className={"register-form container"}>
            <div className="row">
              <div className="col-md-7">
                <form onSubmit={(event) => {event.preventDefault(); submitForm();}}>
                  <img src="/img/logo.png" style={{minWidth: 100, maxWidth:250, display: "block", margin: "auto"}} />
                  <div className="list-group">
                    <div style={{marginTop:30}} className="list-group-item d-flex gap-3 py-3" aria-current="true">
                      <img src={data.avatar} width="40" height="40" className="rounded-circle flex-shrink-0" />
                      <div style={{marginLeft:15}} className="d-flex gap-2 w-100 justify-content-between">
                        <div>
                          <h6 className="mb-0 mb-0-h6">{data.email}</h6>
                          <p className="mb-0 opacity-75">@{data.username}</p>
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item d-flex gap-3 py-3" aria-current="true">
                      <img src={data.groupLogo} width="40" height="40" className="rounded-circle flex-shrink-0" />
                      <div style={{marginLeft:15}} className="d-flex gap-2 w-100 justify-content-between">
                        <div>
                          <h6 className="mb-0 mb-0-h6">{data.groupName}</h6>
                          <p className="mb-0 opacity-75">ID: {data.serverId}</p>
                        </div>
                      </div>
                    </div>
                  </div>
    
                  <div className="row">
                    <div className="col-md-12">
    
                    <Elements stripe={stripePromise}>
                      <CardElement
                          id="cardElement"
                          options={{
                              style: {
                              base: {
                                  color: '#11142D',
                                  fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                                  fontSmoothing: 'antialiased',
                                  fontSize: '14px',
                                  '::placeholder': {
                                  color: '#B2B3BD'
                                  }
                              },
                              invalid: {
                                  color: '#fa755a',
                                  iconColor: '#fa755a'
                              }
                              },
                          }}
                      />
    
                      <StripeCardElementGrabber ref={grabberRef} />
    
                    </Elements>
                    
                      <div className="input-group">
                        <input value={coupon} onChange={e => setCoupon(e.target.value)} type="text" className="form-control input-field" placeholder="Coupon code" />
                        <div className="input-group-append">
                          <button onClick={() => {applyCoupon(coupon, setCouponUsed, setDiscount, setTotal, setCouponApplied)}} style={{fontSize:15, marginTop: 15, border: '1px solid rgba(222, 222, 222, 1)'}} type="button">Apply</button>
                        </div>
                      </div>
    
                      <input required className="input-field" name="password" type="password" name="" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}/>
                      <input required className="input-field" name="password2" type="password" name="" placeholder="Confirm Password" value={password2} onChange={e => setPassword2(e.target.value)}/>
    
    
                      {loading === true ? (
                        <input disabled style={{opacity: 0.7}} className="input-field" type="submit" value="Processing..." />
                      ) : (
                        <input className="input-field" type="submit" value="Register" />
                      )}
                    </div>
                  </div>
    
                </form>
              </div>
              <div className="col-md-5">
                <div class="row">
                  <div class="col-md-12 order-md-12 mb-12">
                    <h4 style={{marginTop:51.5}} class="d-flex justify-content-center align-items-center mb-3">
                      Order Summary
                    </h4>
                    <ul class="list-group mb-3">
                      <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                          <h6 class="my-0">Ternary Business License</h6>
                          <small style={{fontSize:12}} class="text-muted">The best cloud-based Subscription Membership Management Platform for your Website, Discord, and Stripe!</small>
                        </div>
                        <span class="text-muted">${subtotal.toFixed(2)}/mo</span>
                      </li>
                      {couponApplied === true ? (
                        <li class="list-group-item d-flex justify-content-between bg-light">
                          <div class="text-success">
                            <h6 class="my-0">Coupon code</h6>
                            <small>{couponUsed}</small>
                          </div>
                          <span class="text-success">{discount}</span>
                        </li>
                      ) : null }
                      <li class="list-group-item d-flex justify-content-between">
                        <span>Total (USD)</span>
                        <strong>${total.toFixed(2)}</strong>
                      </li>
                    </ul>
                    </div>
                  </div>
    
    
    
              </div>
    
            </div>
    
    
          </div>
        ) : null
      }


    </div>
    )
}

export default Register;
