import React, { useEffect, useState, useRef } from "react";
import './PageNavigation.css';

const PageNavigation = ({ pages }) => {

    useEffect(() => {
    }, [])

    return (
        <div className="page-navigation container">
            <div className="row">
                {pages.map(page => {
                    return (
                        <div onClick={() => {page.onclick()}} className="col-md-auto" style={{cursor: "pointer", color: page.active ? "#eb7179" : "#000"}}>
                            {page.title}
                        </div>
                    )
                })}
            </div>
        </div>
    )

}

export default PageNavigation
