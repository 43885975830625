import $ from "jquery"
import bootbox from "bootbox";
// Pull all releases from API
export const getTaxRates = (setTaxRates, setTaxRateId) => {
    $.get("/api/dashboard/stripe/taxes",
        (data, status) => {
            if (status == "success") {
                if (data.success == true) {
                    setTaxRates(data.taxRates);
                    if (data.taxRate === "") {
                        setTaxRateId("");
                    } else {
                        setTaxRateId(data.taxRate.id);
                    }
                    return;
                } else {
                    return window.location.href = "/logout";
                }
            } else {
                return window.location.href = "/logout";
            }
        });
}

// Submit updated Discord
export const submitStripe = (form, pullData, pullTaxRates) => {
    $.ajax({
        url: "/api/dashboard/stripe",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(form),
        success: function (data) {
            if (data.success) {
                bootbox.alert("Successfully Updated Stripe Settings", () => {
                    pullData();
                    pullTaxRates();
                });
            } else {
               bootbox.alert(data.error);
            }
        }
    }).fail(function (jqXHR, textStatus, error) {
        bootbox.alert("Failed request... Please try again");
    });
}

export const connectStripe = () => {
    fetch("/api/dashboard/stripe/connect")
    .then((response) => response.json())
    .then((json) => {
        // Check the response
        if (json.success) {
            // Set Data
            window.open(json.link, "_blank");
        } else {
            return bootbox.alert(json.error);
        }
    });
}