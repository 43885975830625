import React, { useEffect, useState, useRef } from "react";
import { addTeammate } from "../../../Actions/Dashboard/team";
const AddTeamModal = ({ pullTeam }) => {

    const [email, setEmail] = useState("");
    const [type, setType] = useState("read");

    useEffect(() => {
    }, [])

    const callSubmit = (event) => {
        event.preventDefault();
        var form = {
            email: email,
            type: type
        };
        addTeammate(form, setEmail, pullTeam);
    }

    return (
        <div className="modal fade" id="addTeamModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title">Invite Teammate</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                        d="M1.6129 0.209705L1.70711 0.292893L8.07107 6.65607L14.435 0.292893C14.8256 -0.0976311 15.4587 -0.0976311 15.8492 0.292893C16.2097 0.653377 16.2375 1.22061 15.9324 1.6129L15.8492 1.70711L9.48607 8.07107L15.8492 14.435C16.2398 14.8256 16.2398 15.4587 15.8492 15.8492C15.4888 16.2097 14.9215 16.2375 14.5292 15.9324L14.435 15.8492L8.07107 9.48607L1.70711 15.8492C1.31658 16.2398 0.683418 16.2398 0.292893 15.8492C-0.0675907 15.4888 -0.0953203 14.9215 0.209705 14.5292L0.292893 14.435L6.65607 8.07107L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.623337 -0.0375504 1.12751 -0.0883879 1.5114 0.140381L1.6129 0.209705Z"
                        fill="black" />
                    </svg>
                </button>
                </div>
                <form onSubmit={(event) => {callSubmit(event)}} id="add-teammate-form">
                <div className="modal-body">
                    <div className="form-group">
                    <label for="teammateEmail" className="col-form-label">Email</label>
                    <input value={email} onChange={(event) => {setEmail(event.target.value)}} placeholder="johndoe@gmail.com" type="email" name="email"
                        className="form-control" id="teammateEmail" required />
                    </div>
                    <div className="form-group">
                        <label for="teammateType" className="col-form-label">Type</label>
                        <select className="form-control select" name="type" className="form-control" id="teammateType" value={type} onChange={(event) => {setType(event.target.value)}}>
                            <option value="read">
                                Read
                            </option>
                            <option value="write">
                                Write
                            </option>
                        </select>
                        <small className="form-text text-muted">Read account type will only give the user permission to read the dashboard data but no permissions to make changes, create, edit, or delete.</small>
                    </div>
                    <div className="alert alert-danger" id="addTeamErr" role="alert">
                    </div>
                </div>
                <div className="modal-footer">
                    <button style={{margin:'auto'}} type="submit"
                    className="btn btn-main-type">Invite</button>
                </div>
                </form>
            </div>
            </div>
        </div>
    )
}


export default AddTeamModal
