import React, { useEffect, useState, useRef } from "react";
import Select from 'react-select'
import { editCatalogue } from "../../../Actions/Dashboard/catalogue";
import $ from "jquery"

const EditCatalogueModal = ({ selectedCatalogue, posts, pullPosts, pullCatalogue }) => {    
    const [id, setId] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [selectedPosts, setSelectedPosts] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (selectedCatalogue && selectedCatalogue._id) {
            setId(selectedCatalogue._id);
            setTitle(selectedCatalogue.title);
            setDescription(selectedCatalogue.description);
            // Set selected posts value
            setSelectedPosts(selectedCatalogue.posts);


            // Set selected posts for dropdown
            var selectedOptionsFormatted = posts.map(post => {
                if (selectedCatalogue.posts.includes(post.postId)) {
                    return (
                        {value: post.postId, label: `${post.title}`}
                    )
                }
            });
            setSelectedOptions(selectedOptionsFormatted);

            // Set all posts options
            var optionsFormatted = (posts.map(post => {
                return (
                    {value: post.postId, label: `${post.title}`}
                )
            }))
 
            setOptions(optionsFormatted);
        }
    }, [selectedCatalogue])

    const resetValues = () => {
        setTitle("");
        setDescription("");
        setSelectedPosts([]);
    }

    const selectOption = (selectedOptions) => {
        var posts = selectedOptions.map(post => {
            return post.value;
        });
        setSelectedOptions(selectedOptions);
        setSelectedPosts(posts);
    };
    
    const callSubmit = (event) => {
        event.preventDefault();
        var form = {
            title: title,
            description: description,
            posts: selectedPosts
        };
        editCatalogue(id, form, pullCatalogue, pullPosts, resetValues);
    }

    return (
        <div className="modal fade" id="editCatalogueModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Edit Catalogue</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                        d="M1.6129 0.209705L1.70711 0.292893L8.07107 6.65607L14.435 0.292893C14.8256 -0.0976311 15.4587 -0.0976311 15.8492 0.292893C16.2097 0.653377 16.2375 1.22061 15.9324 1.6129L15.8492 1.70711L9.48607 8.07107L15.8492 14.435C16.2398 14.8256 16.2398 15.4587 15.8492 15.8492C15.4888 16.2097 14.9215 16.2375 14.5292 15.9324L14.435 15.8492L8.07107 9.48607L1.70711 15.8492C1.31658 16.2398 0.683418 16.2398 0.292893 15.8492C-0.0675907 15.4888 -0.0953203 14.9215 0.209705 14.5292L0.292893 14.435L6.65607 8.07107L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.623337 -0.0375504 1.12751 -0.0883879 1.5114 0.140381L1.6129 0.209705Z"
                        fill="black" />
                    </svg>
                    </button>
                </div>
                    <form id="edit-catalogue-form" onSubmit={(event) => {callSubmit(event)}}>
                        <div className="modal-body">
                        <div className="form-group">
                            <label className="col-form-label">Title</label>
                            <input value={title} onChange={(event) => {setTitle(event.target.value)}} placeholder="Playlist" type="text" className="form-control" required />
                        </div>

                        <div className="form-group">
                            <label className="col-form-label">Description</label>

                            <textarea className="form-control" rows="3" value={description} required 
                                onChange={(event) => {setDescription(event.target.value)}
                             }></textarea>

                        </div>

                        <div className="form-group">
                            <label className="col-form-label">Posts</label>

                            <Select value={selectedOptions} onChange={selectOption} options={options} isMulti className="basic-multi-select" />

                            {/* <select class="form-control select" multiple
                                value={selectedPosts}
                                onChange={(event) => {
                                    var values = [];
                                    var options = event.target.options;
                                    for (var i = 0; i < options.length;  i++) {
                                        if (options[i].selected) {
                                            values.push(options[i].value);
                                        }
                                    }
                                    setSelectedPosts(values);
                                }}
                            >
                                {posts.map(post => {
                                    return (
                                        <option value={post.postId}>{post.title} ({post.type})</option>
                                    )
                                })}
                            </select>
                            <small className="form-text text-muted">You can select multiple posts by using control, cmd, or shift click.</small> */}

                        </div>

                        <div className="alert alert-danger" id="catalogueEditErr" role="alert">
                        </div>
                        </div>
                        <div className="modal-footer">
                            <button style={{"margin":"auto"}} type="submit" className="btn btn-main-type">Edit Catalogue</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


export default EditCatalogueModal

