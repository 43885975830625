import React, { useEffect, useState } from "react";
import {addUserSubmit} from "../../../Actions/Dashboard/users";

const CreateUserModal = ({ discord, pullUsers }) => {    
    const [customerId, setCustomerId] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [subscriptionType, setSubscriptionType] = useState("recurring");
    const [subscriptionId, setSubscriptionId] = useState("");
    const [priceId, setPriceId] = useState("");
    const [discordRoles, setDiscordRoles] = useState([]);
    const [key, setKey] = useState("");
    const [discordId, setDiscordId] = useState("");

    useEffect(() => {
    }, [])


    const resetValues = () => {
      setCustomerId("");
      setCustomerEmail("");
      setSubscriptionType("recurring");
      setSubscriptionId("");
      setPriceId("");
      setKey("");
      setDiscordId("");
    }

    const submit = (event) => {
        event.preventDefault();
        var form = {
            addcustomerid: customerId,
            addcustomeremail: customerEmail,
            addsubscriptiontype: subscriptionType,
            addsubscriptionid: subscriptionId,
            addsubscriptionplan: priceId,
            addkey: key,
            adddiscordid: discordId,
            adddiscordroles: discordRoles
        }
        console.log(form);
        addUserSubmit(form, resetValues, pullUsers);
    }


    return (
        <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Add User</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M1.6129 0.209705L1.70711 0.292893L8.07107 6.65607L14.435 0.292893C14.8256 -0.0976311 15.4587 -0.0976311 15.8492 0.292893C16.2097 0.653377 16.2375 1.22061 15.9324 1.6129L15.8492 1.70711L9.48607 8.07107L15.8492 14.435C16.2398 14.8256 16.2398 15.4587 15.8492 15.8492C15.4888 16.2097 14.9215 16.2375 14.5292 15.9324L14.435 15.8492L8.07107 9.48607L1.70711 15.8492C1.31658 16.2398 0.683418 16.2398 0.292893 15.8492C-0.0675907 15.4888 -0.0953203 14.9215 0.209705 14.5292L0.292893 14.435L6.65607 8.07107L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.623337 -0.0375504 1.12751 -0.0883879 1.5114 0.140381L1.6129 0.209705Z" fill="black"/>
                    </svg>
                  </button>
                </div>
                <form onSubmit={(event) => {submit(event)}} id="add-form">
                  <div className="modal-body">
                    <div className="form-group">
                      <label for="addCstmrID" className="col-form-label">Customer ID:</label>
                      <input onChange={(event) => { setCustomerId(event.target.value);}} value={customerId} placeholder="cus_HvRygr19zrwBEv" type="text" name="addcustomerid" className="form-control" id="addCstmrID" required />
                      <small className="form-text text-muted">Find your customers <a target="_blank" href="https://dashboard.stripe.com/customers">here</a> (copy the customer ID)</small>
                    </div>
                    <div className="form-group">
                      <label for="addCstmrEmail" className="col-form-label">Customer Email:</label>
                      <input onChange={(event) => { setCustomerEmail(event.target.value);}} value={customerEmail} placeholder="johndoe@gmail.com" type="email" name="addcustomeremail" className="form-control" id="addCstmrEmail" required />
                    </div>
                    <div className="form-group">
                      <label for="addSubType" className="col-form-label">Subscription Type:</label>
                      <select onChange={(event) => { setSubscriptionType(event.target.value);}} value={subscriptionType} name="addsubscriptiontype" className="form-control select" id="addSubType" required>
                        <option value="recurring" selected>Recurring</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label for="addSubID" className="col-form-label">Subscription ID:</label>
                      <input onChange={(event) => { setSubscriptionId(event.target.value);}} value={subscriptionId} placeholder="sub_HvRyP89cOm0jLb" type="text" name="addsubscriptionid" className="form-control" id="addSubID" required />
                      <small className="form-text text-muted">Find your subscriptions <a target="_blank" href="https://dashboard.stripe.com/subscriptions">here</a> (copy the subscription ID)</small>

                    </div>
                    <div className="form-group">
                      <label for="addSubID" className="col-form-label">Price ID:</label>
                      <input onChange={(event) => { setPriceId(event.target.value);}} value={priceId} placeholder="price_1H7WgeAmT7jWt9ItRyMay2zc" type="text" name="addsubscriptionplan" className="form-control" id="addSubPlan" required />
                      <small className="form-text text-muted">Find your prices <a target="_blank" href="https://dashboard.stripe.com/products">here</a> (copy the price ID located inside the product page)</small>
                    </div>

                    <div className="form-group">
                      <label for="addDiscRoles" className="col-form-label">Discord Roles:</label>
                      <select multiple className="form-control select" name="adddiscordroles" className="form-control" id="addDiscRoles" required
                        value={discordRoles} 
                        onChange={(event) => {
                            var values = [];
                            var options = event.target.options;
                            for (var i = 0; i < options.length;  i++) {
                                if (options[i].selected) {
                                    values.push(options[i].value);
                                }
                            }
                            setDiscordRoles(values);
                        }}
                      >
                      {
                            discord.botStatus.roles.map(role => {
                                return (
                                    <option value={role.id}>{role.name}</option>
                                )
                            })
                        }
                      </select>
                    </div>

                    <div className="form-group">
                      <label for="addKey" className="col-form-label">Key:</label>
                      <input onChange={(event) => { setKey(event.target.value);}} value={key} placeholder="7b133308-24d3-448b-b88a-8577a19fd536" type="text" name="addkey" className="form-control" id="addKey" required />
                      <small className="form-text text-muted">Make sure to use a unique license key for each user</small>

                    </div>
                    <div className="form-group">
                      <label for="addDiscID" className="col-form-label">User's Discord ID:</label>
                      <input onChange={(event) => { setDiscordId(event.target.value);}} value={discordId} placeholder="689513639122567631 (leave blank if none)" type="text" name="adddiscordid" className="form-control" id="addDiscID" />
                      <small className="form-text text-muted">If you need help on finding this click <a target="_blank" href="https://www.swipetips.com/how-to-find-your-discord-user-id/s">here</a></small>
                    </div>
                  
                    <div className="alert alert-danger" id="addErr" role="alert">
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-main-type" style={{"display":"block","margin":"0 auto","float":"none"}}>Add User</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
    )
}

export default CreateUserModal;

