import React, { useEffect, useState, useRef } from "react";
import "./cataloguepost.css"

const Post = ({ post }) => {
    const [date, setDate] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [type, setType] = useState("");
    const [source, setSource] = useState("");

    useEffect(() => {
        setTitle(post.title);
        setDate(post.visibleOn);
        setDescription(post.description);
        if (post.type.includes("image")) {
            setType("image");
            setSource(`/api/user/posts/media/${post.postId}`);
        }
        else if (post.type === "application/pdf") {
            setType("pdf");
            setSource(`/api/user/posts/media/${post.postId}#toolbar=0&navpanes=0`);            
        }
        else if (post.type.includes("video")) {
            setType("video");
            setSource(`/api/user/posts/video/${post.postId}`)
        }
    }, [[post]])

    return (
        <>
        {
            source !== "" ? (
                <div className="post">
                <h5 className="date">{date.substring(0,10)}</h5>
                <div className="post-block">
                    <div className="post-content">
                         <>
                             {type === "video" ? (
                             <video controls>
                                <source src={source}></source>
                             </video>
                             ) : null}
                             {type === "pdf" ? (
                                 <iframe src={source} className="pdf" />
                             ) : null}
                             {type === "image" ? (
                                <img onClick={() => {}} src={source}/>
                             ) : null}
                         </>
                    </div>
                    <div className="post-information">
                        <p className="tag">🔓 Free</p>
                        <h5 className="title">{title}</h5>
                        <p className="description">{description}</p>
                    </div>
                </div>
             </div>
            ) : null
        }
        </>
    )
}

export default Post