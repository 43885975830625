import React, {useEffect, forwardRef, useImperativeHandle} from "react";
import {Elements, CardElement, useElements, useStripe} from '@stripe/react-stripe-js';

const StripeCardElementGrabber = forwardRef(({setElement}, ref) => {
    const stripe = useStripe()
    const elements = useElements()
    useImperativeHandle(ref, () => ({

        getToken() {
            return new Promise((resolve, reject) => {
                const cardElement = elements.getElement(CardElement);
                stripe.createToken(cardElement).then((result) => {
                    if(result.error) {
                        reject(result.error)
                    } else {
                        resolve(result)
                    }
                }).catch((err) => {
                    reject(err)
                })
    
    
            })
            
        }
    
      }));
     

    return(<></>)
})

export default StripeCardElementGrabber;