import $ from "jquery"
import bootbox from "bootbox";
var cancelTable;

// Pull all releases from API
export const getCancels = (setCancels, setLoading) => {
    $.get("/api/dashboard/cancels",
        (data, status) => {
            if (status == "success") {
                if (data.success == true) {
                    setLoading(false);
                    setCancels(data.cancels);
                    createCancelTable();
                    return;
                } else {
                    return window.location.href = "/logout";
                }
            } else {
                return window.location.href = "/logout";
            }
        });
}


const createCancelTable = () => {
    if ($("#cancelTable").length) {


        cancelTable = $('#cancelTable').DataTable({
            ordering: false,
            lengthChange: false,
            initComplete: function (settings, json) {
                $('body').find('.dataTables_scrollBody').addClass("scrollbar");
                $("#cancelTable").wrap("<div class='userTableScroll' style='overflow:auto; width:100%;position:relative;'></div>");
            }
        });
        
        $("#cancel-search").on("keyup change", function () {
            cancelTable.search(this.value).draw();
        });


    } else {
        setTimeout(createCancelTable, 50);
    }
}