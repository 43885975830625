import $ from "jquery"
import bootbox from "bootbox";
var releaseTable;

// Pull all releases from API
export const getReleases = (orgData, setProducts, setReleases, setLoading, setRoles) => {
    $.get("/api/dashboard/releases",
        (data, status) => {
            if (status == "success") {
                if (data.success == true) {
                    setLoading(false);
                    setReleases(data.releases);
                    setProducts(data.products);

                    // Get the active amount of members per product
                    var prices = [];
                    for (var i = 0; i < data.releases.length; i++) {
                        prices.push(data.releases[i].planId);
                    }
                    getRoles(setRoles);

                    // Create DataTable
                    createReleaseTable(orgData);
                    return;
                } else {
                    return window.location.href = "/logout";
                }
            } else {
                return window.location.href = "/logout";
            }
        });
}

export const getSubscriptions = (releases, setUsers) => {
    let subscriptions = (planId) => {
        return new Promise((resolve, reject) => {
            $.get(`/api/dashboard/releases/${planId}/subscriptions`,
                (data, status) => {
                    if (status == "success") {
                        if (data.success == true) {
                            resolve({planId: planId, subscriptions: data.subscriptions});
                        } else {
                            resolve([]);
                        }
                    } else {
                        resolve([]);
                    }
                });
        });
    }    
    Promise.all(releases.map(async (release) => {
        return await subscriptions(release.planId);
    })).then((data) => {
        console.log("Got all subscriptions");
        console.log(data);
        // Set user data
        let users = {};
        for (var i = 0; i < data.length; i++) {
            if (!data.subscriptions || !data.subscriptions.length) {
                return;
            }
            var revenue = 0;

            var toCancelCount = 0;
            var activeCount = 0;
            for (var j = 0; j < data[i].subscriptions.active.length; j++) {
                // console.log(data[i].subscriptions.active[j]);
                if (data[i].subscriptions.active[j].cancel_at_period_end) {
                    toCancelCount++;
                } else {
                    activeCount++;
                }

                // DECLARE subscription variables
                var amount = data[i].subscriptions.active[j].items.data[0].price.unit_amount / 100;
                var discount = data[i].subscriptions.active[j].discount;
                var recurring = data[i].subscriptions.active[j].items.data[0].price.recurring;
                // var created = data[i].subscriptions.active[j].created;
                var created = new Date(data[i].subscriptions.active[j].created * 1000);

                // Calculate discount
                if (discount && discount.coupon.percent_off) {
                    amount = amount - (amount * (discount.coupon.percent_off / 100));
                }
                if (discount && discount.coupon.amount_off) {
                    amount = amount - (discount.coupon.amount_off / 100);
                }

                // Calculate how much in total the user has paid
                if (recurring) {

                    // If interval is month calculate how many months he's been active, and divide by interval count (then round down)
                    if (recurring.interval == "month") {
                        var months = Math.floor((Date.now() - created) / 1000 / 60 / 60 / 24 / 30);
                        if (months == 0) {
                            months = 1;
                        }
                        amount = amount * (Math.floor(months / recurring.interval_count));
                    }

                    // If interval is year calculate how many years he's been active, and divide by interval count (then round down)
                    if (recurring.interval == "year") {
                        var years = Math.floor((Date.now() - created) / 1000 / 60 / 60 / 24 / 365);
                        if (years == 0) {
                            years = 1;
                        }
                        amount = amount * (Math.floor(years / recurring.interval_count));
                    }

                    // If interval is week calculate how many weeks he's been active, and divide by interval count (then round down)
                    if (recurring.interval == "week") {
                        var weeks = Math.floor((Date.now() - created) / 1000 / 60 / 60 / 24 / 7);
                        if (weeks == 0) {
                            weeks = 1;
                        }
                        amount = amount * (Math.floor(weeks / recurring.interval_count));
                    }

                    // If interval is day calculate how many days he's been active, and divide by interval count (then round down)
                    if (recurring.interval == "day") {
                        var days = Math.floor((Date.now() - created) / 1000 / 60 / 60 / 24);
                        if (days == 0) {
                            days = 1;
                        }
                        amount = amount * (Math.floor(days / recurring.interval_count));
                    }

                }

                revenue += amount;

            }

            for (var j = 0; j < data[i].subscriptions.ended.length; j++) {
                var amount = data[i].subscriptions.ended[j].items.data[0].price.unit_amount / 100;
                var discount = data[i].subscriptions.ended[j].discount;
                var recurring = data[i].subscriptions.ended[j].items.data[0].price.recurring;
                var created = new Date(data[i].subscriptions.ended[j].created * 1000);
                if (discount && discount.coupon.percent_off) {
                    amount = amount - (amount * (discount.coupon.percent_off / 100));
                }
                if (discount && discount.coupon.amount_off) {
                    amount = amount - (discount.coupon.amount_off / 100);
                }

                // Calculate how much in total the user has paid
                if (recurring) {

                    // If interval is month calculate how many months he's been active, and divide by interval count (then round down)
                    if (recurring.interval == "month") {
                        var months = Math.floor((Date.now() - created) / 1000 / 60 / 60 / 24 / 30);
                        amount = amount * (Math.floor(months / recurring.interval_count));
                    }

                    // If interval is year calculate how many years he's been active, and divide by interval count (then round down)
                    if (recurring.interval == "year") {
                        var years = Math.floor((Date.now() - created) / 1000 / 60 / 60 / 24 / 365);
                        amount = amount * (Math.floor(years / recurring.interval_count));
                    }

                    // If interval is week calculate how many weeks he's been active, and divide by interval count (then round down)
                    if (recurring.interval == "week") {
                        var weeks = Math.floor((Date.now() - created) / 1000 / 60 / 60 / 24 / 7);
                        amount = amount * (Math.floor(weeks / recurring.interval_count));
                    }

                    // If interval is day calculate how many days he's been active, and divide by interval count (then round down)
                    if (recurring.interval == "day") {
                        var days = Math.floor((Date.now() - created) / 1000 / 60 / 60 / 24);
                        amount = amount * (Math.floor(days / recurring.interval_count));
                    }

                }


            }


            let obj = {
                earnings: revenue,
                active: activeCount,
                toCancel: toCancelCount,
                canceled: data[i].subscriptions.ended.length
            };
            users[data[i].planId] = obj;
        };

        window.localStorage.setItem("USERSUBSCRIPTIONS", JSON.stringify(users));

        console.log("DONe with users");
        console.log(users);
        setUsers(users);

    });

};

const getRoles = (setRoles) => {
    $.ajax({
        url: "/api/dashboard/discord/roles",
        type: "GET",
        contentType: 'application/json',
        success: function (data) {
            if (data.success) {
                if (data.success) {
                    var roles = data.roles;
                    var rolesObj = {};
                    for (var i = 0; i < roles.length; i++) {
                        rolesObj[roles[i].id] = roles[i].name;
                    }
                    setRoles(rolesObj);
                }
            }
        }
    }).fail(function (jqXHR, textStatus, error) {
        console.log(error);
    });
}

const createReleaseTable = (data) => {
    if ($("#releaseTable").length) {
        $("#releaseTable .clickable").on("click", function (e) {
            var domain = $(this).parent().attr("domain");
            var release = JSON.parse($(this).parent().attr("data-identify"));
            console.log(domain)
            if (release.password == "null") {
                window.open(domain, '_blank');
            } else {
                window.open(`${domain}?password=${release.password}`, '_blank');
            }
        });

        releaseTable = $('#releaseTable').DataTable({
            ordering: false,
            lengthChange: false,
            initComplete: function (settings, json) {
                $('body').find('.dataTables_scrollBody').addClass("scrollbar");
                $("#releaseTable").wrap("<div class='userTableScroll' style='overflow:auto; width:100%;position:relative;'></div>");
            }
        });
        
        $("#release-search").on("keyup change", function () {
            releaseTable.search(this.value).draw();
        });

        $(".checkbox-input-all-releases").on("click", function () {
            var rows = document.getElementById("releaseTable").getElementsByTagName("tbody")[0].getElementsByTagName("tr");
            for (var i = 0; i < rows.length; i++) {
              var row = rows[i];
              row.getElementsByClassName("checkbox-input-release")[0].checked = document.getElementsByClassName("checkbox-input-all-releases")[0].checked;
            }
        });

        $(document).on('click', '#releaseTable_previous', function(){
            var rows = document.getElementById("releaseTable").getElementsByTagName("tbody")[0].getElementsByTagName("tr");
            for (var i = 0; i < rows.length; i++) {
                var row = rows[i];
                row.getElementsByClassName("checkbox-input-release")[0].checked = document.getElementsByClassName("checkbox-input-all-releases")[0].checked;
            }
        });
      
      
          $(document).on('click', '#releaseTable_next', function(){
            var rows = document.getElementById("releaseTable").getElementsByTagName("tbody")[0].getElementsByTagName("tr");
            for (var i = 0; i < rows.length; i++) {
                var row = rows[i];
                row.getElementsByClassName("checkbox-input-release")[0].checked = document.getElementsByClassName("checkbox-input-all-releases")[0].checked;
            }
        });


    } else {
        setTimeout(createReleaseTable, 50);
    }
}
  
export const callEditRelease = (setEditRelease) => {
    let selectedReleases = document.getElementsByClassName("checkbox-input-release");
    var selected = null;
    for (var i = 0; i < selectedReleases.length; i++) {
      if (selectedReleases[i].checked == true) {
        selected = selectedReleases[i].parentNode.parentNode.parentNode;
      }
    }
    if (selected != null) {
      var release = JSON.parse(selected.getAttribute("data-identify"));
      setEditRelease(release);
      $("#relEditModal").modal('toggle');
    } else {
      // show error message stating to click a user in the list to edit
      bootbox.alert("Please select a release");
    }
}


export const editReleaseSubmit = (event, form, pullReleases) => {
    event.preventDefault();
    document.getElementById("release-edit-form").getElementsByClassName("btn-main-type")[0].disabled = true;
    document.getElementById("release-edit-form").getElementsByClassName("btn-main-type")[0].innerHTML = "...";
    $.ajax({
        url: `/api/dashboard/releases`,
        type: "PUT",
        contentType: 'application/json',
        data: JSON.stringify(form),
        success: function (data) {
            if (data.success) {
                $("#relEditModal").modal('toggle');
                bootbox.alert("Successfully Edited Product", () => {
                    document.getElementById("release-edit-form").getElementsByClassName("btn-main-type")[0].disabled = false;
                    document.getElementById("release-edit-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Save Changes";
                    pullReleases();
                });
            } else {
                $("#relEditErr").text(data.error);
                $("#relEditErr").show();
                $("#relEditErr").delay(5000).hide(1);
                document.getElementById("release-edit-form").getElementsByClassName("btn-main-type")[0].disabled = false;
                document.getElementById("release-edit-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Save Changes";
            }
        }
    }).fail(function (jqXHR, textStatus, error) {
        $("#relEditModal").modal('toggle');
        bootbox.alert("Failed request... Please try again", () => {
            window.location.reload();
        });
    });
};

export const callAddRelease = () => {
    $("#relAddModal").modal('toggle');
}
export const addReleaseSubmit = (event, form, resetValues, pullReleases) => {
    event.preventDefault();
    document.getElementById("release-add-form").getElementsByClassName("btn-main-type")[0].disabled = true;
    document.getElementById("release-add-form").getElementsByClassName("btn-main-type")[0].innerHTML = "...";
    $.ajax({
        url: "/api/dashboard/releases",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(form),
        success: function (data) {
            if (data.success) {
                $("#relAddModal").modal('toggle');
                bootbox.alert("Successfully Added Product", () => {
                    document.getElementById("release-add-form").getElementsByClassName("btn-main-type")[0].disabled = false;
                    document.getElementById("release-add-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Add Product";
                    resetValues();
                    pullReleases();
                });
            } else {
                $("#relAddErr").text(data.error);
                $("#relAddErr").show();
                $("#relAddErr").delay(5000).hide(1);
                document.getElementById("release-add-form").getElementsByClassName("btn-main-type")[0].disabled = false;
                document.getElementById("release-add-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Add Product";
            }
        }
    }).fail(function (jqXHR, textStatus, error) {
        $("#relAddModal").modal('toggle');
        bootbox.alert("Failed request... Please try again", () => {
            window.location.reload();
        });
    });
};

export const callDeleteRelease = (pullReleases) => {
    var selectedReleases = document.getElementsByClassName("checkbox-input-release");
    let relArr = [];
    for (var i = 0; i < selectedReleases.length; i++) {
        if (selectedReleases[i].checked == true) {
            let row = selectedReleases[i].parentNode.parentNode.parentNode
            let releaseData = JSON.parse(row.getAttribute("data-identify"));
            var planId = releaseData.planId;
            relArr.push({
                planId: planId,
                id: releaseData._id
            });
        }
    }

    if (relArr.length > 0) {
        bootbox.confirm({
            message: `Are you sure you want to delete product(s)`,
            centerVertical: true,
            buttons: {
                confirm: {
                    label: 'Yes',
                    className: 'btn-main-type'
                },
                cancel: {
                    label: 'No',
                    className: 'btn-second-type'
                }
            },
            callback: function (result) {
                if (result == true) {
                    $.ajax({
                        url: "/api/dashboard/releases",
                        type: "DELETE",
                        contentType: 'application/json',
                        data: JSON.stringify({
                            releases: relArr
                        }),
                        success: function (data) {
                            if (data.success) {
                                bootbox.alert("Successfully Deleted Product(s)", () => {
                                    pullReleases();
                                })
                            } else {
                                bootbox.alert(data.error, () => {
                                    pullReleases();
                                });
                            }
                        }
                    }).fail(function (jqXHR, textStatus, error) {
                        bootbox.alert("Failed request... Please try again", () => {
                            window.location.reload();
                        });
                    });
                }
            }
        })
        return;
    } else {
        bootbox.alert("Please select product(s) you would like to delete");
    }
};