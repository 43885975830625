import bootbox from "bootbox";
import $ from "jquery"

export const getCards = (setCards, setCardId) => {
    $.ajax({
        url: "/api/dashboard/stripe/cards",
        type: "GET",
        success: function(data) {
            if (data.success == true) {
                setCards(data.cards);
            if (data.defaultCard.id) {
                setCardId(data.defaultCard.id);
            } else {
                setCardId("");
            }
        } else {
            // window.location.href = "/logout";
        }
        }
    }).fail(function(jqXHR, textStatus, error) {
        // window.location.href = "/logout";
    });
}

export const resetPassword = (form) => {
    $.ajax({
        url: "/api/organization/forgot",
        data: form,
        type: "POST",
        success: function(data) {
        if (data.success == true) {
            return bootbox.alert("Check your email for a password reset link!");
        } else {
            return bootbox.alert(data.error);
        }
        }
    }).fail(function(jqXHR, textStatus, error) {
        return bootbox.alert("Failed request");
    });
}

export const callUpdateCard = () => {
    $("#cardsModal").modal('toggle');
}

export const callAddCard = () => {
    $("#cardsModal").modal('toggle');
    $("#addCardModal").modal('toggle');
}

export const submitCard = (form, pullCards) => {
    $.ajax({
        url: "/api/dashboard/stripe/card/add",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(form),
        success: function (data) {
            if (data.success) {
                pullCards();
                bootbox.alert("Successfully Added Card")
            } else {
                bootbox.alert(data.error);
            }
        }
    }).fail(function (jqXHR, textStatus, error) {
        bootbox.alert("Failed request... Please try again");
    });
}


export const deleteCard = (form, pullCards) => {
    $.ajax({
        url: "/api/dashboard/stripe/card/delete",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(form),
        success: function (data) {
            if (data.success) {
                pullCards();
                bootbox.alert("Successfully Removed Card");
            } else {
                bootbox.alert(data.error);
            }
        }
    }).fail(function (jqXHR, textStatus, error) {
        bootbox.alert("Failed request... Please try again");
    });
}

export const setDefaultCard = (form, pullCards) => {
    $.ajax({
        url: "/api/dashboard/stripe/card",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(form),
        success: function (data) {
            if (data.success) {
                pullCards();
                bootbox.alert("Successfully Set Card");
            } else {
                bootbox.alert(data.error);
            }
        }
    }).fail(function (jqXHR, textStatus, error) {
        bootbox.alert("Failed request... Please try again");
    });
}