import React, { useEffect, useState, useRef } from "react";
import { deleteCard, setDefaultCard, getCards, submitCard, callAddCard, callUpdateCard, resetPassword } from "../../Actions/Dashboard/subscription";
import { Elements, CardElement } from '@stripe/react-stripe-js';
import StripeCardElementGrabber from "../StripeCardElementGrabber"
import bootbox from "bootbox";
import $ from 'jquery';

const Subscription = ({ data, stripePromise, display }) => {
    const [help, showHelp] = useState(false);
    const [card, setCard] = useState(data.card);
    const [cardId, setCardId] = useState(data.card.id ? data.card.id : "");
    const [cards, setCards] = useState(data.cards)
    const grabberRef = useRef();

    useEffect(() => {
    }, [])


    useEffect(() => {
        var found = false;
        for (var i = 0; i < cards.length; i++) {
            if (cards[i].id == cardId) {
                setCard(cards[i]);
                found = true;
            }
        }
        if (!found) {
            setCard({});
        }
    }, [cardId])


    const pullCards = () => {
        getCards(setCards, setCardId);
    }

    const callResetPassword = () => {
        var form = {
            email: data.email.trim()
        }
        resetPassword(form);
    }


    const callSetCard = () => {
        if (!card.id || cardId === "") {
            bootbox.alert("No Card to Set");
        } else {
            var form = {
                cardId: cardId
            }
            $("#cardsModal").modal('toggle');
            setDefaultCard(form, pullCards);
        }
    }

    const callDeleteCard = () => {
        if (!card.id || cardId === "") {
            bootbox.alert("No Card to Remove");
        } else {
            var form = {
                cardId: cardId
            }
            $("#cardsModal").modal('toggle');
            deleteCard(form, pullCards);
        }
    }

    const callSubmitCard = (event) => {
        event.preventDefault();
        $("#addCardModal").modal('toggle');
        grabberRef.current.getToken().then((result) => {
            var form = {
                token: result.token.id
            }
            submitCard(form, pullCards)
        }).catch((err) => {
            bootbox.alert(err.toString());
        })
    }


    return (
        <div id="subscription" style={{display: display === true ? true : 'none'}}>
            <div className="container-fluid row">
                <div className="col-md-12">
                    <h1 className="mt-4">Profile</h1>
                    <div className="user-corner-panel">

                    <svg style={{"verticalAlign":"middle","marginRight":"10px","cursor":"pointer"}} onClick={() => {showHelp(!help)}} width="30"
                        height="30" viewdiv="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                        d="M14.8261 0C6.63787 0 0 6.63787 0 14.8261C0 23.0143 6.63787 29.6522 14.8261 29.6522C23.0143 29.6522 29.6522 23.0143 29.6522 14.8261C29.6522 6.63787 23.0143 0 14.8261 0ZM14.8261 2.69565C21.5255 2.69565 26.9565 8.12663 26.9565 14.8261C26.9565 21.5255 21.5255 26.9565 14.8261 26.9565C8.12663 26.9565 2.69565 21.5255 2.69565 14.8261C2.69565 8.12663 8.12663 2.69565 14.8261 2.69565Z"
                        fill="#808191" />
                        <path fillRule="evenodd" clipRule="evenodd"
                        d="M14.832 20.2174C15.5764 20.2174 16.1798 20.8208 16.1798 21.5652C16.1798 22.2564 15.6595 22.8261 14.9892 22.904L14.832 22.913C14.0817 22.913 13.4782 22.3096 13.4782 21.5652C13.4782 20.874 13.9986 20.3043 14.6689 20.2264L14.832 20.2174ZM18.4572 7.2585L18.6701 7.46842C20.4876 9.31629 20.4778 12.2194 18.73 14.0234L18.4132 14.332C17.9546 14.769 17.4886 15.1181 16.7977 15.6071C16.1485 16.0936 16.0071 16.3252 16.0071 17.05C16.0071 17.7944 15.4036 18.3978 14.6592 18.3978C13.9149 18.3978 13.3114 17.7944 13.3114 17.05C13.3114 15.3795 13.9064 14.4052 15.1813 13.4499L15.7904 13.0084C16.0136 12.8429 16.1825 12.7104 16.3343 12.5795L16.6725 12.2654C17.4784 11.5231 17.555 10.3359 16.883 9.50925L16.7483 9.35868L16.6144 9.2259C15.5484 8.22739 13.8659 8.20049 12.7665 9.12547L12.5889 9.28844L11.9297 9.94753C11.4033 10.4738 10.5499 10.4737 10.0236 9.94732C9.53775 9.4614 9.50046 8.69686 9.91164 8.16817L10.0238 8.0412L10.683 7.38212C12.8001 5.26548 16.2709 5.21072 18.4572 7.2585Z"
                        fill="#808191" />
                    </svg>
                    <div className="help-dropdown" style={{display: help === true ? 'block' : 'none'}}>
                        <ul>
                        <li><a target="_blank" href="https://ternarydev.notion.site/Ternary-4adf412dd4444fcb90c0a457d2a3607c">Helpful Articles</a>
                        </li>
                        <li><a target="_blank" href="https://docs.ternarydev.com/ternary-developments/change-log">Release Notes</a></li>
                        <li><a href="mailto:support@ternarydev.com">Contact Us</a></li>
                        </ul>
                    </div>


                    <a className="settings-button active-item">
                        <svg style={{"verticalAlign":"middle","cursor":"pointer"}} width="30" height="30" viewdiv="0 0 30 30"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM15 2.72729C21.7781 2.72729 27.2727 8.22198 27.2727 15C27.2727 21.7781 21.7781 27.2727 15 27.2727C8.22198 27.2727 2.72729 21.7781 2.72729 15C2.72729 8.22198 8.22198 2.72729 15 2.72729Z"
                            fill="#808191" />
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M14.9999 5.45459C10.4812 5.45459 6.81812 9.11771 6.81812 13.6364C6.81812 18.1551 10.4812 21.8182 14.9999 21.8182C19.5186 21.8182 23.1818 18.1551 23.1818 13.6364C23.1818 9.11771 19.5186 5.45459 14.9999 5.45459ZM14.9997 8.18188C18.0122 8.18188 20.4543 10.624 20.4543 13.6364C20.4543 16.6489 18.0122 19.091 14.9997 19.091C11.9873 19.091 9.54517 16.6489 9.54517 13.6364C9.54517 10.624 11.9873 8.18188 14.9997 8.18188Z"
                            fill="#808191" />
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M15.0066 19.0911C19.4226 19.0911 22.7977 20.6431 24.9792 23.743C25.4126 24.3589 25.2647 25.2095 24.6488 25.6429C24.0329 26.0764 23.1822 25.9284 22.7488 25.3125C21.1085 22.9817 18.5787 21.8183 15.0066 21.8183C11.4268 21.8183 8.89371 22.9866 7.25357 25.328C6.82148 25.9448 5.97116 26.0946 5.35433 25.6625C4.7375 25.2304 4.58774 24.3801 5.01983 23.7632C7.20065 20.65 10.5808 19.0911 15.0066 19.0911Z"
                            fill="#808191" />
                        </svg>
                    </a>

                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="card">
                    <div className="card-body">
                        <h2 style={{"marginBottom":"30px"}}>My Subscription</h2>
                        <ul className="list-group list-group-flush" style={{"lineHeight":"25px"}}>
                        <li className="list-group-item"><strong>Account Type: </strong><span>{data.tier}</span></li>
                            <li className="list-group-item"><strong>Email: </strong><span id="email">
                                {data.email}</span>
                            </li>
                            <li className="list-group-item"><strong>Recurring Period: </strong><span>{data.subInterval}</span></li>

                            <li className="list-group-item"><strong>Subscription State: </strong><span
                                className={data.statusColor}>
                                {data.status}</span></li>

                            <li className="list-group-item"><strong>Next Invoice: </strong><span>${data.subPrice.toFixed(2)}</span></li>

                            <li className="list-group-item"><strong>Next Renewal Date: </strong><span id="exp-date">{(new Date( data.subExp * 1000).toLocaleDateString("en-US"))}</span>
                            </li>
                            <li className="list-group-item"><strong>Active Card: </strong><span id="card">{card.brand ? (`${card.brand.toUpperCase()} ****${card.last4}`) : 'No card'}</span></li>
                            <li className="list-group-item"><strong>Reset Your Password: </strong><span><a
                                    style={{"color":"#f55b6a"}} href="#" onClick={() => {callResetPassword()}}>Reset</a></span></li>
                        </ul>
                    </div>

                    <div style={{"display":"flex","justifyContent":"center","backgroundColor":"transparent","border":"none"}}
                    className="card-footer row">
                        <div style={{"display":"flex","justifyContent":"center","marginBottom":"15px"}} className="col-sm-12">
                            <button onClick={() => {callUpdateCard()}} className="btn"
                            style={{"height":"44px","width":"220px","textAlign":"center","fontSize":"16px","fontWeight":"700","color":"#fff","borderRadius":"16px","backgroundColor":"#333333"}}>Update
                            Default</button>
                        </div>

                    </div>





                    </div>
                  </div>


            </div>


            <div className="modal fade" id="cardsModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Payment Methods</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd"
                            d="M1.6129 0.209705L1.70711 0.292893L8.07107 6.65607L14.435 0.292893C14.8256 -0.0976311 15.4587 -0.0976311 15.8492 0.292893C16.2097 0.653377 16.2375 1.22061 15.9324 1.6129L15.8492 1.70711L9.48607 8.07107L15.8492 14.435C16.2398 14.8256 16.2398 15.4587 15.8492 15.8492C15.4888 16.2097 14.9215 16.2375 14.5292 15.9324L14.435 15.8492L8.07107 9.48607L1.70711 15.8492C1.31658 16.2398 0.683418 16.2398 0.292893 15.8492C-0.0675907 15.4888 -0.0953203 14.9215 0.209705 14.5292L0.292893 14.435L6.65607 8.07107L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.623337 -0.0375504 1.12751 -0.0883879 1.5114 0.140381L1.6129 0.209705Z"
                            fill="black" />
                        </svg>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form onSubmit={(event) => {event.preventDefault()}} id="">
                        <div className="form-group">
                          <label for="cards" className="col-form-label">Cards:</label>
                          <select id="cards" type="hidden" name="cards" className="form-control select" value={cardId} onChange={(event) => {setCardId(event.target.value)}}>
                              {cards.map(card => {
                                  return (
                                      <option value={card.id}>{card.brand ? (`${card.brand.toUpperCase()} ****${card.last4}`) : 'No card'}</option>
                                  )
                              })}
                          </select>
                        </div>
                        <div className="form-group">
                          <a style={{"marginRight":"15px"}} href="#"
                            onClick={() => {callAddCard()}}
                            className="text-success">Add new Card</a>
                          <a href="#" onClick={() => {callDeleteCard()}}className="text-danger">Remove Card</a>
                        </div>
                      </form>
                    </div>
                    <div className="modal-footer">
                      <button style={{"display":"block","margin":"0 auto","float":"none"}} type="button"
                        className="btn btn-main-type" onClick={() => {callSetCard()}}>Save default</button>
                    </div>
                  </div>
                </div>
              </div>


              <div className="modal fade" id="addCardModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Add Card</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd"
                            d="M1.6129 0.209705L1.70711 0.292893L8.07107 6.65607L14.435 0.292893C14.8256 -0.0976311 15.4587 -0.0976311 15.8492 0.292893C16.2097 0.653377 16.2375 1.22061 15.9324 1.6129L15.8492 1.70711L9.48607 8.07107L15.8492 14.435C16.2398 14.8256 16.2398 15.4587 15.8492 15.8492C15.4888 16.2097 14.9215 16.2375 14.5292 15.9324L14.435 15.8492L8.07107 9.48607L1.70711 15.8492C1.31658 16.2398 0.683418 16.2398 0.292893 15.8492C-0.0675907 15.4888 -0.0953203 14.9215 0.209705 14.5292L0.292893 14.435L6.65607 8.07107L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.623337 -0.0375504 1.12751 -0.0883879 1.5114 0.140381L1.6129 0.209705Z"
                            fill="black" />
                        </svg>
                      </button>
                    </div>
                    <form onSubmit={(event) => {callSubmitCard(event)}} id="add-card-form">
                      <div className="modal-body">
                        <div className="form-group">
                        <Elements stripe={stripePromise}>
                            <CardElement
                                id="cardElement"
                                options={{
                                    style: {
                                    base: {
                                        color: '#32325d',
                                        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                                        fontSmoothing: 'antialiased',
                                        fontSize: '16px',
                                        '::placeholder': {
                                        color: '#aab7c4'
                                        }
                                    },
                                    invalid: {
                                        color: '#fa755a',
                                        iconColor: '#fa755a'
                                    }
                                    },
                                }}
                            />

                            <StripeCardElementGrabber ref={grabberRef} />

                        </Elements>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button style={{"display":"block","margin":"0 auto","float":"none"}} type="submit"
                          className="btn btn-main-type">Add Card</button>
                      </div>
                    </form>

                  </div>
                </div>
              </div>



        </div>
    )
}


export default Subscription
