import bootbox from "bootbox";
import $ from "jquery"

export const login = (email, password) => {
    if (email && password) {
        // submit form
        $.post("/api/organization/login", {email: email, password:password},
            (data, status) => {
                if (status == "success") {
                    if (data.success == true) {
                        return window.location.href = `/dashboard`;
                    } else {
                        return bootbox.alert(data.error);
                    }
                } else {
                    return bootbox.alert("Failed request");
                }
            });
    } else {
        bootbox.alert("Please fill in all fields");
    }
}

export const forgotPassword = (email) => {
    if (email) {
        // submit form
        $.post("/api/organization/forgot", {email: email},
            (data, status) => {
                if (status == "success") {
                    if (data.success == true) {
                        return bootbox.alert("Check your email for a password reset link!");
                    } else {
                        return bootbox.alert(data.error);
                    }
                } else {
                    return bootbox.alert("Failed request");
                }
            });
    } else {
        bootbox.alert("Please provide an email");
    }
}