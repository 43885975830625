import React, { useEffect, useState, useRef } from "react";
import {rollApiKey, getApiKeys, submitDefaultDomain, deleteDomain, submitScripts, submitDomain, submitEmailSettings, submitSettings, getDomains, uploadImg, uploadBg, initiatePicker, showPicker1, showPicker2} from "../../Actions/Dashboard/settings";
import bootbox from "bootbox";
import $ from "jquery"
import FontIconPicker from '@fonticonpicker/react-fonticonpicker';
import PageNavigation from "../PageNavigation/PageNavigation";

const Settings = ({ page, setPage, data, pullData, groupLogo, setGroupLogo, groupName, setGroupName, display }) => {
  const [help, showHelp] = useState(false);
  const [groupNameInput, setGroupNameInput] = useState(groupName);
  const [terms, setTerms] = useState(data.terms);
  const [primaryColor, setPrimaryColor] = useState(data.primary_color);
  const [secondaryColor, setSecondaryColor] = useState(data.secondary_color);
  const [domains, setDomains] = useState([]);
  const [apiKeys, setApiKeys] = useState([]);
  const [defaultDomain, setDefaultDomain] = useState(data.domain);
  const [domainInput, setDomainInput] = useState("");
  const [selectedDomain, setSelectedDomain] = useState("");
  const [scripts, setScripts] = useState(data.scripts.join('\n'));
  const [welcomeEmail, setWelcomeEmail] = useState(data.emails.welcome);
  const [tabs, setTabs] = useState(data.sidebar);
  const [iconValue, setIconValue] = useState("fipicon-angle-left");
  const [show, setShow] = useState(false);
  const [background, setBackground] = useState(data.background);
   
  useEffect(() => {
      pullDomains();
      pullApiKeys();
      initiatePicker(primaryColor, setPrimaryColor, secondaryColor, setSecondaryColor);
  }, [])

  useEffect(() => {
  }, [domains])

  const addTab = () => {
    let id =  Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    var div = document.createElement("div");
    div.setAttribute("id", id)
    div.classList.add("form-group");
    div.classList.add("col-md-12");
    div.innerHTML = `
        <label>Menu Tab</label>
        <input style="margin-top:5px;" type="text" class="form-control tabName" placeholder="Menu Name" required />
        <input style="margin-top:5px;" type="url" class="form-control tabUrl" placeholder="Menu URL" required />
        
        <button onclick="document.getElementById('${id}').remove()" 
        style="margin-top:15px; text-align: center; font-size: 14px; color:#11142D; border-radius: 16px; background-color: #E4E4E4;padding: 12px 33px;"
        type="button" class="btn">Remove</button>
    `  
    document.getElementById("tabs").append(div);

  }

  const pullDomains = () => {
    if ($.fn.DataTable.isDataTable( '#domain-table' )) {
      $("#domain-table").dataTable().fnDestroy();
    }
    getDomains(setDomains)
  }


  const pullApiKeys = () => {
    if ($.fn.DataTable.isDataTable( '#api-key-table' )) {
      $("#api-key-table").dataTable().fnDestroy();
    }
    getApiKeys(setApiKeys);
  }

  const callAddDomain = (event) => {
      event.preventDefault();
      var form = {
          domain: domainInput
      }
      setDomainInput("");
      submitDomain(form, pullDomains);
  }

  const callDeleteDomain = (event) => {
      if (selectedDomain != "") {
          var form = {
              domain: selectedDomain.replace(/ /g, "")
          }
          setSelectedDomain("")
          deleteDomain(form, pullDomains);
      } else {
          bootbox.alert("Please Select a Domain");
      }
  }


  const callDefaultDomain = (event) => {
      if (selectedDomain != "") {
          submitDefaultDomain(selectedDomain.replace(/ /g, ""), pullData, pullDomains);
          setSelectedDomain("")
      } else {
          bootbox.alert("Please Select a Domain");
      }
  }

  const callSubmitSettings = (event) => {
      event.preventDefault();

      // Pull tabs
      var sidebar = [];

      var menuNames = $(".tabName");
      var menuUrls = $(".tabUrl");

      for (var i = 0; i < menuNames.length; i++) {
        if (menuNames[i].value) {
          sidebar.push({name: menuNames[i].value, url: menuUrls[i].value})
        }
      }
      
      var form = {
          group_name: groupNameInput,
          primary_color: primaryColor,
          secondary_color: secondaryColor,
          terms: terms,
          sidebar: sidebar
      }
      submitSettings(form, setGroupName);

    }



  function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }


  const callSubmitScripts = () => {
      let scriptsSplit = scripts.split('\n');
      let scriptsArr = [];
      for (var i = 0; i < scriptsSplit.length; i++) {
        if (validURL(scriptsSplit[i])) {
          scriptsArr.push(scriptsSplit[i]);
        }
      }
      var form = {
          scripts: scriptsArr
      }
      submitScripts(form);
    }



  const callSubmitEmailSettings = (event) => {
    event.preventDefault();
    var form = {
        welcome: welcomeEmail
    }
    submitEmailSettings(form);
  }



    return (
        <div id="settings" style={{display: display === true ? true : 'none'}}>
            <div className="container-fluid row">
                <div className="col-md-12">
                    <h1 className="mt-4">Settings</h1>
                    <PageNavigation pages={[{title: "Branding", active: page === "branding", onclick: () => {setPage('branding')} }, {title: "Domain", active: page === "domain", onclick: () => {setPage('domain')} }, {title: "Email Settings", active: page === "email", onclick: () => {setPage('email')} }, {title: "Developer", active: page === "developer", onclick: () => {setPage('developer')} }, {title: "Team", active: false, onclick: () => {setPage('team')} }]} />

                    <div className="user-corner-panel">
                        <svg style={{"verticalAlign":"middle","marginRight":"10px","cursor":"pointer"}} onClick={() => {showHelp(!help)}} width="30"
                            height="30" viewdiv="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                            d="M14.8261 0C6.63787 0 0 6.63787 0 14.8261C0 23.0143 6.63787 29.6522 14.8261 29.6522C23.0143 29.6522 29.6522 23.0143 29.6522 14.8261C29.6522 6.63787 23.0143 0 14.8261 0ZM14.8261 2.69565C21.5255 2.69565 26.9565 8.12663 26.9565 14.8261C26.9565 21.5255 21.5255 26.9565 14.8261 26.9565C8.12663 26.9565 2.69565 21.5255 2.69565 14.8261C2.69565 8.12663 8.12663 2.69565 14.8261 2.69565Z"
                            fill="#808191" />
                            <path fillRule="evenodd" clipRule="evenodd"
                            d="M14.832 20.2174C15.5764 20.2174 16.1798 20.8208 16.1798 21.5652C16.1798 22.2564 15.6595 22.8261 14.9892 22.904L14.832 22.913C14.0817 22.913 13.4782 22.3096 13.4782 21.5652C13.4782 20.874 13.9986 20.3043 14.6689 20.2264L14.832 20.2174ZM18.4572 7.2585L18.6701 7.46842C20.4876 9.31629 20.4778 12.2194 18.73 14.0234L18.4132 14.332C17.9546 14.769 17.4886 15.1181 16.7977 15.6071C16.1485 16.0936 16.0071 16.3252 16.0071 17.05C16.0071 17.7944 15.4036 18.3978 14.6592 18.3978C13.9149 18.3978 13.3114 17.7944 13.3114 17.05C13.3114 15.3795 13.9064 14.4052 15.1813 13.4499L15.7904 13.0084C16.0136 12.8429 16.1825 12.7104 16.3343 12.5795L16.6725 12.2654C17.4784 11.5231 17.555 10.3359 16.883 9.50925L16.7483 9.35868L16.6144 9.2259C15.5484 8.22739 13.8659 8.20049 12.7665 9.12547L12.5889 9.28844L11.9297 9.94753C11.4033 10.4738 10.5499 10.4737 10.0236 9.94732C9.53775 9.4614 9.50046 8.69686 9.91164 8.16817L10.0238 8.0412L10.683 7.38212C12.8001 5.26548 16.2709 5.21072 18.4572 7.2585Z"
                            fill="#808191" />
                        </svg>
                        <div className="help-dropdown" style={{display: help === true ? 'block' : 'none'}}>
                            <ul>
                            <li><a target="_blank" href="https://ternarydev.notion.site/Ternary-4adf412dd4444fcb90c0a457d2a3607c">Helpful Articles</a>
                            </li>
                            <li><a target="_blank" href="https://docs.ternarydev.com/ternary-developments/change-log">Release Notes</a></li>
                            <li><a href="mailto:support@ternarydev.com">Contact Us</a></li>
                            </ul>
                        </div>
                        <a className="settings-button" onClick={() => {setPage('subscription')}}>
                            <svg style={{"verticalAlign":"middle","cursor":"pointer"}} width="30" height="30" viewdiv="0 0 30 30"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM15 2.72729C21.7781 2.72729 27.2727 8.22198 27.2727 15C27.2727 21.7781 21.7781 27.2727 15 27.2727C8.22198 27.2727 2.72729 21.7781 2.72729 15C2.72729 8.22198 8.22198 2.72729 15 2.72729Z"
                                fill="#808191" />
                            <path fillRule="evenodd" clipRule="evenodd"
                                d="M14.9999 5.45459C10.4812 5.45459 6.81812 9.11771 6.81812 13.6364C6.81812 18.1551 10.4812 21.8182 14.9999 21.8182C19.5186 21.8182 23.1818 18.1551 23.1818 13.6364C23.1818 9.11771 19.5186 5.45459 14.9999 5.45459ZM14.9997 8.18188C18.0122 8.18188 20.4543 10.624 20.4543 13.6364C20.4543 16.6489 18.0122 19.091 14.9997 19.091C11.9873 19.091 9.54517 16.6489 9.54517 13.6364C9.54517 10.624 11.9873 8.18188 14.9997 8.18188Z"
                                fill="#808191" />
                            <path fillRule="evenodd" clipRule="evenodd"
                                d="M15.0066 19.0911C19.4226 19.0911 22.7977 20.6431 24.9792 23.743C25.4126 24.3589 25.2647 25.2095 24.6488 25.6429C24.0329 26.0764 23.1822 25.9284 22.7488 25.3125C21.1085 22.9817 18.5787 21.8183 15.0066 21.8183C11.4268 21.8183 8.89371 22.9866 7.25357 25.328C6.82148 25.9448 5.97116 26.0946 5.35433 25.6625C4.7375 25.2304 4.58774 24.3801 5.01983 23.7632C7.20065 20.65 10.5808 19.0911 15.0066 19.0911Z"
                                fill="#808191" />
                            </svg>
                        </a>
                    </div>
                </div>

                <div style={{maxWidth:"100%"}} className="row">
                    <div className="col-lg-12">
                        <div style={{paddingBottom:80}} className="col-md-12">
                        
                        <form style={{display: page == "branding" ? "block" : "none"}} onSubmit={(event) => {callSubmitSettings(event)}} id="branding-form">
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <h2 className="mt-4" style={{"fontSize":"23px","marginBottom":"35px"}}>General Branding</h2>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <img id="company-profile-preview" width="110px" height="110px"
                                      style={{"border":"1px solid #E4E4E4 !important"}}
                                      className="rounded-circle" src={groupLogo} alt="" />
                                    <input type="file" id="fileElem" accept="image/*"
                                      onChange={(event) => {uploadImg(event.target.files, setGroupLogo)}} />
                                    <label style={{"marginLeft":"45px","color":"#FFF","backgroundColor":"#333333"}}
                                      className="btn btn-secondary" id="selectFiles" for="fileElem">Upload Logo</label>
                                  </div>
                                </div>
                              </div>
                              <input required value="group_logo" type="hidden" name="group_logo" id="hidden_logo" />
                            </div>
                            <div className="form-group col-md-12">
                              <label for="group_name">Company Name</label>
                              <input required type="text" className="form-control" name="group_name" id="group_name"
                                placeholder="Company Name" value={groupNameInput} onChange={(event) => {setGroupNameInput(event.target.value)}} />
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label for="primary_color">Primary Color</label>
                              <div style={{"backgroundColor":primaryColor}} className="color_block"
                                id="primary_color_block" onClick={() => {showPicker1()}}></div>
                              <div id="primary_color_popup"></div>
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label for="secondary_color">Secondary Color</label>
                              <div style={{"backgroundColor":secondaryColor}} className="color_block"
                                id="secondary_color_block" onClick={() => {showPicker2()}} ></div>
                              <div id="secondary_color_popup"></div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label for="terms">URL to Terms of Service Webpage</label>
                              <input required type="url" className="form-control" id="terms" name="terms"
                                placeholder="Terms of service page URL" value={terms} onChange={(event) => {setTerms(event.target.value)}} describedby="termsBlock" />
                              <small id="termsBlock" className="form-text text-muted">
                                Terms of service are the legal agreements between a service provider and a person who
                                wants to use that service. If you do not already have one for your business it is
                                recommended you create one and link it with your Ternary
                                platform above. <a target="_blank" href="https://www.optionsswing.com/disclaimer">For
                                  Example</a>
                              </small>
                            </div>
                          </div>

                          <div className="form-row">
                            <label for="">Background Image</label>
                            <div className="form-group col-md-12">

                                {
                                  background && (
                                    <img style={{maxHeight: 300, width:"100%", borderRadius: 16, "object-fit": 'cover', "border":"1px solid #E4E4E4 !important"}} src={background} alt="" />
                                  )
                                }

                                <input type="file" id="background" accept="image/*"
                                  onChange={(event) => {uploadBg(event.target.files, setBackground)}} />

                                <label style={{marginTop:15, "color":"#FFF","backgroundColor":"#333333"}}
                                      className="btn btn-secondary" id="selectBackground" for="background">Upload Background</label>


                                {/* Remove bg */}
                                {
                                  background && (
                                    <button onClick={() => {setBackground(null); uploadBg('') }} style={{"marginTop":"15px","marginLeft": 15, "textAlign":"center","fontSize":"14px","color":"#11142D","borderRadius":"16px","backgroundColor":"#E4E4E4","padding":"12px 33px"}}
                                    type="button" className="btn">Remove</button>
                                  )
                                }

                              <small className="form-text text-muted">The background image will be displayed on the login and purchase pages.
                              </small>
                            </div>
                          </div>

                          

          

                        
                        <div id="tabs" className="form-row">
                          {tabs ? (
                             tabs.map(tab => {
                              return (
                                <div id={`${tab.url}-tab`} className="form-group col-md-12">
                                    <label>Menu Tab</label>
                                    <input readOnly value={tab.name} type="text" className="form-control tabName" placeholder="Menu Name" required />
                                    <input readonly value={tab.url} style={{marginTop:5}} type="url" className="form-control tabUrl" placeholder="Menu URL" required />
                                    <button onClick={() => {document.getElementById(`${tab.url}-tab`).remove()}} style={{"marginTop":"15px","textAlign":"center","fontSize":"14px","color":"#11142D","borderRadius":"16px","backgroundColor":"#E4E4E4","padding":"12px 33px"}}
                                    type="button" className="btn">Remove</button>
                                </div>
                              )
                            })
                          ) : null}
                        </div>
                         


                          <div className="form-row">
                            <div style={{marginTop:-30}} className="form-group col-md-12">
                              <button type="submit" className="btn btn-primary" style={{"textAlign":"center","width":"auto","minWidth":"120px","fontSize":"14px","fontWeight":"700","color":"#fff","borderRadius":"16px","marginTop":"40px","marginRight":"20px","backgroundColor":"#F55B6A","padding":"12px 33px"}}>Save</button>
                        
                              <button onClick={() => {addTab()}} type="button" className="btn"
                                    style={{"textAlign":"center","width":"auto","minWidth":"120px","fontSize":"14px","fontWeight":"700","color":"#fff","marginTop":"40px","borderRadius":"16px","marginRight":"20px","backgroundColor":"#333333","padding":"12px 33px"}}>Add side-bar menu tab</button>
                            </div>
                          </div>

                        </form>

                        <form style={{display: page == "domain" ? "block" : "none"}} onSubmit={(event) => {callAddDomain(event)}} id="domain-form" className="domain-form">
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <h2 className="mt-4" style={{"fontSize":"23px","marginBottom":"35px"}}>Domain</h2>
                              <div className="row">
                                <div className="form-group col-md-12">
                                  <label>User Dashboard Link: <u><a target="_blank" href={`${defaultDomain}/dashboard`}
                                        style={{"whiteSpace":"nowrap","textOverflow":"ellipsis","color":"#F55B6A"}}>{defaultDomain}/dashboard</a></u>
                                  </label>
                                </div>
                                <div className="form-group col-md-12">
                                  <label for="domain">Your Custom Domain</label>
                                  <input required type="text" className="form-control" id="domain"
                                    aria-describedby="domainHelp" name="domain" placeholder="portal.ternarydev.com" value={domainInput} onChange={(event) => {setDomainInput(event.target.value)}} />
                                  <small id="domainHelp" className="form-text text-muted" style={{"paddingTop":"10px"}}>e.g
                                    mygroup.com or subdomain.mygroup.com, <a target="_blank"
                                      href="https://ca.godaddy.com/help/add-a-cname-record-19236">An example on how to
                                      connect a CNAME record</a></small>
                                </div>
                                <div style={{"marginTop":"-30px"}} className="form-group col-md-12">
                                  <button type="submit" className="btn" id="connDomain"
                                    style={{"textAlign":"center","width":"auto","minWidth":"120px","fontSize":"14px","fontWeight":"700","color":"#fff","borderRadius":"16px","marginTop":"40px","marginRight":"20px","backgroundColor":"#333333","padding":"12px 33px"}}>Add Domain</button>
                                  <button onClick={() => {callDeleteDomain()}} id="delDomain" type="button" className="btn"
                                    style={{"textAlign":"center","width":"auto","minWidth":"120px","fontSize":"14px","fontWeight":"700","color":"#11142D","marginTop":"40px","borderRadius":"16px","marginRight":"20px","backgroundColor":"#E4E4E4","padding":"12px 33px"}}>Delete Domain</button>
                                  <button onClick={() => {callDefaultDomain()}} id="defaultDomain" type="button" className="btn"
                                    style={{"textAlign":"center","width":"auto","minWidth":"120px","fontSize":"14px","fontWeight":"700","color":"#11142D","marginTop":"40px","borderRadius":"16px","backgroundColor":"#E4E4E4","padding":"12px 33px"}}>Set as Default Domain</button>
                                </div>


                              </div>
                            </div>

                            <div className="form-group col-md-12 table-responsive">
                              <table className="table" id="domain-table">
                                <thead className="thead">
                                  <tr>
                                    <th data-field="domain"></th>
                                    <th data-field="domain">Domain</th>
                                    <th data-field="dns">CNAME Record</th>
                                    <th data-field="dns">Default</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {domains.map(domain => {
                                    return (
                                        <tr domain={domain.domain}>
                                            <td>
                                                <label className="checkbox-container">
                                                <input domain={domain.domain} className="checkbox-input-domain" type="checkbox" 
                                                    onChange={(event) => {
                                                        event.target.checked === true ? 
                                                            setSelectedDomain(event.target.getAttribute('domain')) 
                                                        : 
                                                            setSelectedDomain("")
                                                        }
                                                    } 
                                                />

                                                
                                                <span className="checkmark"></span>
                                                </label>
                                            </td>
                                            <td>
                                                {domain.domain}
                                            </td>
                                            <td>
                                                {domain.dns}
                                            </td>
                                            <td>
                                                {domain.domain === defaultDomain.split('//')[1] ? (
                                                    <span style={{"color":"#F55B6A"}}>True</span>
                                                ) : (
                                                    <span style={{"color":"#F55B6A"}}>False</span>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                              </tbody>
                              </table>
                            </div>
                          </div>


                        </form>

                        <form style={{display: page == "email" ? "block" : "none"}} onSubmit={(event) => {callSubmitEmailSettings(event)}} id="email-form">
                          <div className="form-row">
                              <div className="form-group col-md-12">
                                <h2 className="mt-4" style={{"fontSize":"23px","marginBottom":"35px"}}>Email Settings</h2>
                              </div>
                          </div>

                          <div style={{marginTop:-15}} className="form-row">
                            <div className="form-group col-md-12">
                                <label for="scripts">Custom Welcome Message Block</label>
                                <textarea type="text" style={{"color":"#000"}} className="form-control"
                                  value={welcomeEmail}
                                  onChange={(event) => {setWelcomeEmail(event.target.value)}}
                                  placeholder="Click the following link to book a meeting with one of your mods to show you around: https://calendly.com/"></textarea>
                            </div>
                            <div className="form-group col-md-12">
                              <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                          </div>


                        </form>



                        <form style={{display: page == "developer" ? "block" : "none"}} id="developer-form" onSubmit={(event) => {}}>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <h2 className="mt-4" style={{"fontSize":"23px","marginBottom":"35px"}}>API Keys</h2>
                            </div>
                            
                            <div style={{marginTop:-45}} className="form-group col-md-12 table-responsive">
                                <table className="table" id="api-key-table">
                                  <thead className="thead">
                                    <tr>
                                      <th>Key</th>
                                      <th>Date Created</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {apiKeys.map(apiKey => {
                                      return (
                                          <tr>
                                              <td>
                                                  <span style={{cursor: "pointer"}} onClick={() => {setShow(!show)}} className={!show ? "blur" : ""}>{apiKey.key}</span>
                                              </td>

                                              <td>
                                                  {new Date(apiKey.created).toLocaleDateString()}
                                              </td>
                                              
                                              <td>
                                                <svg onClick={() => {
                                                  rollApiKey(apiKey.key, setApiKeys);
                                                }} style={{cursor: "pointer", width:15, height:15}} ariaHidden="true" focusable="false" dataPrefix="fas" dataIcon="ellipsis-h" className="svg-inline--fa fa-ellipsis-h fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"></path></svg>
                                              </td>
                                        
                                          </tr>
                                      )
                                  })}                              
                                  </tbody>
                                </table>
                                
                              </div>
                              <small className="form-text text-muted">
                                You can find documentation for the API <a target="blank" href="https://docs.ternarydev.com/ternary-developments">here.</a>
                              </small>
                            </div>
                            <div style={{"marginTop":"30px"}} className="form-row">
                            <div className="form-group col-md-12">
                            <h2 className="mt-4" style={{"fontSize":"23px","marginBottom":"35px"}}>External Scripts</h2>
                              {/* <label for="scripts">Your Custom Scripts</label> */}
                              <textarea type="text" style={{"color":"#000"}} className="form-control" id="scripts" name="scripts"
                                value={scripts}
                                onChange={(event) => {setScripts(event.target.value)}}
                                placeholder="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-alpha.2/js/bootstrap.min.js"></textarea>
                            </div>

                            <div className="form-group col-md-12">
                              <button type="button" onClick={() => {callSubmitScripts()}} className="btn btn-primary">Save Scripts</button>
                            </div>
                        </div>
                        </form>



                      </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings
