// USER LOGIN PAGE
import React, { useEffect, useState } from "react";
import {
  useParams
} from "react-router-dom";
import "bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../Components/Login/login.css';
import {login, forgotPassword} from '../Actions/login';
import { Helmet } from 'react-helmet'

const Login = () => {  
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  return (
    <div id="login-page">
      <form onSubmit={(event) => {event.preventDefault(); login(email, password)}} className={"login-form"} id={"login-form"}>
        <img src="img/logo.png" style={{minWidth: 200, maxWidth:300, display: "block", margin: "auto"}} />
        <h1>Sign In</h1>
        <div className="row">
          <div className="col-md-12">
            <input style={{marginBottom:20}} className="input-field" name="email" type="email" name="" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
            <input className="input-field" name="password" type="password" name="" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}/>
            <a onClick={() => {forgotPassword(email)}} style={{textDecoration: "none", color: "#F55B6A", fontSize:  12}} href="#">Forgot password?</a>
            <input style={{width:165}} className="input-field" type="submit" value="Sign In" />
          </div>
        </div>
      </form>
    </div>
    )
}

export default Login;
