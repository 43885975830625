import bootbox from "bootbox";
import $ from "jquery"
var affiliateTable;

export const setupAffiliate = (referralPayouts) => {
    var affiliateTable = $('#affiliateTable').DataTable({
        ordering: false,
        lengthChange: false,
        initComplete: function(settings, json) {
          $('body').find('.dataTables_scrollBody').addClass("scrollbar");
          $("#affiliateTable").wrap("<div class='userTableScroll' style='overflow:auto; width:100%;position:relative;'></div>");
        }
    });  


    var totalReferralsEarned = 0;
    var labels = [];
    var values = [];
    for (var i = 0; i < referralPayouts.length; i++) {
        totalReferralsEarned += referralPayouts[i].amount;
        labels.push(referralPayouts[i].month);
        values.push(parseFloat(referralPayouts[i].amount / 100).toFixed(2));
    }
    totalReferralsEarned = parseFloat(totalReferralsEarned / 100).toFixed(2);
    var parent = $("#totalAffiliateRevenue").parent();
    $(parent).find(".shine").css("display", "none");
    $("#totalAffiliateRevenue").text(`$${totalReferralsEarned}`);
    $("#totalAffiliateRevenue").css("display", "inline");
    let dataset = [{
        label: "Affiliate Revenue",
        data: values,
        backgroundColor: 'rgba(255, 99, 132, 0.1)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 2
    }];
    var ctx = document.getElementById("affiliateRevenue").getContext('2d');
    var myChart = new window.Chart(ctx, {
        type: 'line',
        data: {
        labels: labels,
        datasets: dataset
        },
        options: {
        legend: {
            display: false
        },
        scales: {
            xAxes: [{
            gridLines: {
                display: false
            }
            }],
            yAxes: [{
                gridLines: {
                display: false,
                drawBorder: false,
                },
                ticks: {
                display: true,
                beginAtZero: true,
                callback: function(value) {
                    // if (value % 1 === 0) {return value;}
                    return '';
                }
                }
            }

            ]
        },
        maintainAspectRatio: false
        }
    });
    
      
    
}
