import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import bootbox from "bootbox";
import StripeCardElementGrabber from "../../StripeCardElementGrabber";
import {loadStripe} from '@stripe/stripe-js';
import { Elements, CardElement } from '@stripe/react-stripe-js';

const ExpiredModal = ({  }) => {
    const [idempotent, setIdempotent] = useState("")
    const [stripePromise, setStripePromise] = useState(false);
    let grabberRef = useRef();

    useEffect(() => {
        setIdempotent(uuidv4());

        // Show modal on load
        $('#expiredModal').modal({
            backdrop: 'static',
            keyboard: false
        });
        // Load stripe
        if (!stripePromise) {
            let loadedStripe = loadStripe('pk_test_51HALOUB7YuVHBjztlz2Xc7CK0yxfp58YshyQJiWkXlAVAoPl2Qelchz5L2k5SfbTml8hqFpKGsQXqZLihi0eNe1N00BTDQV1PZ');
            setStripePromise(loadedStripe);
        }
    }, []);


    // Randomly generate UUIDV4 for request
    const uuidv4 = () =>  {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
        });
    }
  


    const submitForm = async () => {
        // Create card element
        grabberRef.current.getToken().then((result) => {
            if (result.error) {
                return bootbox.alert(result.error);
            } else {
                fetch("/api/dashboard/stripe/renew", {
                    method: "POST",
                    headers: {
                    'Content-Type': 'application/json',
                    'Idempotency-Key': idempotent
                    },
                    body: JSON.stringify({token: result.token.id})
                })
                .then((val) => val.json())
                .then((val) => {
                    if (val.success) {
                        console.log("Done!");
                    } else {
                        // return bootbox.alert();
                    }
                })
                .catch((err) => {
                    // return bootbox.alert();
                })

            }
          }).catch((err) => {
            // return bootbox.alert(err.message);
          })

      
    }


    return (
        <div className="modal fade" id="expiredModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title">Renew Subscription</h5>
                </div>
                <form onSubmit={(event) => {event.preventDefault(); submitForm();}} id="renew-form">
                    {
                        stripePromise !== false ? (
                            <Elements stripe={stripePromise}>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label for="card" className="col-form-label">Card:</label>
                                    <CardElement
                                        options={{
                                            style: {
                                            base: {
                                                fontSize: '16px',
                                                color: '#424770',
                                                '::placeholder': {
                                                color: '#aab7c4',
                                                },
                                            },
                                            invalid: {
                                                color: '#9e2146',
                                            },
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button style={{"display": "block", "margin": "0 auto", "float": "none"}} type="submit"
                                className="btn btn-main-type">Subscribe ($50 / month)</button>
                            </div>
                            <StripeCardElementGrabber ref={grabberRef} />
                        </Elements>
                        ) : null
                    }
                </form>


            </div>
            </div>
        </div>
    )
}


export default ExpiredModal
