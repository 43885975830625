import React, { useEffect, useState } from "react";
import {addReleaseSubmit} from "../../../Actions/Dashboard/releases";

const CreateReleaseModal = ({ data, discord, products, releases, pullReleases }) => {
    const [productType, setProductType] = useState("discord");
    const [membershipType, setMembershipType] = useState("recurring");
    const [productId, setProductId] = useState("new");
    const [productName, setProductName] = useState("");
    const [useTrialDays, setUseTrialDays]  = useState(false);
    const [trialDays, setTrialDays] = useState(0);
    const [currency, setCurrency] = useState("USD");
    const [removal, setRemoval] = useState("remove");
    const [useAddOns, setUseAddOns] = useState(false);
    const [addOns, setAddOns] = useState([]);
    const [useUpgrades, setUseUpgrades] = useState(false);
    const [upgrades, setUpgrades] = useState([]);
    const [discordRoles, setDiscordRoles] = useState([]);
    const [trialRoles, setTrialRoles] = useState([]);
    const [roleRemoval, setRoleRemoval] = useState([]);
    const [stock, setStock] = useState(1);
    const [password, setPassword] = useState("");
    const [price, setPrice] = useState(0.0);
    const [useInitialPayment, setUseInitialPayment]  = useState(false);
    const [initialPayment, setInitialPayment] = useState(0.0);
    const [interval, setInterval] = useState("month")
    const [intervalCount, setIntervalCount] = useState(1)
    const [features, setFeatures] = useState();

    useEffect(() => {
      if (membershipType == "one time") {
          setTrialDays(0);
      }
    }, [membershipType]);

    useEffect(() => {
        if (productId == "new") {
            setProductName("");
        }
    }, [productId]);

    useEffect(() => {
      if (useInitialPayment === false) {
          setInitialPayment(0.0);
      }
    }, [useInitialPayment]);

    useEffect(() => {
      if (useTrialDays === false) {
          setTrialDays(0);
      }
    }, [useTrialDays]);


    useEffect(() => {
      if (useAddOns === false) {
          setAddOns([]);
      }
    }, [useAddOns]);


    useEffect(() => {
      if (useUpgrades === false) {
          setUpgrades([]);
      }
    }, [useUpgrades]);

  const resetValues = () => {
      setProductType("discord");
      setMembershipType("recurring");
      setProductId("new");
      setProductName("");
      setTrialDays(0);
      setCurrency("USD");
      setRemoval([]);
      setAddOns([]);
      setUpgrades([]);
      setDiscordRoles([]);
      setTrialRoles([]);
      setRoleRemoval([]);
      setStock(1);
      setPassword("");
      setPrice(0.0);
      setInitialPayment(0.0);
      setInterval("month");
      setIntervalCount(1);
      setFeatures("");
    }
    
    const submit = (event) => {
        event.preventDefault();
        var featuresArr = [];
        if (features) {
            featuresArr = features.split(",");
        }
        var form = {
            productType: productType,
            addplanprice: price,
            addstock: stock,
            addpass: password,
            addproductid: productId,
            addproductmembership: membershipType,
            addplaninterval: interval,
            addplanintervalcount: intervalCount,
            initialPayment: initialPayment,
            currency: currency,
            reladdname: productName,
            adddiscordroles: discordRoles,
            addupgradeplans: upgrades,
            addaddonplans: addOns,
            addkickuser: removal,
            addplantrialdays: trialDays,
            addtrialroles: trialDays > 0 ? trialRoles : [],
            adddiscordrolesremoval: roleRemoval,
            features: featuresArr
        }
        // console.log(form);
        addReleaseSubmit(event, form, resetValues, pullReleases);
        return;
    }

    return (
        <div className="modal fade" id="relAddModal" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create a Product</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.6129 0.209705L1.70711 0.292893L8.07107 6.65607L14.435 0.292893C14.8256 -0.0976311 15.4587 -0.0976311 15.8492 0.292893C16.2097 0.653377 16.2375 1.22061 15.9324 1.6129L15.8492 1.70711L9.48607 8.07107L15.8492 14.435C16.2398 14.8256 16.2398 15.4587 15.8492 15.8492C15.4888 16.2097 14.9215 16.2375 14.5292 15.9324L14.435 15.8492L8.07107 9.48607L1.70711 15.8492C1.31658 16.2398 0.683418 16.2398 0.292893 15.8492C-0.0675907 15.4888 -0.0953203 14.9215 0.209705 14.5292L0.292893 14.435L6.65607 8.07107L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.623337 -0.0375504 1.12751 -0.0883879 1.5114 0.140381L1.6129 0.209705Z" fill="black"/>
                  </svg>
                </button>
              </div>
              <form onSubmit={(event) => {submit(event);}} id="release-add-form">
                <div className="modal-body">

                {/* <div className="form-group">
                    <label className="col-form-label">Product Type:</label>
                    <select onChange={(event) => { setProductType(event.target.value);}} value={productType} className="form-control select" name="addproducttype" required>
                      <option value="discord">Discord</option>
                      <option value="manual">Manual Fullfilment</option>
                    </select>
                </div> */}

                  {
                    data.stripe.connected && (
                      <>
                          <div className="form-group">
                            <label for="relRec" className="col-form-label">Membership Type:</label>
                            <select onChange={(event) => { setMembershipType(event.target.value);}} value={membershipType} className="form-control select" name="addproductmembership" required id="relRec">
                              <option value="recurring">Recurring Membership</option>
                              <option value="lifetime">One Time Payment</option>
                            </select>
                            <label for="relProduct" className="col-form-label">Product:</label>
                            <select onChange={(event) => { setProductId(event.target.value) }} className="form-control select" id="relProduct" name="addproductid" required value={productId}>
                                {
                                    products.map(product => {
                                        return (
                                            <option value={product.id}>{product.name}</option>
                                        )
                                    })
                                }
                                <option value="new">(Create) New Product</option>
                            </select>
                        </div>
                        {
                            productId === "new" ? (
                              <div id="priceNameGroup" className="form-group">
                                  <label for="relAddName" className="col-form-label">Price Name:</label>
                                  <input value={productName} onChange={(event) => {setProductName(event.target.value)}} required placeholder="Monthly Subscription" type="text" name="reladdname" className="form-control" id="relAddName" />
                              </div>
                            ) : null
                        }
                      </>
                    )
                  }

                  {
                    data.authorizeNet && (
                      <div id="priceNameGroup" className="form-group">
                        <label for="relAddName" className="col-form-label">Price Name:</label>
                        <input value={productName} onChange={(event) => {setProductName(event.target.value)}} required placeholder="Monthly Subscription" type="text" name="reladdname" className="form-control" id="relAddName" />
                      </div>
                    )
                  }

                  {
                      membershipType === 'recurring' ? (
                        <div className="form-group">
                            <div className="billingSelectWrapper">
                            <label for="addPlanPeriod" className="col-form-label">Billing Period:</label>
                            <div className="input-group mb-3">
                                <div className="input-group-addon">
                                <span className="input-group-text">every</span>
                                </div>
                                <input value={intervalCount} onChange={(event) => {setIntervalCount(event.target.value)}} id="addPlanPeriod" min="1" value={intervalCount} type="number" className="form-control" name="addplanintervalcount" required />
                                <div className="input-group-append">
                                <select value={interval} onChange={(event) => {setInterval(event.target.value)}} className="form-control select" id="addPlanInterval" name="addplaninterval" required>
                                    <option value="month">Months</option>
                                    <option value="year">Years</option>
                                    <option value="week">Weeks</option>
                                    <option value="day">Days</option>
                                </select>
                                </div>
                            </div>
                            </div>
                        </div>
                      ) : null
                  }
                
                  <div className="form-group">
                    <label for="addPlanPrice" className="col-form-label">Price:</label>
                    <div className="input-group mb-3">
                      <div className="input-group-addon">
                        <span className="input-group-text">$</span>
                      </div>
                      <input value={price} onChange={(event) => {setPrice(parseFloat(event.target.value))}} min="0" placeholder="25" step=".01" type="number" name="addplanprice" className="form-control" id="addPlanPrice" required />
                    </div>
                  </div>

                  
                  
                  {membershipType === "recurring" && data.stripe.connected ? (
                      <div className="form-group">
                        <label className="checkbox-container modal-checkbox-container">
                          <input type="checkbox" value={useInitialPayment} onChange={(event) => {setUseInitialPayment(event.target.checked)}} checked={useInitialPayment}/>
                          <span className="checkmark"></span>
                          <label className="col-form-label">Initial Payment:</label>
                       </label>


                        {useInitialPayment === true ? (
                          <div className="input-group mb-3">
                            <div className="input-group-addon">
                              <span className="input-group-text">$</span>
                            </div>
                            <input value={initialPayment} onChange={(event) => {setInitialPayment(parseFloat(event.target.value))}} min="0" placeholder="0" step=".01" type="number" name="addplaninitialprice" className="form-control" required />
                            <small className="form-text text-muted">This price will be charged in addition to the subscription price on sign ups.</small>
                          </div>
                        ) : null}
                        
                      </div>
                  ) : null}

                  {
                      membershipType === "recurring" && data.stripe.connected ? (
                      <div id="addPlanTrialGroup" className="form-group">

                        <label className="checkbox-container modal-checkbox-container">
                          <input type="checkbox" value={useTrialDays} onChange={(event) => {setUseTrialDays(event.target.checked)}} checked={useTrialDays}/>
                          <span className="checkmark"></span>
                          <label className="col-form-label">Trial Days:</label>
                       </label>

                        {
                          useTrialDays === true ? (
                              <div className="input-group mb-3">
                              <input value={trialDays} onChange={(event) => {setTrialDays(parseInt(event.target.value))}} min="0" max="730" placeholder="30" step="1" type="number" name="addplantrialdays" className="form-control" id="addPlanTrial" required />
                              <div className="input-group-append">
                                  <span className="input-group-text">Days</span>
                              </div>
                              </div>
                          ) : null
                        }


                      </div>
                      )
                      : null
                  }
                  
                  <div className="form-group">
                    <label for="addCurrency" className="col-form-label">Currency:</label>
                    <select value={currency} onChange={(event) => {setCurrency(event.target.value)}} id="addCurrency" name="currency"  className="form-control select" required>
                        <option value="USD" label="US dollar">USD</option>
                        <option value="CAD" label="Canadian dollar">CAD</option>
                        <option value="EUR" label="Euro">EUR</option>
                        <option value="JPY" label="Japanese yen">JPY</option>
                        <option value="GBP" label="Pound sterling">GBP</option>
                        <option value="AUD" label="Australian dollar">AUD</option>
                        <option disabled>──────────</option>
                        <option value="AED" label="United Arab Emirates dirham">AED</option>
                        <option value="AFN" label="Afghan afghani">AFN</option>
                        <option value="ALL" label="Albanian lek">ALL</option>
                        <option value="AMD" label="Armenian dram">AMD</option>
                        <option value="ANG" label="Netherlands Antillean guilder">ANG</option>
                        <option value="AOA" label="Angolan kwanza">AOA</option>
                        <option value="ARS" label="Argentine peso">ARS</option>
                        <option value="AWG" label="Aruban florin">AWG</option>
                        <option value="AZN" label="Azerbaijani manat">AZN</option>
                        <option value="BAM" label="Bosnia and Herzegovina convertible mark">BAM</option>
                        <option value="BBD" label="Barbadian dollar">BBD</option>
                        <option value="BDT" label="Bangladeshi taka">BDT</option>
                        <option value="BGN" label="Bulgarian lev">BGN</option>
                        <option value="BHD" label="Bahraini dinar">BHD</option>
                        <option value="BIF" label="Burundian franc">BIF</option>
                        <option value="BMD" label="Bermudian dollar">BMD</option>
                        <option value="BND" label="Brunei dollar">BND</option>
                        <option value="BOB" label="Bolivian boliviano">BOB</option>
                        <option value="BRL" label="Brazilian real">BRL</option>
                        <option value="BSD" label="Bahamian dollar">BSD</option>
                        <option value="BTN" label="Bhutanese ngultrum">BTN</option>
                        <option value="BWP" label="Botswana pula">BWP</option>
                        <option value="BYN" label="Belarusian ruble">BYN</option>
                        <option value="BZD" label="Belize dollar">BZD</option>
                        <option value="CDF" label="Congolese franc">CDF</option>
                        <option value="CHF" label="Swiss franc">CHF</option>
                        <option value="CLP" label="Chilean peso">CLP</option>
                        <option value="CNY" label="Chinese yuan">CNY</option>
                        <option value="COP" label="Colombian peso">COP</option>
                        <option value="CRC" label="Costa Rican colón">CRC</option>
                        <option value="CUC" label="Cuban convertible peso">CUC</option>
                        <option value="CUP" label="Cuban peso">CUP</option>
                        <option value="CVE" label="Cape Verdean escudo">CVE</option>
                        <option value="CZK" label="Czech koruna">CZK</option>
                        <option value="DJF" label="Djiboutian franc">DJF</option>
                        <option value="DKK" label="Danish krone">DKK</option>
                        <option value="DOP" label="Dominican peso">DOP</option>
                        <option value="DZD" label="Algerian dinar">DZD</option>
                        <option value="EGP" label="Egyptian pound">EGP</option>
                        <option value="ERN" label="Eritrean nakfa">ERN</option>
                        <option value="ETB" label="Ethiopian birr">ETB</option>
                        <option value="EUR" label="EURO">EUR</option>
                        <option value="FJD" label="Fijian dollar">FJD</option>
                        <option value="FKP" label="Falkland Islands pound">FKP</option>
                        <option value="GBP" label="British pound">GBP</option>
                        <option value="GEL" label="Georgian lari">GEL</option>
                        <option value="GGP" label="Guernsey pound">GGP</option>
                        <option value="GHS" label="Ghanaian cedi">GHS</option>
                        <option value="GIP" label="Gibraltar pound">GIP</option>
                        <option value="GMD" label="Gambian dalasi">GMD</option>
                        <option value="GNF" label="Guinean franc">GNF</option>
                        <option value="GTQ" label="Guatemalan quetzal">GTQ</option>
                        <option value="GYD" label="Guyanese dollar">GYD</option>
                        <option value="HKD" label="Hong Kong dollar">HKD</option>
                        <option value="HNL" label="Honduran lempira">HNL</option>
                        <option value="HKD" label="Hong Kong dollar">HKD</option>
                        <option value="HRK" label="Croatian kuna">HRK</option>
                        <option value="HTG" label="Haitian gourde">HTG</option>
                        <option value="HUF" label="Hungarian forint">HUF</option>
                        <option value="IDR" label="Indonesian rupiah">IDR</option>
                        <option value="ILS" label="Israeli new shekel">ILS</option>
                        <option value="IMP" label="Manx pound">IMP</option>
                        <option value="INR" label="Indian rupee">INR</option>
                        <option value="IQD" label="Iraqi dinar">IQD</option>
                        <option value="IRR" label="Iranian rial">IRR</option>
                        <option value="ISK" label="Icelandic króna">ISK</option>
                        <option value="JEP" label="Jersey pound">JEP</option>
                        <option value="JMD" label="Jamaican dollar">JMD</option>
                        <option value="JOD" label="Jordanian dinar">JOD</option>
                        <option value="JPY" label="Japanese yen">JPY</option>
                        <option value="KES" label="Kenyan shilling">KES</option>
                        <option value="KGS" label="Kyrgyzstani som">KGS</option>
                        <option value="KHR" label="Cambodian riel">KHR</option>
                        <option value="KID" label="Kiribati dollar">KID</option>
                        <option value="KMF" label="Comorian franc">KMF</option>
                        <option value="KPW" label="North Korean won">KPW</option>
                        <option value="KRW" label="South Korean won">KRW</option>
                        <option value="KWD" label="Kuwaiti dinar">KWD</option>
                        <option value="KYD" label="Cayman Islands dollar">KYD</option>
                        <option value="KZT" label="Kazakhstani tenge">KZT</option>
                        <option value="LAK" label="Lao kip">LAK</option>
                        <option value="LBP" label="Lebanese pound">LBP</option>
                        <option value="LKR" label="Sri Lankan rupee">LKR</option>
                        <option value="LRD" label="Liberian dollar">LRD</option>
                        <option value="LSL" label="Lesotho loti">LSL</option>
                        <option value="LYD" label="Libyan dinar">LYD</option>
                        <option value="MAD" label="Moroccan dirham">MAD</option>
                        <option value="MDL" label="Moldovan leu">MDL</option>
                        <option value="MGA" label="Malagasy ariary">MGA</option>
                        <option value="MKD" label="Macedonian denar">MKD</option>
                        <option value="MMK" label="Burmese kyat">MMK</option>
                        <option value="MNT" label="Mongolian tögrög">MNT</option>
                        <option value="MOP" label="Macanese pataca">MOP</option>
                        <option value="MRU" label="Mauritanian ouguiya">MRU</option>
                        <option value="MUR" label="Mauritian rupee">MUR</option>
                        <option value="MVR" label="Maldivian rufiyaa">MVR</option>
                        <option value="MWK" label="Malawian kwacha">MWK</option>
                        <option value="MXN" label="Mexican peso">MXN</option>
                        <option value="MYR" label="Malaysian ringgit">MYR</option>
                        <option value="MZN" label="Mozambican metical">MZN</option>
                        <option value="NAD" label="Namibian dollar">NAD</option>
                        <option value="NGN" label="Nigerian naira">NGN</option>
                        <option value="NIO" label="Nicaraguan córdoba">NIO</option>
                        <option value="NOK" label="Norwegian krone">NOK</option>
                        <option value="NPR" label="Nepalese rupee">NPR</option>
                        <option value="NZD" label="New Zealand dollar">NZD</option>
                        <option value="OMR" label="Omani rial">OMR</option>
                        <option value="PAB" label="Panamanian balboa">PAB</option>
                        <option value="PEN" label="Peruvian sol">PEN</option>
                        <option value="PGK" label="Papua New Guinean kina">PGK</option>
                        <option value="PHP" label="Philippine peso">PHP</option>
                        <option value="PKR" label="Pakistani rupee">PKR</option>
                        <option value="PLN" label="Polish złoty">PLN</option>
                        <option value="PRB" label="Transnistrian ruble">PRB</option>
                        <option value="PYG" label="Paraguayan guaraní">PYG</option>
                        <option value="QAR" label="Qatari riyal">QAR</option>
                        <option value="RON" label="Romanian leu">RON</option>
                        <option value="RON" label="Romanian leu">RON</option>
                        <option value="RSD" label="Serbian dinar">RSD</option>
                        <option value="RUB" label="Russian ruble">RUB</option>
                        <option value="RWF" label="Rwandan franc">RWF</option>
                        <option value="SAR" label="Saudi riyal">SAR</option>
                        <option value="SEK" label="Swedish krona">SEK</option>
                        <option value="SGD" label="Singapore dollar">SGD</option>
                        <option value="SHP" label="Saint Helena pound">SHP</option>
                        <option value="SLL" label="Sierra Leonean leone">SLL</option>
                        <option value="SLS" label="Somaliland shilling">SLS</option>
                        <option value="SOS" label="Somali shilling">SOS</option>
                        <option value="SRD" label="Surinamese dollar">SRD</option>
                        <option value="SSP" label="South Sudanese pound">SSP</option>
                        <option value="STN" label="São Tomé and Príncipe dobra">STN</option>
                        <option value="SYP" label="Syrian pound">SYP</option>
                        <option value="SZL" label="Swazi lilangeni">SZL</option>
                        <option value="THB" label="Thai baht">THB</option>
                        <option value="TJS" label="Tajikistani somoni">TJS</option>
                        <option value="TMT" label="Turkmenistan manat">TMT</option>
                        <option value="TND" label="Tunisian dinar">TND</option>
                        <option value="TOP" label="Tongan paʻanga">TOP</option>
                        <option value="TRY" label="Turkish lira">TRY</option>
                        <option value="TTD" label="Trinidad and Tobago dollar">TTD</option>
                        <option value="TVD" label="Tuvaluan dollar">TVD</option>
                        <option value="TWD" label="New Taiwan dollar">TWD</option>
                        <option value="TZS" label="Tanzanian shilling">TZS</option>
                        <option value="UAH" label="Ukrainian hryvnia">UAH</option>
                        <option value="UGX" label="Ugandan shilling">UGX</option>
                        <option value="USD" label="United States dollar">USD</option>
                        <option value="UYU" label="Uruguayan peso">UYU</option>
                        <option value="UZS" label="Uzbekistani soʻm">UZS</option>
                        <option value="VES" label="Venezuelan bolívar soberano">VES</option>
                        <option value="VND" label="Vietnamese đồng">VND</option>
                        <option value="VUV" label="Vanuatu vatu">VUV</option>
                        <option value="WST" label="Samoan tālā">WST</option>
                        <option value="XAF" label="Central African CFA franc">XAF</option>
                        <option value="XCD" label="Eastern Caribbean dollar">XCD</option>
                        <option value="XOF" label="West African CFA franc">XOF</option>
                        <option value="XPF" label="CFP franc">XPF</option>
                        <option value="ZAR" label="South African rand">ZAR</option>
                        <option value="ZMW" label="Zambian kwacha">ZMW</option>
                        <option value="ZWB" label="Zimbabwean bonds">ZWB</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label for="addStock" className="col-form-label">Product Stock:</label>
                    <input value={stock} onChange={(event) => {setStock(parseInt(event.target.value))}} min="0" placeholder="99" type="number" name="addstock" className="form-control" id="addStock" required />
                  </div>
                  <div className="form-group">
                    <label for="addPass" className="col-form-label">URL Slug:</label>
                    <input value={password} onChange={(event) => {setPassword(event.target.value)}} placeholder="specialproduct123" type="text" name="addpass" className="form-control" id="addPass" />
                  </div>

                  {
                    productType === "discord" ? (
                      <div className="form-group">
                        <label for="reladdDiscRoles" className="col-form-label">Discord Roles:</label>
                        <select 
                            value={discordRoles} 
                            onChange={(event) => {
                                var values = [];
                                var options = event.target.options;
                                for (var i = 0; i < options.length;  i++) {
                                    if (options[i].selected) {
                                        values.push(options[i].value);
                                    }
                                }
                                setDiscordRoles(values);
                            }}
                            multiple 
                            className="form-control"
                            name="adddiscordroles" 
                            aria-describedby="rolesHelp" 
                            className="form-control" 
                            id="reladdDiscRoles" 
                            required>
                            {
                                discord.botStatus.roles.map(role => {
                                    return (
                                        <option value={role.id}>{role.name}</option>
                                    )
                                })
                            }
                        </select>
                        <small id="rolesHelp" className="form-text text-muted">You can select multiple roles by using control, cmd, or shift click.</small>
                      </div>
                    ) : null
                  }

                  {
                    productType === "discord" && trialDays > 0 ?
                    (
                        <div className="form-group">
                            <label for="editRoles" className="col-form-label">Discord Roles For Trial Period:</label>
                            <select value={trialRoles}
                                onChange={(event) => {
                                    var values = [];
                                    var options = event.target.options;
                                    for (var i = 0; i < options.length;  i++) {
                                        if (options[i].selected) {
                                            values.push(options[i].value);
                                        }
                                    }
                                    setTrialRoles(values);
                                }}
                                multiple className="form-control"
                                    >
                                    {
                                    discord.botStatus.roles.map(role => {
                                        return (
                                            <option value={role.id}>{role.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <small className="form-text text-muted">These roles will be applied to the user while on trial. Once trial ends and regular subscription begins, configured roles for the regular product will automatically replace the trial roles.</small>
                        </div>
                    ) : null
                }



                  {
                    productType === "discord" ? (
                      <>
                        <div className="form-group">
                          <label for="relAddKickUser" className="col-form-label">User removal:</label>
                          <select onChange={(event) => {setRemoval(event.target.value)}} value={removal} describedby="kickHelp" className="form-control select" name="addkickuser" id="relAddKickUser" required>
                            <option value="remove">Kick user</option>
                            <option value="role">Remove role</option>
                          </select>
                          <small id="kickHelp" className="form-text text-muted">Kick user will remove user from Discord server when his subscription expires, if you select remove role the selected will be removed when the subscription expires.</small>
                        </div>
                        
                        {
                            removal === 'role' ? (
                              <div id="roleRemovalGroup" className="form-group">
                                  <label for="relRemoveRoles" className="col-form-label">Role(s) to remove:</label>
                                  <select value={roleRemoval} 
                                      onChange={(event) => {
                                          var values = [];
                                          var options = event.target.options;
                                          for (var i = 0; i < options.length;  i++) {
                                              if (options[i].selected) {
                                                  values.push(options[i].value);
                                              }
                                          }
                                          setRoleRemoval(values);
                                      }}
                                      describedby="removalHelp" multiple className="form-control" name="adddiscordrolesremoval" lass="form-control" id="relRemoveRoles">
                                      {
                                          discord.botStatus.roles.map(role => {
                                              return (
                                                  <option value={role.id}>{role.name}</option>
                                              )
                                          })
                                      }
                                  </select>
                                  <small id="removalHelp" className="form-text text-muted">You can select multiple products by using control, cmd, or shift click.</small>
                              </div>
                            ) : null
                        }
                      </>
                    ) : null
                  }

                  <div className="form-group">
                    <label className="col-form-label">Features:</label>
                    <textarea 
                      value={features}
                      onChange={(event) => {setFeatures(event.target.value)}}
                      className="form-control"
                      name="addfeatures"
                      id="addFeatures"
                      style={{
                        fontSize: 14,
                        backgroundColor: 'rgba(228, 228, 228, .3)',
                        border: 'none'
                      }}
                    />
                    <small className="form-text text-muted">Separate features by comma for example: Exclusive access to private channels, voice channel access, access to live streams and events</small>
                    {/* <input value={price} onChange={(event) => {setPrice(parseFloat(event.target.value))}} min="0" placeholder="25" step=".01" type="number" name="addplanprice" className="form-control" id="addPlanPrice" required /> */}

                  </div>
                  

                  {
                    data.stripe.connected ? (
                      <>
                      <div className="form-group">
                        <label className="checkbox-container modal-checkbox-container">
                          <input type="checkbox" value={useUpgrades} onChange={(event) => {setUseUpgrades(event.target.checked)}} checked={useUpgrades}/>
                          <span className="checkmark"></span>
                          <label className="col-form-label">Switch to Product(s):</label>
                        </label>

                        {
                          useUpgrades === true ? (
                            <>
                            <select value={upgrades} 
                                onChange={(event) => {
                                    var values = [];
                                    var options = event.target.options;
                                    for (var i = 0; i < options.length;  i++) {
                                        if (options[i].selected) {
                                            values.push(options[i].value);
                                        }
                                    }
                                    setUpgrades(values);
                                }}
                                multiple className="form-control" name="addupgradeplans" className="form-control" id="relUpgradePlans" aria-describedby="upgradeHelp">
                                {
                                    releases.map(release => {
                                      return (
                                        <>
                                        {
                                          release.stock > 0 ? (
                                            <option value={release.planId}>{release.name} ${release.price}</option>
                                          )
                                          : null
                                        }
                                        </>
                                      )
                                      
                                    })
                                }
                            </select>
                            <small id="upgradeHelp" className="form-text text-muted">You can select multiple roles by using control, cmd, or shift click.</small>
                            </>
                          ) : null
                        }

                        </div>
                        <div className="form-group">

                        <label className="checkbox-container modal-checkbox-container">
                          <input type="checkbox" value={useAddOns} onChange={(event) => {setUseAddOns(event.target.checked)}} checked={useAddOns}/>
                          <span className="checkmark"></span>
                          <label className="col-form-label">Add-on Product(s):</label>
                        </label>

                        {
                          useAddOns === true ? (
                            <>
                              <select value={addOns} 
                                onChange={(event) => {
                                    var values = [];
                                    var options = event.target.options;
                                    for (var i = 0; i < options.length;  i++) {
                                        if (options[i].selected) {
                                            values.push(options[i].value);
                                        }
                                    }
                                    setAddOns(values);
                                }}
                                multiple className="form-control" name="addaddonplans" className="form-control" id="relAddOnPlans" aria-describedby="addOnHelp">
                                {
                                    releases.map(release => {
                                      return (
                                        <>
                                        {
                                          release.stock > 0 ? (
                                            <option value={release.planId}>{release.name} ${release.price}</option>
                                          )
                                          : null
                                        }
                                        </>
                                      )
                                    })
                                }
                            </select>
                            <small id="addOnHelp" className="form-text text-muted">Add-on products are products that members will be able to add-on to their additional subscriptions at a later date. You can select multiple products by using control, cmd, or shift click.</small>
                            </>
                          ) : null
                        }

                        </div>
                      </>
                    ) : null
                  }
 

                  <div className="alert alert-danger" id="relAddErr" role="alert">
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-main-type" style={{"display":"block","margin":"0 auto","float":"none"}}>Add Product</button>
                </div>
              </form>
            </div>
          </div>
        </div>
    )
}

export default CreateReleaseModal;

