import $ from "jquery"
import bootbox from "bootbox";
import Axios from "axios";

var postTable;

// Pull all releases from API
export const getPosts = (setPosts, setLoading, setPage, setOpenPost) => {
    $.get("/api/dashboard/posts",
        (data, status) => {
            if (status == "success") {
                if (data.success == true) {
                    setLoading(false);
                    setPosts(data.posts);
                    createPostTable(setPage, setOpenPost);
                    return;
                } else {
                    return window.location.href = "/logout";
                }
            } else {
                return window.location.href = "/logout";
            }
        });
}




const createPostTable = (setPage, setOpenPost) => {
    if ($("#postTable").length) {

        $("#postTable .clickable").on("click", function (e) {
            var post = JSON.parse($(this).parent().attr("data-identify"));
            // http://localhost:3000/api/user/posts/media/${post.postId}#toolbar=0&navpanes=0

            if (post.type.includes("image")) {
                setOpenPost({
                    postId: post.postId,
                    postLink: `/api/dashboard/posts/media/${post.postId}`,
                    title: post.title,
                    description: post.description,
                    visibility: post.visibility,
                    type: post.type
                });
            } else if (post.type === "application/pdf") {
                // window.open(`/api/dashboard/posts/media/${post.postId}`, '_blank');
                setOpenPost({
                    postId: post.postId,
                    postLink: `/api/dashboard/posts/media/${post.postId}#toolbar=0&navpanes=0`,
                    title: post.title,
                    description: post.description,
                    visibility: post.visibility,
                    type: post.type
                });

            } else if (post.type.includes("video")) {
                setOpenPost({
                    postId: post.postId,
                    postLink: `/api/dashboard/posts/video/${post.postId}`,
                    title: post.title,
                    description: post.description,
                    visibility: post.visibility,
                    type: post.type
                });
            }
            setPage("post")


        });

        postTable = $('#postTable').DataTable({
            ordering: false,
            lengthChange: false,
            initComplete: function (settings, json) {
                $('body').find('.dataTables_scrollBody').addClass("scrollbar");
                $("#postTable").wrap("<div class='userTableScroll' style='overflow:auto; width:100%;position:relative;'></div>");
            }
        });

        $("#post-search").on("keyup change", function () {
            postTable.search(this.value).draw();
        });

        $(".checkbox-input-all-posts").on("click", function () {
            var rows = document.getElementById("postTable").getElementsByTagName("tbody")[0].getElementsByTagName("tr");
            for (var i = 0; i < rows.length; i++) {
                var row = rows[i];
                row.getElementsByClassName("checkbox-input-post")[0].checked = document.getElementsByClassName("checkbox-input-all-posts")[0].checked;
            }
        });

        
        $(document).on('click', '#postTable_previous', function(){
            var rows = document.getElementById("postTable").getElementsByTagName("tbody")[0].getElementsByTagName("tr");
            console.log('Updating', rows.length);
            for (var i = 0; i < rows.length; i++) {
                var row = rows[i];
                row.getElementsByClassName("checkbox-input-post")[0].checked = document.getElementsByClassName("checkbox-input-all-posts")[0].checked;
            }
        });
 

        $(document).on('click', '#postTable_next', function(){
            var rows = document.getElementById("postTable").getElementsByTagName("tbody")[0].getElementsByTagName("tr");
            console.log('Updating', rows.length);
            for (var i = 0; i < rows.length; i++) {
                var row = rows[i];
                row.getElementsByClassName("checkbox-input-post")[0].checked = document.getElementsByClassName("checkbox-input-all-posts")[0].checked;
            }
        });


 
    } else {
        setTimeout(createPostTable, 50);
    }
}


export const callAddPost = () => {
    $("#postAddModal").modal('toggle');
}


export const callDeletePost = (pullPosts) => {
    var selectedPosts = document.getElementsByClassName("checkbox-input-post");
    var postId = "";
    for (var i = 0; i < selectedPosts.length; i++) {
        if (selectedPosts[i].checked == true) {
            let row = selectedPosts[i].parentNode.parentNode.parentNode
            postId = row.getAttribute("id");
        }
    }

    if (postId !== "") {
        bootbox.confirm({
            message: `Are you sure you want to delete post`,
            centerVertical: true,
            buttons: {
                confirm: {
                    label: 'Yes',
                    className: 'btn-main-type'
                },
                cancel: {
                    label: 'No',
                    className: 'btn-second-type'
                }
            },
            callback: function (result) {
                if (result == true) {
                    $.ajax({
                        url: "/api/dashboard/posts/delete",
                        type: "POST",
                        contentType: 'application/json',
                        data: JSON.stringify({
                            postId: postId
                        }),
                        success: function (data) {
                            if (data.success) {
                                bootbox.alert("Successfully Deleted Post", () => {
                                    pullPosts();
                                })
                            } else {
                                bootbox.alert(data.error, () => {
                                    pullPosts();
                                });
                            }
                        }
                    }).fail(function (jqXHR, textStatus, error) {
                        bootbox.alert("Failed request... Please try again", () => {
                            window.location.reload();
                        });
                    });
                }
            }
        })
        return;
    } else {
        bootbox.alert("Please select posts you would like to delete");
    }
}

export const submitPost = async (file, form, resetValues, pullPosts, setUploadPercentage, setProgressBarVisibility) => {
    document.getElementById("add-post-form").getElementsByClassName("btn-main-type")[0].disabled = true;
    document.getElementById("add-post-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Uploading File...";
    let formData = new FormData();
    formData.append("post", file);

    var xhr = new XMLHttpRequest();
    xhr.open('POST', `/api/dashboard/posts/upload?form=${JSON.stringify(form)}`);
    xhr.onerror = () => {
        $("#addPostErr").text("Request failed...");
        $("#addPostErr").show();
        $("#addPostErr").delay(5000).hide(1);
        setProgressBarVisibility(false);
        document.getElementById("add-post-form").getElementsByClassName("btn-main-type")[0].disabled = false;
        document.getElementById("add-post-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Create";
    }
    xhr.onload = function () {
        var response = JSON.parse(xhr.responseText);
        if (response.success) {
            $("#postAddModal").modal('toggle');
            bootbox.alert("Successfully Created Post", () => {
                document.getElementById("add-post-form").getElementsByClassName("btn-main-type")[0].disabled = false;
                document.getElementById("add-post-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Create";
                resetValues();
                pullPosts();
            });
        } else {
            $("#addPostErr").text(response.error);
            $("#addPostErr").show();
            $("#addPostErr").delay(5000).hide(1);
            setProgressBarVisibility(false);
            document.getElementById("add-post-form").getElementsByClassName("btn-main-type")[0].disabled = false;
            document.getElementById("add-post-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Create";
        }
    };


    xhr.upload.onprogress = function (evt) {
        if (evt.lengthComputable) {
            var percentComplete = (((evt.loaded / evt.total) * 100).toFixed(2));
            setUploadPercentage(percentComplete);
        }
    };
    setUploadPercentage(0.0);
    setProgressBarVisibility(true);
    xhr.send(formData);

}


// export const submitPost = async (file, form, resetValues, pullPosts) => {
//     document.getElementById("add-post-form").getElementsByClassName("btn-main-type")[0].disabled = true;
//     document.getElementById("add-post-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Uploading File...";
//     let formData = new FormData();
//     formData.append("post",  file);
//     console.log(file);
//     console.log(formData)
//     const response = await fetch(`/api/dashboard/posts/upload`, {
//         method: 'post',
//         headers: form,
//         body: formData,
//     }).catch((err) => {
//         $("#addPostErr").text("Failed request... Please try again");
//         $("#addPostErr").show();
//         $("#addPostErr").delay(5000).hide(1);
//         document.getElementById("add-post-form").getElementsByClassName("btn-main-type")[0].disabled = false;
//         document.getElementById("add-post-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Create";
//     });
//     try {
//         const data = await response.json();
//         if (data.success) {
//             $("#postAddModal").modal('toggle');
//             bootbox.alert("Successfully Created Post", () => {
//                 document.getElementById("add-post-form").getElementsByClassName("btn-main-type")[0].disabled = false;
//                 document.getElementById("add-post-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Create";
//                 resetValues();
//                 pullPosts();
//             });
//         } else {
//             $("#addPostErr").text(data.error);
//             $("#addPostErr").show();
//             $("#addPostErr").delay(5000).hide(1);
//             document.getElementById("add-post-form").getElementsByClassName("btn-main-type")[0].disabled = false;
//             document.getElementById("add-post-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Create";
//         }
//     }
//     catch (err) {
//         $("#addPostErr").text("Failed request... Please try again");
//         $("#addPostErr").show();
//         $("#addPostErr").delay(5000).hide(1);
//         document.getElementById("add-post-form").getElementsByClassName("btn-main-type")[0].disabled = false;
//         document.getElementById("add-post-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Create";
//     }

// }

export const callVisibility = (pullPosts) => {
    var selectedPosts = document.getElementsByClassName("checkbox-input-post");
    var postIds = [];
    for (var i = 0; i < selectedPosts.length; i++) {
        if (selectedPosts[i].checked == true) {
            let row = selectedPosts[i].parentNode.parentNode.parentNode
            let postId = row.getAttribute("id");
            let visibility = row.getAttribute("visibility");
            postIds.push({postId: postId, visibility: visibility});
        }
    }

    if (postIds.length > 0) {
        bootbox.confirm({
            message: `Are you sure you want to change visibility for ${postIds.length} post(s)?`,
            centerVertical: true,
            buttons: {
                confirm: {
                    label: 'Yes',
                    className: 'btn-main-type'
                },
                cancel: {
                    label: 'No',
                    className: 'btn-second-type'
                }
            },
            callback: function (result) {
                if (result == true) {
                    $.ajax({
                        url: `/api/dashboard/posts/toggle`,
                        type: "POST",
                        contentType: 'application/json',
                        data: JSON.stringify({
                            postIds: postIds
                        }),
                        success: function (data) {
                            if (data.success) {
                                bootbox.alert("Successfully Toggled Post Visibility", () => {
                                    pullPosts();
                                })
                            } else {
                                bootbox.alert(data.error, () => {
                                    pullPosts();
                                });
                            }
                        }
                    }).fail(function (jqXHR, textStatus, error) {
                        bootbox.alert("Failed request... Please try again", () => {
                            window.location.reload();
                        });
                    });
                }
            }
        })
        return;
    } else {
        bootbox.alert("Please select posts you would like to toggle");
    }
}

export const editPost = (id, form, pullPosts) => {
    fetch(`/api/dashboard/posts/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(form)
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            bootbox.alert("Successfully updated post");
        } else {
            bootbox.alert(data.error);
        }
        pullPosts();
    }).catch((err) => {
        bootbox.alert("Error updating post");
        console.log(err);
    })
}
