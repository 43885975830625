import React, { useEffect, useState, useRef } from "react";
import {  delSpecificUser, callToggleMenu, getUser, callEditSpecificUser } from "../../Actions/Dashboard/users";
import { exportSpecificUser } from "../../Actions/Dashboard/dashboard";
import $ from "jquery"
import EditUserModal from "./Modals/EditUser";
import currencies from "./currencies";

const User = ({ data, page, pullUsers, user, setPage, display }) => {
    const [help, showHelp] = useState(false);
    const [image, setImage] = useState("");
    const [discord, setDiscord] = useState("");
    const [key, setKey] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [discordId, setDiscordId] = useState("");
    const [referral, setReferral] = useState("");
    const [totalReferrals, setTotalReferrals] = useState(0);
    const [address, setAddress] = useState(false);
    const [phone, setPhone] = useState("");
    const [line1, setLine1] = useState("");
    const [line2, setLine2] = useState("");
    const [zip, setZip] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [invoices, setInvoices] = useState([]);

    const [subscriptions, setSubscriptions] = useState([]);

    const [payments, setPayments] = useState([]);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editUser, setEditUser] = useState({});

    const pullUser = () => {
        if ($.fn.DataTable.isDataTable( '#invoiceTable' )) {
            $("#invoiceTable").dataTable().fnDestroy();
        }
        if ($.fn.DataTable.isDataTable( '#paymentTable' )) {
            $("#paymentTable").dataTable().fnDestroy();
        }
        console.log("pulling user");
        setLoading(true);
        getUser(key, setUserData, setLoading);
    }

    useEffect(() => {
        if (key != "") {
            pullUser();
        }
    }, [key])

    useEffect(() => {
        if (user.key) { 
            setKey(user.key);
        }
    }, [user])

    useEffect(() => {
        if (userData.discordObject && userData.discordObject.id) {
            if (userData.discordObject.avatar == null) {
              setImage(`https://discordapp.com/assets/322c936a8c8be1b803cd94861bdfa868.png`);
            } else {
              setImage(`https://cdn.discordapp.com/avatars/${userData.discordObject.id}/${userData.discordObject.avatar}.png`);
            }
            setDiscord(`${userData.discordObject.username}#${userData.discordObject.discriminator}`);
        } else {
            setDiscord("n/a");
            setImage(`https://discordapp.com/assets/322c936a8c8be1b803cd94861bdfa868.png`);
        }
        if (userData.firstName) {
            setName(`${userData.firstName} ${userData.lastName}`);
        } else {
            setName("n/a");
        }
        setEmail(userData.customerEmail);
        setCustomerId(userData.customerId);
        setDiscordId(userData.discordId ? userData.discordId : "n/a");
        setReferral(userData.referral);
        setTotalReferrals(userData.totalReferrals);
        setSubscriptions(userData.subscriptionObject);
        if (userData.address && userData.address.line1) {
            setAddress(true);
            setPhone(userData.address.phone);
            setLine1(userData.address.line1);
            setLine2(userData.address.line2);
            setZip(userData.address.postal_code);
            setCity(userData.address.city);
            setState(userData.address.state);
            setCountry(userData.address.country);
        } else {
            setAddress(false);
        }
        setInvoices(userData.invoices);
        setPayments(userData.payments);
    }, [userData])


    return (
        <div id="user-page" style={{display: display === true ? true : 'none'}}>
            <div className="container-fluid row">
            <div className="col-sm-12">
                    <h1 className="mt-4">User Profile</h1>
                    <div className="user-corner-panel">

                    <svg style={{"verticalAlign":"middle","marginRight":"10px","cursor":"pointer"}} onClick={() => {showHelp(!help)}} width="30"
                        height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                        d="M14.8261 0C6.63787 0 0 6.63787 0 14.8261C0 23.0143 6.63787 29.6522 14.8261 29.6522C23.0143 29.6522 29.6522 23.0143 29.6522 14.8261C29.6522 6.63787 23.0143 0 14.8261 0ZM14.8261 2.69565C21.5255 2.69565 26.9565 8.12663 26.9565 14.8261C26.9565 21.5255 21.5255 26.9565 14.8261 26.9565C8.12663 26.9565 2.69565 21.5255 2.69565 14.8261C2.69565 8.12663 8.12663 2.69565 14.8261 2.69565Z"
                        fill="#808191" />
                        <path fillRule="evenodd" clipRule="evenodd"
                        d="M14.832 20.2174C15.5764 20.2174 16.1798 20.8208 16.1798 21.5652C16.1798 22.2564 15.6595 22.8261 14.9892 22.904L14.832 22.913C14.0817 22.913 13.4782 22.3096 13.4782 21.5652C13.4782 20.874 13.9986 20.3043 14.6689 20.2264L14.832 20.2174ZM18.4572 7.2585L18.6701 7.46842C20.4876 9.31629 20.4778 12.2194 18.73 14.0234L18.4132 14.332C17.9546 14.769 17.4886 15.1181 16.7977 15.6071C16.1485 16.0936 16.0071 16.3252 16.0071 17.05C16.0071 17.7944 15.4036 18.3978 14.6592 18.3978C13.9149 18.3978 13.3114 17.7944 13.3114 17.05C13.3114 15.3795 13.9064 14.4052 15.1813 13.4499L15.7904 13.0084C16.0136 12.8429 16.1825 12.7104 16.3343 12.5795L16.6725 12.2654C17.4784 11.5231 17.555 10.3359 16.883 9.50925L16.7483 9.35868L16.6144 9.2259C15.5484 8.22739 13.8659 8.20049 12.7665 9.12547L12.5889 9.28844L11.9297 9.94753C11.4033 10.4738 10.5499 10.4737 10.0236 9.94732C9.53775 9.4614 9.50046 8.69686 9.91164 8.16817L10.0238 8.0412L10.683 7.38212C12.8001 5.26548 16.2709 5.21072 18.4572 7.2585Z"
                        fill="#808191" />
                    </svg>
                    <div className="help-dropdown" style={{display: help === true ? 'block' : 'none'}}>
                        <ul>
                        <li><a target="_blank" href="https://ternarydev.notion.site/Ternary-4adf412dd4444fcb90c0a457d2a3607c">Helpful Articles</a>
                        </li>
                        <li><a target="_blank" href="https://docs.ternarydev.com/ternary-developments/change-log">Release Notes</a></li>
                        <li><a href="mailto:support@ternarydev.com">Contact Us</a></li>
                        </ul>
                    </div>


                    <a className="settings-button" onClick={() => {setPage('subscription')}}>
                        <svg style={{"verticalAlign":"middle","cursor":"pointer"}} width="30" height="30" viewBox="0 0 30 30"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM15 2.72729C21.7781 2.72729 27.2727 8.22198 27.2727 15C27.2727 21.7781 21.7781 27.2727 15 27.2727C8.22198 27.2727 2.72729 21.7781 2.72729 15C2.72729 8.22198 8.22198 2.72729 15 2.72729Z"
                            fill="#808191" />
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M14.9999 5.45459C10.4812 5.45459 6.81812 9.11771 6.81812 13.6364C6.81812 18.1551 10.4812 21.8182 14.9999 21.8182C19.5186 21.8182 23.1818 18.1551 23.1818 13.6364C23.1818 9.11771 19.5186 5.45459 14.9999 5.45459ZM14.9997 8.18188C18.0122 8.18188 20.4543 10.624 20.4543 13.6364C20.4543 16.6489 18.0122 19.091 14.9997 19.091C11.9873 19.091 9.54517 16.6489 9.54517 13.6364C9.54517 10.624 11.9873 8.18188 14.9997 8.18188Z"
                            fill="#808191" />
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M15.0066 19.0911C19.4226 19.0911 22.7977 20.6431 24.9792 23.743C25.4126 24.3589 25.2647 25.2095 24.6488 25.6429C24.0329 26.0764 23.1822 25.9284 22.7488 25.3125C21.1085 22.9817 18.5787 21.8183 15.0066 21.8183C11.4268 21.8183 8.89371 22.9866 7.25357 25.328C6.82148 25.9448 5.97116 26.0946 5.35433 25.6625C4.7375 25.2304 4.58774 24.3801 5.01983 23.7632C7.20065 20.65 10.5808 19.0911 15.0066 19.0911Z"
                            fill="#808191" />
                        </svg>
                    </a>

                    </div>
                </div>

                <div style={{"marginTop":"30px"}} className="col-md-12">
                    <a onClick={() => {setPage("users")}} style={{"fontWeight":"bold","fontSize":"24px","color":"#F55B6A"}}>
                    <i style={{"color":"#F55B6A"}} className="fas fa-angle-left"></i>
                    Back to the List
                    </a>
                </div>


                <div className="col-md-12">
                    {loading == true ? (
                        <div className="user-overview row">
                            <div className="col-sm-8">
                                <div>
                                    <div style={{"width":"80px","height":"80px","borderRadius":"24px"}} className="shine"></div>
                                </div>
                                <div style={{"justifyContent":"center","display":"flex","flexDirection":"column","marginLeft":"15px"}}>
                                <div className="shine"></div>

                                <div className="shine"></div>

                                <div className="shine"></div>
                                </div>
                            </div>

                            <div className="col-sm-4">

                                <div className="dropdown show">
                                <button className="btn">Actions <i style={{"marginLeft":"5px"}} className="fas fa-caret-down"></i></button>
                                </div>

                            </div>
                        </div>
                    ) : (
                        <div className="user-overview row">
                            <div className="col-12 user-page-picture-container-mobile">
                                <img id="user-page-picture" src={image} alt="" style={{"borderRadius":"24px", "height": 80, "width": 80}} height="80px" width="80px"  />
                            </div>
                            <div className="col-sm-8">
                                <div className="user-page-picture-container">
                                    <img id="user-page-picture" src={image} alt="" style={{"borderRadius":"24px", "height": 80, "width": 80}} height="80px" width="80px"  />
                                </div>
                                <div style={{"justifyContent":"center","display":"flex","flexDirection":"column","marginLeft":"15px"}}>
                                    <h2  id="user-page-discord">{discord}</h2>

                                    <span  id="user-page-customerEmail">{email}</span>

                                    <span  id="user-page-fullName">{name}</span>
                                </div>
                            </div>

                            <div className="col-sm-4">

                                <div className="dropdown show">
                                <button onClick={() => {callToggleMenu()}} className="btn">Actions <i style={{"marginLeft":"5px"}} className="fas fa-caret-down"></i></button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <a onClick={() => {callEditSpecificUser(userData, setEditUser)}} className="dropdown-item">Edit user</a>
                                    <a onClick={() => {delSpecificUser(key, pullUsers, setPage)}} className="dropdown-item">Delete user</a>
                                    <a onClick={() => {exportSpecificUser(user)}} className="dropdown-item">Export user</a>
                                </div>
                                </div>

                            </div>
                        </div>
                    )}
                </div>

                <div id="user-profile-body" className="row container-fluid">
                    {loading == true ? (
                        <div className="card user-profile-card" style={{"marginRight":"35px"}}>
                        <div className="card-body">
                            <h2 style={{"marginBottom":"30px"}}>Account Details</h2>
                            <ul className="list-group list-group-flush" style={{"lineHeight":"25px"}}>
                            <li className="list-group-item"><strong>Key: </strong>
                                <div className="shine"></div>
                            </li>


                            <li className="list-group-item"><strong>Customer ID: </strong>
                                <div className="shine"></div>
                            </li>

                            <li className="list-group-item"><strong>Discord ID: </strong>
                                <div className="shine"></div>
                            </li>

                            <li className="list-group-item"><strong>Referral Code: </strong>
                                <div className="shine"></div>
                            </li>
                            <li className="list-group-item"><strong>Total Referrals: </strong>
                                <div className="shine"></div>
                            </li>
                            </ul>
                        </div>
                        </div>
                    ) : (
                        <div className="card user-profile-card" style={{"marginRight":"35px"}}>
                        <div className="card-body">
                            <h2 style={{"marginBottom":"30px"}}>Account Details</h2>
                            <ul className="list-group list-group-flush" style={{"lineHeight":"25px"}}>
                            <li className="list-group-item"><strong>Key: </strong>
                                <span id="user-page-key">{key}</span>
                            </li>


                            <li className="list-group-item"><strong>Customer ID: </strong>
                                <span  id="user-page-customerID">{customerId}</span>
                            </li>

                            <li className="list-group-item"><strong>Discord ID: </strong>
                                <span  id="user-page-discordID">{discordId}</span>
                            </li>

                            <li className="list-group-item"><strong>Referral Code: </strong>
                                <span  id="user-page-referral">{referral}</span>
                            </li>
                            <li className="list-group-item"><strong>Total Referrals: </strong>
                                <span  id="user-page-totalRef">{totalReferrals}</span>
                            </li>
                            </ul>
                        </div>
                        </div>
                    )}
                    


                    {address && loading == false ? (
                        <div id="user-address" className="card user-profile-card">
                        <div className="card-body">
                            <h2 style={{"marginBottom":"30px"}}>Address</h2>
                            <ul className="list-group list-group-flush" style={{"lineHeight":"25px"}}>

                            <li className="list-group-item"><strong>Line 1: </strong>
                                <span  id="user-page-line1">{line1}</span>
                            </li>

                            <li className="list-group-item"><strong>Line 2: </strong>
                                <span  id="user-page-line2">{line2}</span>
                            </li>

                            <li className="list-group-item"><strong>Zip: </strong>
                                <span  id="user-page-zip">{zip}</span>
                            </li>


                            <li className="list-group-item"><strong>City: </strong>
                                <span  id="user-page-city">{city}</span>
                            </li>

                            <li className="list-group-item"><strong>State: </strong>
                                <span  id="user-page-state">{state}</span>
                            </li>

                            <li className="list-group-item"><strong>Country: </strong>
                                <span  id="user-page-country">{country}</span>
                            </li>
                            </ul>
                        </div>
                        </div>
                    ): null}
                    
                    {subscriptions && loading === false ? (
                        <>
                            {subscriptions.map(subscription => {
                                return (
                                    <>
                                        <div style={{marginRight: 35}} className="card user-profile-card user-sub-card">
                                        <div className="card-body">
                                            <h2 style={{marginBottom:30}}>Subscription</h2>
                                            <ul className="list-group list-group-flush" style={{"lineHeight":"25px"}}>
            
                                                    <li className="list-group-item"><strong>Status: </strong>
                                                        <span  className="user-page-subscriptionCreated">{subscription.cancel_at_period_end === true ? "Cancelling at end of period." : "Active"}</span>
                                                    </li>
            
                                                    <li className="list-group-item"><strong>Start Date: </strong>
                                                    <span  className="user-page-subscriptionCreated">{new Date(subscription.created * 1000).toDateString()}</span>
                                                    </li>
                                                    <li className="list-group-item"><strong>Current Period: </strong>
                                                    <span  className="user-page-subscriptionPeriod">{new Date(subscription.current_period_start * 1000).toDateString()}</span>
                                                    </li>
                                                    <li className="list-group-item"><strong>Next Invoice: </strong>
                                                    <span  className="user-page-upcomingInvoice"><a target="_blank" href={`https://dashboard.stripe.com/customers/${customerId}/upcoming_invoice/${subscription.id}`}>{new Date(subscription.current_period_end * 1000).toDateString()}</a></span>
                                                    </li>
                                                    <li className="list-group-item"><strong>Subscription ID: </strong>
                                                    <span  className="user-page-subscriptionID"><a target="_blank" href={`https://dashboard.stripe.com/subscriptions/${subscription.id}`}>{subscription.id}</a></span>
                                                    </li>
                                                    <li className="list-group-item"><strong>Discount Applied: </strong>
                                                    <span  className="user-page-subscriptionDiscount">
                                                        {subscription.discount ? null : "None"}
                                                        {
                                                            subscription.discount && subscription.discount.coupon.amount_off ? (
                                                                <a target="_blank" href={`https://dashboard.stripe.com/${subscription.discount.coupon.id}`}>${subscription.discount.coupon.amount_off / 100} Off</a>
                                                            ) : null
                                                        }

                                                        {
                                                            subscription.discount && subscription.discount.coupon.percent_off ? (
                                                            <a target="_blank" href={`https://dashboard.stripe.com/coupons/${subscription.discount.coupon.id}`}>{subscription.discount.coupon.percent_off}% Off</a>
                                                            ) : null
                                                        }
                                                        
                                                    </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div style={{marginRight: 35}} className="card user-profile-card user-product-card">
                                        <div className="card-body">
                                            <h2 style={{marginBottom:30}}>Product</h2>
                                            <ul className="list-group list-group-flush" style={{"lineHeight":"25px"}}>
                                                    <li className="list-group-item"><strong>Price ID: </strong>
                                                    <span  className="user-page-subscriptionCreated">
                                                        <a href={`https://dashboard.stripe.com/prices/${subscription.items.data[0].plan.id}`}>{subscription.items.data[0].price.id}</a>
                                                    </span>
                                                    </li>
                                                    <li className="list-group-item"><strong>Price: </strong>
                                                    <span  className="user-page-subscriptionPeriod">{currencies[subscription.items.data[0].plan.currency.toUpperCase()].symbol.default.display}{subscription.items.data[0].plan.amount / 100}</span>
                                                    </li>
                                                    <li className="list-group-item"><strong>Interval: </strong>
                                                    <span  className="user-page-upcomingInvoice">Every {subscription.items.data[0].plan.interval_count} {subscription.items.data[0].plan.interval}</span>
                                                    </li>
                                                    <li className="list-group-item"><strong>Trial Period End: </strong>
                                                    <span  className="user-page-subscriptionDiscount">
                                                        {subscription.trial_end ? 
                                                            (
                                                                new Date(subscription.trial_end * 1000).toDateString()
                                                            )
                                                        : ""}
                                                        
                                                        
                                                    </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </>
                    ) : null}

                </div>
                </div>
            

                {
                    data.stripe.connected && (
                        <>
                            <div className="container-fluid row">
                            <div className="col-md-12"><h1 className="mt-4">Invoices</h1></div>
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                <table className="table" id="invoiceTable">
                                    <thead className="thead">
                                    <tr>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Invoice Number</th>
                                        <th>Due</th>
                                        <th>Created</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {invoices && loading !== true ? (
                                        <>
                                        {invoices.map(invoice => {
                                            return (
                                                <tr data-identify={JSON.stringify(invoice)}>
                                                    <td className="clickable">
                                                        {`${currencies[invoice.currency.toUpperCase()].symbol.default.display}${invoice.amount_due / 100}`}
                                                    </td>
                                                    <td className="clickable">
                                                        {invoice.paid ? "Paid" : "Pending"}
                                                    </td>
                                                    <td className="clickable">
                                                        {invoice.number}
                                                    </td>
                                                    <td className="clickable">
                                                        {invoice.due_date ? (new Date(invoice.due_date * 1000).toDateString()) : null}
                                                    </td>
                                                    <td className="clickable">
                                                        {new Date(invoice.created * 1000).toDateString()}
                                                    </td>
                                                    <td className="download">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.4206 18.8138L11.3205 18.7321L4.32052 12.2321C3.91581 11.8563 3.89238 11.2236 4.26818 10.8189C4.61507 10.4453 5.18089 10.3966 5.5842 10.6869L5.68142 10.7665L11.0018 15.7075L11.001 2.99933C11.001 2.44705 11.4487 1.99933 12.001 1.99933C12.5138 1.99933 12.9365 2.38537 12.9942 2.88271L13.001 2.99933L13.0018 15.7065L18.3205 10.7665C18.6941 10.4196 19.262 10.4129 19.6427 10.7323L19.7338 10.8189C20.0807 11.1925 20.0874 11.7603 19.768 12.1411L19.6814 12.2321L12.6814 18.7321C12.6378 18.7726 12.5915 18.8085 12.543 18.8398L12.5313 18.8455C12.1915 19.0606 11.7508 19.0496 11.4206 18.8138Z" fill="#F55B6A" stroke="#F55B6A" strokeWidth="0.3"/>
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M21 21C21.5523 21 22 21.4477 22 22C22 22.5128 21.614 22.9355 21.1166 22.9933L21 23H3C2.44772 23 2 22.5523 2 22C2 21.4872 2.38604 21.0645 2.88338 21.0067L3 21H21Z" fill="#F55B6A" stroke="#F55B6A" strokeWidth="0.3"/>
                                                        </svg>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </>
                                    ): null}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            </div>
                            <div className="container-fluid row">
                            <div className="col-md-12"><h1 className="mt-4">Payments</h1></div>
                            <div className="col-md-12">
                                <div className="table-responsive">
                                <table className="table" id="paymentTable">
                                    <thead className="thead">
                                    <tr>
                                        <th>Paid</th>
                                        <th>Description</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {payments && loading !== true  ? (
                                        <>
                                        {payments.map(payment => {
                                            return (
                                                <tr data-identify={JSON.stringify(payment)}>
                                                    <td className="clickable">
                                                        {`${currencies[payment.currency.toUpperCase()].symbol.default.display}${payment.amount_received / 100}`}
                                                    </td>
                                                    <td className="clickable">
                                                        {payment.description}
                                                    </td>
                                                    <td className="clickable">
                                                        {payment.status}
                                                    </td>
                                                    <td className="clickable">
                                                        {new Date(payment.created * 1000).toDateString()}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </>
                                    ): null}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                        </>
                        
                    )
                }

            {page === "user" ? (
                <EditUserModal mode={"user"} editUser={editUser} pullUser={pullUser} pullUsers={pullUsers}/>
            ) : null }
        </div>
    )
}


export default User
