import bootbox from "bootbox";
import $ from "jquery"
import currencies from "../../Components/Dashboard/currencies";
var myDoughnutChart;
var revChart;
var membersChart;
var productsChart;
var cancelsChart;
var globalReleases = [];
var currency = "USD";

// Only load analytics once releases is loaded in!
export const loadAnalytics = (data, releases) => {

  if (data.stripeAccount.account_currency) {
    currency = data.stripeAccount.account_currency.toUpperCase();
  }

    // Load contents and charts and do calculations
  if (data.authorizeNet) {
    console.log(data.authorizeNet)
      getMembers();
  }
  else if (data.stripe.connected) {
      getMembers();
      getCancels();
      getRevenue();
      getRefunds();
      getSubscriptions(data.releases);
      getCancelReasons();
  }

  globalReleases = releases;

  if (revChart) {
    revChart.destroy();
  }

  if (myDoughnutChart) {
    myDoughnutChart.destroy();
  }

  if (membersChart) {
    membersChart.destroy();
  }

  if (productsChart) {
    productsChart.destroy();
  }

  if (cancelsChart) {
    cancelsChart.destroy();
  }

  // check if this is the same group as cached items
  // if so -> load cached items
  // if not -> clear cache
  let groupName = document.getElementById("group_name").innerHTML;
  let cachedGroupName = window.localStorage.getItem("GROUP_NAME");
  if (cachedGroupName && cachedGroupName === groupName) {
    let cachedMembers = window.localStorage.getItem("MEMBERS");
    let cachedSubscriptions = window.localStorage.getItem("SUBSCRIPTIONS");
    let cachedRenewals = window.localStorage.getItem("RENEWALS");
    let cachedRevenue = window.localStorage.getItem("REVENUE");
    let cachedRefunds = window.localStorage.getItem("REFUNDS");
    let cachedCancels = window.localStorage.getItem("CANCELS");
    let cachedReleaseUsers = window.localStorage.getItem("RELEASEUSERS");
    let cachedCancelReasons = window.localStorage.getItem("CANCELREASONS");
    if (cachedMembers) {
      setMembers(JSON.parse(cachedMembers));
    }
    if (cachedSubscriptions) {
      setSubscriptions(JSON.parse(cachedSubscriptions));
    }
    if (cachedRenewals) {
      setRenewals(JSON.parse(cachedRenewals));
    }
    if (cachedRevenue) {
      setRevenue(JSON.parse(cachedRevenue));
    }
    if (cachedRefunds) {
      setRefunds(JSON.parse(cachedRefunds));
    }
    if (cachedCancels) {
      setCancels(JSON.parse(cachedCancels));
    }
    if (cachedCancelReasons) {
      setCancelReasons(JSON.parse(cachedCancelReasons));
    }
  } else if (cachedGroupName && cachedGroupName !== groupName) {
    window.localStorage.clear();
  }




  window.localStorage.setItem("GROUP_NAME", groupName)
  
}


// window.setInterval(function(){
//   getRevenue();
//   getSubscriptions();
//   getMembers();
// }, 60000);

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}



function commaSeparateNumber(val) {
  while (/(\d+)(\d{3})/.test(val.toString())) {
    val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
  }
  return val;
}

function getMembers() {
  $.ajax({
    url: "/api/analytics/members",
    type: "get",
    dataType: "json",
    success: function (data) {
      if (data.success) {
        console.log("MEMBERS", data);
        setMembers(data.members);
      }
    }
  });
}

const setMembers = (members) => {
  let parent = $("#members").parent();
  $(parent).find(".shine").css("display", "none");
  $("#members").text(commaSeparateNumber(members));
  window.localStorage.setItem("MEMBERS", JSON.stringify(members));
}


function getSubscriptions() {
  $.ajax({
    url: "/api/analytics/subscriptions",
    type: "get",
    dataType: "json",
    success: function (data) {
      if (data.success) {
        setSubscriptions(data.sales);
      }
    }
  });
}

const setSubscriptions = (sales) => {
  var parent = $("#orders").parent();
  $(parent).find(".shine").css("display", "none");
  $("#orders").text(sales.length);
  let today = new Date()
  let week1 = new Date(new Date().setDate(today.getDate() - 28));
  let week1Subs = 0;
  let week2 = new Date(new Date().setDate(today.getDate() - 21));
  let week2Subs = 0;
  let week3 = new Date(new Date().setDate(today.getDate() - 14));
  let week3Subs = 0;
  let week4 = new Date(new Date().setDate(today.getDate() - 7));
  let week4Subs = 0;
  var plans = {};
  // Get the points of each week of the past month.
  for (var i = 0; i < sales.length; i++) {
    var date = new Date(sales[i].timestamp * 1000);
    let planId = sales[i].plan;
    // Check if date is between week1 and 2
    if (date >= week1 && date < week2) {
      week1Subs += 1;
      if (plans[planId]) {
        if (plans[planId].week1) {
          plans[planId].week1 += 1;
        } else {
          plans[planId].week1 = 1;
        }
      } else {
        plans[planId] = {};
        plans[planId].week1 = 1;
      }
    }
    // If date is between week2 and 3
    else if (date >= week2 && date < week3) {
      week2Subs += 1;
      if (plans[planId]) {
        if (plans[planId].week2) {
          plans[planId].week2 += 1;
        } else {
          plans[planId].week2 = 1;
        }
      } else {
        plans[planId] = {};
        plans[planId].week2 = 1;
      }
    }
    // If date is between week3 and 4
    else if (date >= week3 && date < week4) {
      week3Subs += 1;
      if (plans[planId]) {
        if (plans[planId].week3) {
          plans[planId].week3 += 1;
        } else {
          plans[planId].week3 = 1;
        }
      } else {
        plans[planId] = {};
        plans[planId].week3 = 1;
      }
    }
    // If date is between week4 and today
    else if (date >= week4 && date <= today) {
      week4Subs += 1;
      if (plans[planId]) {
        if (plans[planId].week4) {
          plans[planId].week4 += 1;
        } else {
          plans[planId].week4 = 1;
        }
      } else {
        plans[planId] = {};
        plans[planId].week4 = 1;
      }
    }
  }
  $("#members2").text(sales.length);
  $("#members2").css("display", "inline");
  var parent = $("#membersChart").parent();
  $(parent).find(".shine").css("display", "none");
  let labels = [week1.toLocaleDateString(), week2.toLocaleDateString(), week3.toLocaleDateString(), week4.toLocaleDateString()];
  let values = [week1Subs, week2Subs, week3Subs, week4Subs];
  let dataset = [{
    label: "New Members",
    data: values,
    backgroundColor: 'rgba(255, 99, 132, 0.1)',
    borderColor: 'rgba(255, 99, 132, 1)',
    borderWidth: 2
  }];
  loadChart("membersChart", labels, dataset);
  // Create new dataset for new members per package
  let dataset2 = [];
  for (var plan in plans) {
    var planName;
    for (var i = 0; i < globalReleases.length; i++) {
      if (globalReleases[i].planId == plan) {
        planName = globalReleases[i].name;
      }
    }
    if (!planName) {
      planName = plan;
    }
    var planWeek1 = plans[plan].week1 ? plans[plan].week1 : 0;
    var planWeek2 = plans[plan].week2 ? plans[plan].week2 : 0;
    var planWeek3 = plans[plan].week3 ? plans[plan].week3 : 0;
    var planWeek4 = plans[plan].week4 ? plans[plan].week4 : 0;
    let values = [planWeek1, planWeek2, planWeek3, planWeek4];
    var data = {
      label: planName,
      data: values,
      backgroundColor: 'transparent',
      borderColor: getRandomColor(),
      borderWidth: 2
    }
    dataset2.push(data);

  }
  $("#memberPerProductProducts").text(`${dataset2.length} Product(s)`);
  $("#memberPerProductProducts").css("display", "inline");
  var parent = $("#memberPerProduct").parent();
  $(parent).find(".shine").css("display", "none");
  loadChart("memberPerProduct", labels, dataset2);
  window.localStorage.setItem("SUBSCRIPTIONS", JSON.stringify(sales));
}

function getRenewals() {
  $.ajax({
    url: "/api/analytics/renewals",
    type: "get",
    dataType: "json",
    success: function (data) {
      if (data.success) {
        setRenewals(data.renewals)
      }
    }
  });
}

const setRenewals = (renewals) => {
  $("#renewals").text(commaSeparateNumber(renewals.length));
  window.localStorage.setItem("RENEWALS", JSON.stringify(renewals));
}

function getRevenue() {
  $.ajax({
    url: "/api/analytics/revenue",
    type: "get",
    dataType: "json",
    success: function (data) {
      if (data.success) {
        setRevenue(data.sales);
      }
    }
  });
}

const setRevenue = (sales) => {
  // Get current date
  let today = new Date()
  let week1 = new Date(new Date().setDate(today.getDate() - 28));
  let week1Sales = 0;
  let week2 = new Date(new Date().setDate(today.getDate() - 21));
  let week2Sales = 0;
  let week3 = new Date(new Date().setDate(today.getDate() - 14));
  let week3Sales = 0;
  let week4 = new Date(new Date().setDate(today.getDate() - 7));
  let week4Sales = 0;
  let totalSales = 0;
  // Get the points of each week of the past month.
  for (var i = 0; i < sales.length; i++) {
    var date = new Date(sales[i].timestamp * 1000);
    totalSales += (sales[i].amount_captured - sales[i].amount_refunded);
    // Check if date is between week1 and 2
    if (date >= week1 && date < week2) {
      week1Sales += (sales[i].amount_captured - sales[i].amount_refunded);
    }
    // If date is between week2 and 3
    else if (date >= week2 && date < week3) {
      week2Sales += (sales[i].amount_captured - sales[i].amount_refunded);
    }
    // If date is between week3 and 4
    else if (date >= week3 && date < week4) {
      week3Sales += (sales[i].amount_captured - sales[i].amount_refunded);
    }
    // If date is between week4 and today
    else if (date >= week4 && date <= today) {
      week4Sales += (sales[i].amount_captured - sales[i].amount_refunded);
    }
  }
  $("#totalRevenue").text(`${currencies[currency].symbol.default.display}${commaSeparateNumber(totalSales.toFixed(2))}`);
  $("#totalRevenue").css("display", "inline");
  var parent = $("#totalRevenue").parent();
  $(parent).find(".shine").css("display", "none");
  let labels = [week1.toLocaleDateString(), week2.toLocaleDateString(), week3.toLocaleDateString(), week4.toLocaleDateString()];
  let values = [week1Sales.toFixed(2), week2Sales.toFixed(2), week3Sales.toFixed(2), week4Sales.toFixed(2)];
  // Load chart
  loadRevenue("revenue", "Sales", labels, values);
  window.localStorage.setItem("REVENUE", JSON.stringify(sales));

}

function loadRevenue(id, label, labels, values) {
  let parent = $(`#${id}`).parent();
  if (parent) {
    $(parent).find(".shine").css("display", "none");
    var ctx = document.getElementById(id).getContext('2d');
    revChart = new window.Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: label,
          data: values,
          backgroundColor: 'rgba(255, 99, 132, 0.1)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 2
        }]
      },
      options: {
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              return "$" + commaSeparateNumber(tooltipItem.value);
            }
          }
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: true,
              // Include a dollar sign in the ticks
              callback: function (value, index, values) {
                return '';
                // return '$' + commaSeparateNumber(value);
              }
            }
          }]
        },
        maintainAspectRatio: false
      }
    });
  }

}


function getRefunds() {
  $.ajax({
    url: "/api/analytics/refunds",
    type: "get",
    dataType: "json",
    success: function (data) {
      if (data.success) {
        // Get current date
        setRefunds(data.refunds)
      }
    }
  });
}

const setRefunds = (refunds) => {
  let today = new Date()
  let week1 = new Date(new Date().setDate(today.getDate() - 28));
  let week1Refunds = 0;
  let week2 = new Date(new Date().setDate(today.getDate() - 21));
  let week2Refunds = 0;
  let week3 = new Date(new Date().setDate(today.getDate() - 14));
  let week3Refunds = 0;
  let week4 = new Date(new Date().setDate(today.getDate() - 7));
  let week4Refunds = 0;
  let totalRefunds = 0;
  // Get the points of each week of the past month.
  for (var i = 0; i < refunds.length; i++) {
    var date = new Date(refunds[i].timestamp * 1000);
    totalRefunds += (refunds[i].amount_refunded);
    // Check if date is between week1 and 2
    if (date >= week1 && date < week2) {
      week1Refunds += (refunds[i].amount_refunded);
    }
    // If date is between week2 and 3
    else if (date >= week2 && date < week3) {
      week2Refunds += (refunds[i].amount_refunded);
    }
    // If date is between week3 and 4
    else if (date >= week3 && date < week4) {
      week3Refunds += (refunds[i].amount_refunded);
    }
    // If date is between week4 and today
    else if (date >= week4 && date <= today) {
      week4Refunds += (refunds[i].amount_refunded);
    }
  }
  $("#totalRefunds").text(`${currencies[currency].symbol.default.display}${commaSeparateNumber(totalRefunds.toFixed(2))}`);
  $("#totalRefunds").css("display", "inline");
  var parent = $("#totalRefunds").parent();
  $(parent).find(".shine").css("display", "none");
  let labels = [week1.toLocaleDateString(), week2.toLocaleDateString(), week3.toLocaleDateString(), week4.toLocaleDateString()];
  let values = [week1Refunds.toFixed(2), week2Refunds.toFixed(2), week3Refunds.toFixed(2), week4Refunds.toFixed(2)];
  // Load chart
  loadRevenue("refunds", "Refunds", labels, values);
  window.localStorage.setItem("REFUNDS", JSON.stringify(refunds));

}



function getCancels() {
  $.ajax({
    url: "/api/analytics/cancels",
    type: "get",
    dataType: "json",
    success: function (data) {
      if (data.success) {
        // Get current date
        setCancels(data.cancels)
      }
    }
  });
}

function getCancelReasons() {
  $.ajax({
    url: "/api/analytics/cancels/reasons",
    type: "get",
    dataType: "json",
    success: function (data) {
      if (data.success) {
        // Get current date
        setCancelReasons(data.cancels)
      }
    }
  });
}

const setCancels = (cancels) => {
  let today = new Date()
  let week1 = new Date(new Date().setDate(today.getDate() - 28));
  let week1Cancels = 0;
  let week2 = new Date(new Date().setDate(today.getDate() - 21));
  let week2Cancels = 0;
  let week3 = new Date(new Date().setDate(today.getDate() - 14));
  let week3Cancels = 0;
  let week4 = new Date(new Date().setDate(today.getDate() - 7));
  let week4Cancels = 0;
  // Get the points of each week of the past month.
  for (var i = 0; i < cancels.length; i++) {
    var date = new Date(cancels[i].timestamp * 1000);
    // Check if date is between week1 and 2
    if (date >= week1 && date < week2) {
      week1Cancels += 1;
    }
    // If date is between week2 and 3
    else if (date >= week2 && date < week3) {
      week2Cancels += 1;
    }
    // If date is between week3 and 4
    else if (date >= week3 && date < week4) {
      week3Cancels += 1;
    }
    // If date is between week4 and today
    else if (date >= week4 && date <= today) {
      week4Cancels += 1;
    }
  }
  $("#totalCancels").text(cancels.length);
  $("#totalCancels").css("display", "inline");
  var parent = $("#totalCancels").parent();
  $(parent).find(".shine").css("display", "none");
  let labels = [week1.toLocaleDateString(), week2.toLocaleDateString(), week3.toLocaleDateString(), week4.toLocaleDateString()];
  let values = [week1Cancels, week2Cancels, week3Cancels, week4Cancels];
  let dataset = [{
    label: "Cancellations",
    data: values,
    backgroundColor: 'rgba(255, 99, 132, 0.1)',
    borderColor: 'rgba(255, 99, 132, 1)',
    borderWidth: 2
  }]
  loadChart("cancels", labels, dataset)
  window.localStorage.setItem("CANCELS", JSON.stringify(cancels));
}


function loadChart(id, labels, dataset) {
  let parent = $(`#${id}`).parent();
  if (parent) {
    $(parent).find(".shine").css("display", "none");
    var ctx = document.getElementById(id).getContext('2d');
    if (id == "cancels") {
      cancelsChart = new window.Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: dataset
        },
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false
              }
            }],
            yAxes: [{
                gridLines: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  display: true,
                  beginAtZero: true,
                  callback: function (value) {
                    // if (value % 1 === 0) {return value;}
                    return '';
                  }
                }
              }

            ]
          },
          maintainAspectRatio: false
        }
      });
    } else if (id == "membersChart") {
      if (membersChart) {
        membersChart.destroy();
      }
      membersChart = new window.Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: dataset
        },
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false
              }
            }],
            yAxes: [{
                gridLines: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  display: true,
                  beginAtZero: true,
                  callback: function (value) {
                    // if (value % 1 === 0) {return value;}
                    return '';
                  }
                }
              }

            ]
          },
          maintainAspectRatio: false
        }
      });
    } else if (id == "memberPerProduct") {
      productsChart = new window.Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: dataset
        },
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false
              }
            }],
            yAxes: [{
                gridLines: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  display: true,
                  beginAtZero: true,
                  callback: function (value) {
                    // if (value % 1 === 0) {return value;}
                    return '';
                  }
                }
              }

            ]
          },
          maintainAspectRatio: false
        }
      });
    }
  }
}


const setCancelReasons = (cancels) => {
  let parent = $(`#cancelReasons`).parent();
  if (parent) {
    $(parent).find(".shine").css("display", "none");
    var ctx = document.getElementById("cancelReasons").getContext('2d');
    let labels = ['Price', "Competitor", "No time to use", "Other", "Custom"];
    let reasons = [0, 0, 0, 0, 0];
    var colors = ["#F55B6A", "#f7cd6a", "#409bf7", "#5bf599", "#5bf5e6"];
    for (var i = 0; i < cancels.length; i++) {
      if (cancels[i].reason == "Price") {
        reasons[0] += 1;
      } else if (cancels[i].reason == "Competitor") {
        reasons[1] += 1;
      } else if (cancels[i].reason == "No time to use") {
        reasons[2] += 1;
      } else if (cancels[i].reason == "Other") {
        reasons[3] += 1;
      } else {
        reasons[4] += 1;
      }
    }
    // And for a doughnut chart
    myDoughnutChart = new window.Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [{
          data: reasons,
          backgroundColor: colors
        }],
        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: labels
      },
      options: {
        legend: {
          display: true
        },
        maintainAspectRatio: false
      }
    });

  }
}