// Pull alerts from API
export const getAlerts = (setAlerts) => {
    fetch("/api/dashboard/alerts")
    .then(response => response.json())
    .then((data) => {
        if (data.success) {
            setAlerts(data.alerts);
        }
    });
}
