import React, { useEffect, useState, useRef } from "react";
import CircleLoader from "../../Components/CircleLoader"
import {getPosts, callVisibility, callDeletePost, callAddPost} from "../../Actions/Dashboard/posts";
import PostVideoModal from "./Modals/PostVideo";
import AddPostModal from "./Modals/AddPost";
import PageNavigation from "../PageNavigation/PageNavigation";
import $ from "jquery"

const Posts = ({ loading, setLoading, pullPosts, posts, setPosts, setOpenPost, setPage, data, display }) => {
    const [help, showHelp] = useState(false);

    useEffect(() => {
        getPosts(setPosts, setLoading, setPage, setOpenPost)
    }, [])



    return (
        <div id="posts" style={{display: display === true ? true : 'none'}}>
            <div className="container-fluid row">
                <div className="col-sm-12">
                    <h1 className="mt-4">Posts</h1>
                    <PageNavigation pages={[{title: "Content", active: true,  onclick: () => {} }, {title: "Catalogue", active: false,  onclick: () => {setPage('catalogues')} }]} />

                    <div className="user-corner-panel">

                    <svg style={{"verticalAlign":"middle","marginRight":"10px","cursor":"pointer"}} onClick={() => {showHelp(!help)}} width="30"
                        height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                        d="M14.8261 0C6.63787 0 0 6.63787 0 14.8261C0 23.0143 6.63787 29.6522 14.8261 29.6522C23.0143 29.6522 29.6522 23.0143 29.6522 14.8261C29.6522 6.63787 23.0143 0 14.8261 0ZM14.8261 2.69565C21.5255 2.69565 26.9565 8.12663 26.9565 14.8261C26.9565 21.5255 21.5255 26.9565 14.8261 26.9565C8.12663 26.9565 2.69565 21.5255 2.69565 14.8261C2.69565 8.12663 8.12663 2.69565 14.8261 2.69565Z"
                        fill="#808191" />
                        <path fillRule="evenodd" clipRule="evenodd"
                        d="M14.832 20.2174C15.5764 20.2174 16.1798 20.8208 16.1798 21.5652C16.1798 22.2564 15.6595 22.8261 14.9892 22.904L14.832 22.913C14.0817 22.913 13.4782 22.3096 13.4782 21.5652C13.4782 20.874 13.9986 20.3043 14.6689 20.2264L14.832 20.2174ZM18.4572 7.2585L18.6701 7.46842C20.4876 9.31629 20.4778 12.2194 18.73 14.0234L18.4132 14.332C17.9546 14.769 17.4886 15.1181 16.7977 15.6071C16.1485 16.0936 16.0071 16.3252 16.0071 17.05C16.0071 17.7944 15.4036 18.3978 14.6592 18.3978C13.9149 18.3978 13.3114 17.7944 13.3114 17.05C13.3114 15.3795 13.9064 14.4052 15.1813 13.4499L15.7904 13.0084C16.0136 12.8429 16.1825 12.7104 16.3343 12.5795L16.6725 12.2654C17.4784 11.5231 17.555 10.3359 16.883 9.50925L16.7483 9.35868L16.6144 9.2259C15.5484 8.22739 13.8659 8.20049 12.7665 9.12547L12.5889 9.28844L11.9297 9.94753C11.4033 10.4738 10.5499 10.4737 10.0236 9.94732C9.53775 9.4614 9.50046 8.69686 9.91164 8.16817L10.0238 8.0412L10.683 7.38212C12.8001 5.26548 16.2709 5.21072 18.4572 7.2585Z"
                        fill="#808191" />
                    </svg>
                    <div className="help-dropdown" style={{display: help === true ? 'block' : 'none'}}>
                        <ul>
                        <li><a target="_blank" href="https://ternarydev.notion.site/Ternary-4adf412dd4444fcb90c0a457d2a3607c">Helpful Articles</a>
                        </li>
                        <li><a target="_blank" href="https://docs.ternarydev.com/ternary-developments/change-log">Release Notes</a></li>
                        <li><a href="mailto:support@ternarydev.com">Contact Us</a></li>
                        </ul>
                    </div>


                    <a className="settings-button" onClick={() => {setPage('subscription')}}>
                        <svg style={{"verticalAlign":"middle","cursor":"pointer"}} width="30" height="30" viewBox="0 0 30 30"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM15 2.72729C21.7781 2.72729 27.2727 8.22198 27.2727 15C27.2727 21.7781 21.7781 27.2727 15 27.2727C8.22198 27.2727 2.72729 21.7781 2.72729 15C2.72729 8.22198 8.22198 2.72729 15 2.72729Z"
                            fill="#808191" />
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M14.9999 5.45459C10.4812 5.45459 6.81812 9.11771 6.81812 13.6364C6.81812 18.1551 10.4812 21.8182 14.9999 21.8182C19.5186 21.8182 23.1818 18.1551 23.1818 13.6364C23.1818 9.11771 19.5186 5.45459 14.9999 5.45459ZM14.9997 8.18188C18.0122 8.18188 20.4543 10.624 20.4543 13.6364C20.4543 16.6489 18.0122 19.091 14.9997 19.091C11.9873 19.091 9.54517 16.6489 9.54517 13.6364C9.54517 10.624 11.9873 8.18188 14.9997 8.18188Z"
                            fill="#808191" />
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M15.0066 19.0911C19.4226 19.0911 22.7977 20.6431 24.9792 23.743C25.4126 24.3589 25.2647 25.2095 24.6488 25.6429C24.0329 26.0764 23.1822 25.9284 22.7488 25.3125C21.1085 22.9817 18.5787 21.8183 15.0066 21.8183C11.4268 21.8183 8.89371 22.9866 7.25357 25.328C6.82148 25.9448 5.97116 26.0946 5.35433 25.6625C4.7375 25.2304 4.58774 24.3801 5.01983 23.7632C7.20065 20.65 10.5808 19.0911 15.0066 19.0911Z"
                            fill="#808191" />
                        </svg>
                    </a>

                    </div>
                </div>

      
                        <div className="input-group col-md-12">
                        <div className="col-md-12" style={{"marginTop":"30px"}}>
                        <div className="row">
                            <div className="col-lg-auto" style={{marginBottom:15}}>
                            <div onClick={() => {callAddPost()}} className="addUserButton tableAction" data-toggle="modal"
                                title="Add Product">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                    d="M8 1C8.59831 1 9.09142 1.45038 9.15882 2.03061L9.16667 2.16667V6.83333H13.8333C14.4777 6.83333 15 7.35567 15 8C15 8.59831 14.5496 9.09142 13.9694 9.15882L13.8333 9.16667H9.16667V13.8333C9.16667 14.4777 8.64433 15 8 15C7.40169 15 6.90857 14.5496 6.84118 13.9694L6.83333 13.8333V9.16667H2.16667C1.52233 9.16667 1 8.64433 1 8C1 7.40169 1.45038 6.90857 2.03061 6.84118L2.16667 6.83333H6.83333V2.16667C6.83333 1.52233 7.35567 1 8 1Z"
                                    fill="white" stroke="white" strokeWidth="0.5" />
                                </svg>
                            </div>
                            <div onClick={() => {callDeletePost(pullPosts)}} className="delUserButton tableAction" title="Delete Product">
                            <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0)">
                                <path d="M0.428571 0.874822H3.64286L3.89464 0.363494C3.94798 0.254177 4.03014 0.162222 4.13188 0.0979744C4.23362 0.0337264 4.3509 -0.000265901 4.47054 -0.000178311H7.53214C7.65151 -0.000646724 7.76858 0.0332188 7.86996 0.0975383C7.97134 0.161858 8.05292 0.25403 8.10536 0.363494L8.35714 0.874822H11.5714C11.6851 0.874822 11.7941 0.920915 11.8745 1.00296C11.9548 1.08501 12 1.19629 12 1.31232V2.18732C12 2.30335 11.9548 2.41463 11.8745 2.49668C11.7941 2.57873 11.6851 2.62482 11.5714 2.62482H0.428571C0.314907 2.62482 0.205898 2.57873 0.125525 2.49668C0.0451525 2.41463 -1.19209e-07 2.30335 -1.19209e-07 2.18732V1.31232C-1.19209e-07 1.19629 0.0451525 1.08501 0.125525 1.00296C0.205898 0.920915 0.314907 0.874822 0.428571 0.874822V0.874822ZM10.575 12.7694C10.5546 13.1026 10.4105 13.4153 10.1721 13.6439C9.93376 13.8725 9.61902 13.9998 9.29196 13.9998H2.70804C2.38098 13.9998 2.06624 13.8725 1.82787 13.6439C1.58951 13.4153 1.44544 13.1026 1.425 12.7694L0.857143 3.49982H11.1429L10.575 12.7694Z" fill="white"/>
                                </g>
                                <defs>
                                <clipPath id="clip0">
                                <rect width="12" height="14" fill="white" transform="matrix(-1 0 0 1 12 0)"/>
                                </clipPath>
                                </defs>
                            </svg>
                            </div>


                            <div onClick={() => {callVisibility(pullPosts)}} className="setVisibilityButton tableAction">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 18C6.48 18 2 12 2 12C2 12 6.48 6 12 6C17.52 6 22 12 22 12C22 12 17.52 18 12 18Z" fill="white"/>
                                <path d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z" fill="white"/>
                                <path d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z" stroke="#EEEEEE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                            </div>

                            
                            </div>
                            <div className="col-lg-auto">
                                <label id="search-bar-icon">
                                    <svg className="icon" width="21" height="22" viewBox="0 0 21 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                        d="M9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0ZM9 2C12.866 2 16 5.13401 16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2Z"
                                        fill="#808191" />
                                    <path fillRule="evenodd" clipRule="evenodd"
                                        d="M14.3596 14.2325C14.7838 13.8788 15.4143 13.9359 15.768 14.3601L20.7688 20.357C21.1225 20.7812 21.0654 21.4118 20.6412 21.7655C20.2171 22.1192 19.5865 22.0621 19.2328 21.6379L14.232 15.6409C13.8783 15.2168 13.9354 14.5862 14.3596 14.2325Z"
                                        fill="#808191" />
                                    </svg>
                                    <input id="post-search" className="form-control"
                                    style={{"width":"100%","margin":"auto"}} type="text" placeholder="Search" />
                                </label>
                            </div>
                        </div>
                        </div>
                        <div style={{marginTop:0}} className="col-md-12">
                            <div className="table-responsive">
                                <table className={"table releaseTable"} id="postTable">
                                    {
                                        loading === true ? (
                                            <CircleLoader />
                                        )
                                        : (
                                            <>
                                                <thead className="thead">
                                                <tr>
                                                <th style={{display: "inline-block", "verticalAlign": "center"}}>
                                                    <label style={{}} className="checkbox-container"><input style={{"marginTop":"0px !important"}} className="checkbox-input-all-posts" type="checkbox" /><span className="checkmark"></span></label>
                                                </th>
                                                <th>Post</th>
                                                <th>Date</th>
                                                <th>Visibility</th>
                                                <th>Type</th>
                                                <th>Catalogue(s)</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                        posts.map(post => {
                                                            return (
                                                                <tr visibility={post.visibility} id={post.postId} data-identify={JSON.stringify(post)}>
                                                                  
                                                                    <td style={{width:25}} className="checkbox-cell">
                                                                        <label className="checkbox-container">
                                                                            <input data-identify={JSON.stringify(post)} className="checkbox-input-post" type="checkbox" />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </td>
        
                                                                    <td style={{maxWidth:500, width:350}} className="clickable">
                                                                        <div style={{display:'flex', flexDirection:'row'}}>

                                                                            {post.type.includes("video") ? (
                                                                                <img style={{height:50, width:80, marginRight:15,borderRadius:3}} src={"https://i.imgur.com/Y4vgobN.png"}></img>
                                                                            ) : null}

                                                                            {post.type.includes("pdf") ? (
                                                                                <img style={{height:50, width:80, marginRight:15,borderRadius:3}} src={"https://i.imgur.com/E4n1krh.png"}></img>
                                                                            ) : null}
                                                                            
                                                                            {post.type.includes("image") ? (
                                                                                <img style={{objectFit: 'cover', height:50,width:80, marginRight:15,borderRadius:3}} src={`/api/dashboard/posts/media/${post.postId}`}></img>
                                                                            ) : null}

                                                                            <div style={{paddingTop:5, display: 'flex', flexDirection: 'column'}}>
                                                                                {post.title}
                                                                                <div style={{maxWidth:270,whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize:11, color: "#999"}}>
                                                                                    {post.description}
                                                                                </div>
                                                                            </div>
                                                                        </div>        
                                                                    </td>
                                                                    <td className="clickable">{(new Date(post.visibleOn)).toLocaleDateString()}</td>
                                                                    <td className="clickable">{post.visibility === "live" ? 
                                                                        <>
                                                                        <svg style={{color: "rgba(44, 216, 161, 1)", width:15, marginRight:5}}ariaHidden="true" focusable="false" dataPrefix="fas" dataIcon="eye" className="svg-inline--fa fa-eye fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path></svg>
                                                                            {"Visible"}
                                                                        </> 
                                                                        : 
                                                                        <>
                                                                        <svg style={{color: "rgba(245, 91, 106, 0.8)", width:15, marginRight:5}} ariaHidden="true" focusable="false" dataPrefix="fas" dataIcon="eye-slash" className="svg-inline--fa fa-eye-slash fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"></path></svg>
                                                                            {"Hidden"}
                                                                        </> 
                                                                        }</td>
                                                                    <td className="clickable">{post.premium === true ? "True" : "Free"}</td>
                                                                    <td>
                                                                        {
                                                                        post.catalogues ? 
                                                                            <div className="release-roles">
                                                                            {post.catalogues.map(catalogue => {
                                                                                return (
                                                                                    <div className="release-role">
                                                                                        {catalogue.title}
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div> : null}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </>
                                        )
                                    }

                                    
                                    
                                    
                                </table>
                            </div>
                        </div>
                    </div>
                    <AddPostModal pullPosts={pullPosts}/>
            </div>
                        
        </div>
    )
}


export default Posts
