import React, { useEffect, useState, useRef } from "react";
import { createCoupon } from "../../../Actions/Dashboard/coupons";
import $ from "jquery"

const CreateCouponModal = ({ releases, pullCoupons }) => {    
    
    const [name, setName] = useState("");
    const [useCode, setUseCode] = useState(false);
    const [code, setCode] = useState("");
    const [applyToProduct, setApplyToProduct] = useState(false);
    const [product, setProduct] = useState([]);
    const [useRedemption, setUseRedemption] = useState(false);
    const [redemptionLimit, setRedemptionLimit] = useState(0);
    const [useDate, setUseDate] = useState(false);
    const [date, setDate] = useState("");
    const [type, setType] = useState("percent");
    const [percent, setPercent] = useState(0);
    const [amount, setAmount] = useState(0);
    const [currency, setCurrency] = useState("USD");
    const [discountDuration, setDiscountDuration] = useState("forever");
    const [months, setMonths] = useState(1);

    useEffect(() => {
    }, [])


    useEffect(() => {
        console.log("Hey!", releases.length);
    }, [releases])

    const callSubmit = (event) => {
        event.preventDefault();
        var coupondatarange = "";
        if (useDate) {
            coupondatarange = date;
        }
        var form = {
            name: name,
            couponidcheck: useCode,
            couponid: code,
            couponidcheck: useCode,
            couponproductcheck: applyToProduct,
            couponproducts: product,
            couponlimitcheck: useRedemption,
            couponlimit: parseInt(redemptionLimit),
            coupondaterange: coupondatarange,
            percent: parseInt(percent),
            fixedamount: parseInt(amount),
            currency: currency,
            duration: discountDuration,
            months: parseInt(months),
            type: type
        };
        createCoupon(form, pullCoupons);
    }

    return (
        <div className="modal fade" id="addCouponModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title">Create a Coupon</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M1.6129 0.209705L1.70711 0.292893L8.07107 6.65607L14.435 0.292893C14.8256 -0.0976311 15.4587 -0.0976311 15.8492 0.292893C16.2097 0.653377 16.2375 1.22061 15.9324 1.6129L15.8492 1.70711L9.48607 8.07107L15.8492 14.435C16.2398 14.8256 16.2398 15.4587 15.8492 15.8492C15.4888 16.2097 14.9215 16.2375 14.5292 15.9324L14.435 15.8492L8.07107 9.48607L1.70711 15.8492C1.31658 16.2398 0.683418 16.2398 0.292893 15.8492C-0.0675907 15.4888 -0.0953203 14.9215 0.209705 14.5292L0.292893 14.435L6.65607 8.07107L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.623337 -0.0375504 1.12751 -0.0883879 1.5114 0.140381L1.6129 0.209705Z"
                            fill="black" />
                        </svg>
                    </button>
                    </div>
                    <form onSubmit={(event) => {callSubmit(event)}} id="coupon-add-form">
                    <div className="modal-body">


                        <div className="form-group">
                        <label for="addCouponName" className="col-form-label">Name:</label>
                        <input placeholder="1 Month free" type="text" name="name" className="form-control"
                            id="addCouponName" required value={name} onChange={(event) => {setName(event.target.value)}} />
                        </div>


                        <div className="form-group">
                        
                            <label className="checkbox-container modal-checkbox-container">
                                <input name="couponidcheck" id="addCouponId" type="checkbox" value={useCode} onChange={(event) => {setUseCode(event.target.checked)}} checked={useCode}/>
                                <span className="checkmark"></span>
                                <label for="addCouponIdValue" className="col-form-label">Custom Code:</label>
                            </label>

    
                            {useCode === true ? (
                                <div id="couponIdContainer" className="input-group mb-3">
                                    <input required placeholder="GRANDLAUNCH" type="text" name="couponid" className="form-control"
                                        id="addCouponIdValue" value={code} onChange={(event) => {setCode(event.target.value)}}/>
                               </div>
                            ) : null }

                        </div>

                        <div className="form-group">
                            
                            <label className="checkbox-container modal-checkbox-container">
                              <input name="couponproductcheck" id="addCouponProducts" type="checkbox" value={applyToProduct} onChange={(event) => {setApplyToProduct(event.target.checked)}} checked={applyToProduct} />
                              <span className="checkmark"></span>
                              <label className="col-form-label">Apply to Specific Products:</label>
                            </label>
                           
                            {applyToProduct === true ? (
                                <div id="couponProductsContainer" className="input-group mb-3">
                                    <select multiple                                    
                                    value={product} 
                                    onChange={(event) => {
                                        var values = [];
                                        var options = event.target.options;
                                        for (var i = 0; i < options.length;  i++) {
                                            if (options[i].selected) {
                                                values.push(options[i].value);
                                            }
                                        }
                                        setProduct(values);
                                    }}
                                    className="form-control" name="couponproducts" className="form-control"
                                    id="addCouponProductsValue" value={product}>
                                    {releases.map(release => {
                                        return (
                                            <>
                                            {
                                                release.stock > 0 ? (
                                                    <option value={release.productId}>{release.name}</option>
                                                ) : null
                                            }
                                            </>
                                        )
                                    })}
                                    </select>
                                    <small className="form-text text-muted">You can select multiple products by using control, cmd, or shift click.</small>
                                </div>
                            ) : null}
                        </div>

                <div className="form-group">
                    
                <label className="checkbox-container modal-checkbox-container">
                    <input name="couponlimitcheck" id="addCouponLimit" type="checkbox" value={useRedemption} onChange={(event) => {setUseRedemption(event.target.checked)}} checked={useRedemption} />
                    <span className="checkmark"></span>
                    <label className="col-form-label">Redemption Limit:</label>
                </label>

                {useRedemption === true ? (
                    <div id="couponLimitContainer" className="input-group mb-3">
                        <input required placeholder="3" type="number" min="0" name="couponlimit" className="form-control" id="addCouponLimitAmount" value={redemptionLimit} onChange={(event) => {setRedemptionLimit(event.target.value)}} />
                        <div className="input-group-addon">
                        <span className="input-group-text">Times</span>
                        </div>
                    </div>
                ) : null}
                </div>

                <div className="form-group">

                    <label className="checkbox-container modal-checkbox-container">
                        <input name="couponlimitcheck" id="addCouponDate" type="checkbox" value={useDate} onChange={(event) => {setUseDate(event.target.checked)}} checked={useDate} />
                        <span className="checkmark"></span>
                        <label for="addCouponDateRange" className="col-form-label">Redeem by (Date Limit):</label>
                    </label>

   
                    {useDate === true ? (
                        <div id="couponDateRangeContainer" className="input-group mb-3">
                            <input type="datetime-local" name="coupondaterange" className="form-control" id="addCouponDateRange" value={date} onChange={(event) => {setDate(event.target.value)}} />
                        </div>
                    ) : null}
                </div>

                <div className="form-group">
                    <label className="col-form-label">Type:</label>
                    <div className="custom-control custom-radio">
                        <input value={type === 'percent' ? true : false} onChange={() => {setType('percent')}} checked={type === 'percent' ? true : false} type="radio" id="couponPercentRadio" name="customRadio" className="custom-control-input" />
                        <label className="custom-control-label" for="couponPercentRadio">Percentage</label>
                    </div>
                    <div className="custom-control custom-radio">
                        <input value={type === 'amount' ? true : false} onChange={() => {setType('fixed')}} checked={type === 'fixed' ? true : false} type="radio" id="couponAmountRadio" name="customRadio" className="custom-control-input" />
                        <label className="custom-control-label" for="couponAmountRadio">Fixed Amount</label>
                    </div>
                </div>

                {type === 'percent' ? (
                    <div id="couponPercentContainer">
                        <div className="form-group">
                            <label for="addCouponPercent" className="col-form-label">Percentage off:</label>
                            <div className="input-group mb-3">
                                <div className="input-group-addon">
                                    <span className="input-group-text">%</span>
                                </div>
                                <input required placeholder="50" value="50" type="number" min="0" name="percent" className="form-control" id="addCouponPercent" value={percent} onChange={(event) => {setPercent(event.target.value)}} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div id="couponAmountContainer">
                        <div className="form-group">
                            <label for="addCouponAmount" className="col-form-label">Fix Amount:</label>
                            <div className="input-group mb-3">
                                <div className="input-group-addon">
                                    <span className="input-group-text">$</span>
                                </div>
                                <input value={amount} onChange={(event) => {setAmount(event.target.value)}} placeholder="50" step=".01" type="number" min="0" name="fixedamount" className="form-control" id="addCouponAmount" />
                            </div>
                        </div>
                    </div>
                )}

                <div className="form-group">
                    <label for="addCouponCurrency" className="col-form-label">Currency:</label>
                    <select id="addCouponCurrency" name="currency"  className="form-control select" value={currency} onChange={(event) => {setCurrency(event.target.value)}}>
                        <option value="USD" selected="selected" label="US dollar">USD</option>
                        <option value="CAD" label="Canadian dollar">CAD</option>
                        <option value="EUR" label="Euro">EUR</option>
                        <option value="JPY" label="Japanese yen">JPY</option>
                        <option value="GBP" label="Pound sterling">GBP</option>
                        <option value="AUD" label="Australian dollar">AUD</option>
                        <option disabled>──────────</option>
                        <option value="AED" label="United Arab Emirates dirham">AED</option>
                        <option value="AFN" label="Afghan afghani">AFN</option>
                        <option value="ALL" label="Albanian lek">ALL</option>
                        <option value="AMD" label="Armenian dram">AMD</option>
                        <option value="ANG" label="Netherlands Antillean guilder">ANG</option>
                        <option value="AOA" label="Angolan kwanza">AOA</option>
                        <option value="ARS" label="Argentine peso">ARS</option>
                        <option value="AWG" label="Aruban florin">AWG</option>
                        <option value="AZN" label="Azerbaijani manat">AZN</option>
                        <option value="BAM" label="Bosnia and Herzegovina convertible mark">BAM</option>
                        <option value="BBD" label="Barbadian dollar">BBD</option>
                        <option value="BDT" label="Bangladeshi taka">BDT</option>
                        <option value="BGN" label="Bulgarian lev">BGN</option>
                        <option value="BHD" label="Bahraini dinar">BHD</option>
                        <option value="BIF" label="Burundian franc">BIF</option>
                        <option value="BMD" label="Bermudian dollar">BMD</option>
                        <option value="BND" label="Brunei dollar">BND</option>
                        <option value="BOB" label="Bolivian boliviano">BOB</option>
                        <option value="BRL" label="Brazilian real">BRL</option>
                        <option value="BSD" label="Bahamian dollar">BSD</option>
                        <option value="BTN" label="Bhutanese ngultrum">BTN</option>
                        <option value="BWP" label="Botswana pula">BWP</option>
                        <option value="BYN" label="Belarusian ruble">BYN</option>
                        <option value="BZD" label="Belize dollar">BZD</option>
                        <option value="CDF" label="Congolese franc">CDF</option>
                        <option value="CHF" label="Swiss franc">CHF</option>
                        <option value="CLP" label="Chilean peso">CLP</option>
                        <option value="CNY" label="Chinese yuan">CNY</option>
                        <option value="COP" label="Colombian peso">COP</option>
                        <option value="CRC" label="Costa Rican colón">CRC</option>
                        <option value="CUC" label="Cuban convertible peso">CUC</option>
                        <option value="CUP" label="Cuban peso">CUP</option>
                        <option value="CVE" label="Cape Verdean escudo">CVE</option>
                        <option value="CZK" label="Czech koruna">CZK</option>
                        <option value="DJF" label="Djiboutian franc">DJF</option>
                        <option value="DKK" label="Danish krone">DKK</option>
                        <option value="DOP" label="Dominican peso">DOP</option>
                        <option value="DZD" label="Algerian dinar">DZD</option>
                        <option value="EGP" label="Egyptian pound">EGP</option>
                        <option value="ERN" label="Eritrean nakfa">ERN</option>
                        <option value="ETB" label="Ethiopian birr">ETB</option>
                        <option value="EUR" label="EURO">EUR</option>
                        <option value="FJD" label="Fijian dollar">FJD</option>
                        <option value="FKP" label="Falkland Islands pound">FKP</option>
                        <option value="GBP" label="British pound">GBP</option>
                        <option value="GEL" label="Georgian lari">GEL</option>
                        <option value="GGP" label="Guernsey pound">GGP</option>
                        <option value="GHS" label="Ghanaian cedi">GHS</option>
                        <option value="GIP" label="Gibraltar pound">GIP</option>
                        <option value="GMD" label="Gambian dalasi">GMD</option>
                        <option value="GNF" label="Guinean franc">GNF</option>
                        <option value="GTQ" label="Guatemalan quetzal">GTQ</option>
                        <option value="GYD" label="Guyanese dollar">GYD</option>
                        <option value="HKD" label="Hong Kong dollar">HKD</option>
                        <option value="HNL" label="Honduran lempira">HNL</option>
                        <option value="HKD" label="Hong Kong dollar">HKD</option>
                        <option value="HRK" label="Croatian kuna">HRK</option>
                        <option value="HTG" label="Haitian gourde">HTG</option>
                        <option value="HUF" label="Hungarian forint">HUF</option>
                        <option value="IDR" label="Indonesian rupiah">IDR</option>
                        <option value="ILS" label="Israeli new shekel">ILS</option>
                        <option value="IMP" label="Manx pound">IMP</option>
                        <option value="INR" label="Indian rupee">INR</option>
                        <option value="IQD" label="Iraqi dinar">IQD</option>
                        <option value="IRR" label="Iranian rial">IRR</option>
                        <option value="ISK" label="Icelandic króna">ISK</option>
                        <option value="JEP" label="Jersey pound">JEP</option>
                        <option value="JMD" label="Jamaican dollar">JMD</option>
                        <option value="JOD" label="Jordanian dinar">JOD</option>
                        <option value="JPY" label="Japanese yen">JPY</option>
                        <option value="KES" label="Kenyan shilling">KES</option>
                        <option value="KGS" label="Kyrgyzstani som">KGS</option>
                        <option value="KHR" label="Cambodian riel">KHR</option>
                        <option value="KID" label="Kiribati dollar">KID</option>
                        <option value="KMF" label="Comorian franc">KMF</option>
                        <option value="KPW" label="North Korean won">KPW</option>
                        <option value="KRW" label="South Korean won">KRW</option>
                        <option value="KWD" label="Kuwaiti dinar">KWD</option>
                        <option value="KYD" label="Cayman Islands dollar">KYD</option>
                        <option value="KZT" label="Kazakhstani tenge">KZT</option>
                        <option value="LAK" label="Lao kip">LAK</option>
                        <option value="LBP" label="Lebanese pound">LBP</option>
                        <option value="LKR" label="Sri Lankan rupee">LKR</option>
                        <option value="LRD" label="Liberian dollar">LRD</option>
                        <option value="LSL" label="Lesotho loti">LSL</option>
                        <option value="LYD" label="Libyan dinar">LYD</option>
                        <option value="MAD" label="Moroccan dirham">MAD</option>
                        <option value="MDL" label="Moldovan leu">MDL</option>
                        <option value="MGA" label="Malagasy ariary">MGA</option>
                        <option value="MKD" label="Macedonian denar">MKD</option>
                        <option value="MMK" label="Burmese kyat">MMK</option>
                        <option value="MNT" label="Mongolian tögrög">MNT</option>
                        <option value="MOP" label="Macanese pataca">MOP</option>
                        <option value="MRU" label="Mauritanian ouguiya">MRU</option>
                        <option value="MUR" label="Mauritian rupee">MUR</option>
                        <option value="MVR" label="Maldivian rufiyaa">MVR</option>
                        <option value="MWK" label="Malawian kwacha">MWK</option>
                        <option value="MXN" label="Mexican peso">MXN</option>
                        <option value="MYR" label="Malaysian ringgit">MYR</option>
                        <option value="MZN" label="Mozambican metical">MZN</option>
                        <option value="NAD" label="Namibian dollar">NAD</option>
                        <option value="NGN" label="Nigerian naira">NGN</option>
                        <option value="NIO" label="Nicaraguan córdoba">NIO</option>
                        <option value="NOK" label="Norwegian krone">NOK</option>
                        <option value="NPR" label="Nepalese rupee">NPR</option>
                        <option value="NZD" label="New Zealand dollar">NZD</option>
                        <option value="OMR" label="Omani rial">OMR</option>
                        <option value="PAB" label="Panamanian balboa">PAB</option>
                        <option value="PEN" label="Peruvian sol">PEN</option>
                        <option value="PGK" label="Papua New Guinean kina">PGK</option>
                        <option value="PHP" label="Philippine peso">PHP</option>
                        <option value="PKR" label="Pakistani rupee">PKR</option>
                        <option value="PLN" label="Polish złoty">PLN</option>
                        <option value="PRB" label="Transnistrian ruble">PRB</option>
                        <option value="PYG" label="Paraguayan guaraní">PYG</option>
                        <option value="QAR" label="Qatari riyal">QAR</option>
                        <option value="RON" label="Romanian leu">RON</option>
                        <option value="RON" label="Romanian leu">RON</option>
                        <option value="RSD" label="Serbian dinar">RSD</option>
                        <option value="RUB" label="Russian ruble">RUB</option>
                        <option value="RWF" label="Rwandan franc">RWF</option>
                        <option value="SAR" label="Saudi riyal">SAR</option>
                        <option value="SEK" label="Swedish krona">SEK</option>
                        <option value="SGD" label="Singapore dollar">SGD</option>
                        <option value="SHP" label="Saint Helena pound">SHP</option>
                        <option value="SLL" label="Sierra Leonean leone">SLL</option>
                        <option value="SLS" label="Somaliland shilling">SLS</option>
                        <option value="SOS" label="Somali shilling">SOS</option>
                        <option value="SRD" label="Surinamese dollar">SRD</option>
                        <option value="SSP" label="South Sudanese pound">SSP</option>
                        <option value="STN" label="São Tomé and Príncipe dobra">STN</option>
                        <option value="SYP" label="Syrian pound">SYP</option>
                        <option value="SZL" label="Swazi lilangeni">SZL</option>
                        <option value="THB" label="Thai baht">THB</option>
                        <option value="TJS" label="Tajikistani somoni">TJS</option>
                        <option value="TMT" label="Turkmenistan manat">TMT</option>
                        <option value="TND" label="Tunisian dinar">TND</option>
                        <option value="TOP" label="Tongan paʻanga">TOP</option>
                        <option value="TRY" label="Turkish lira">TRY</option>
                        <option value="TTD" label="Trinidad and Tobago dollar">TTD</option>
                        <option value="TVD" label="Tuvaluan dollar">TVD</option>
                        <option value="TWD" label="New Taiwan dollar">TWD</option>
                        <option value="TZS" label="Tanzanian shilling">TZS</option>
                        <option value="UAH" label="Ukrainian hryvnia">UAH</option>
                        <option value="UGX" label="Ugandan shilling">UGX</option>
                        <option value="USD" label="United States dollar">USD</option>
                        <option value="UYU" label="Uruguayan peso">UYU</option>
                        <option value="UZS" label="Uzbekistani soʻm">UZS</option>
                        <option value="VES" label="Venezuelan bolívar soberano">VES</option>
                        <option value="VND" label="Vietnamese đồng">VND</option>
                        <option value="VUV" label="Vanuatu vatu">VUV</option>
                        <option value="WST" label="Samoan tālā">WST</option>
                        <option value="XAF" label="Central African CFA franc">XAF</option>
                        <option value="XCD" label="Eastern Caribbean dollar">XCD</option>
                        <option value="XOF" label="West African CFA franc">XOF</option>
                        <option value="XPF" label="CFP franc">XPF</option>
                        <option value="ZAR" label="South African rand">ZAR</option>
                        <option value="ZMW" label="Zambian kwacha">ZMW</option>
                        <option value="ZWB" label="Zimbabwean bonds">ZWB</option>
                    </select>
                </div>

                <div className="form-group">
                    <label for="addCouponAmount" className="col-form-label">Discount Duration:</label>
                    <select className="form-control select" name="duration" className="form-control" id="addCouponDuration" value={discountDuration} onChange={(event) => {setDiscountDuration(event.target.value)}}>
                        <option value="forever">
                        Forever
                        </option>

                        <option value="once">
                        One Time
                        </option>

                        <option value="months">
                        Multiple Months
                        </option>
                    </select>
                </div>
                
                {discountDuration === 'months' ? (
                    <div id="couponMonths" className="form-group">
                        <label for="addCouponMonths" className="col-form-label">Number of Months:</label>
                        <input placeholder="1" value="1" type="number" min="0" name="months" className="form-control" id="addCouponMonths" value={months} onChange={(event) => {setMonths(event.target.value)}} />
                    </div>
                ) : null}
                <div className="alert alert-danger" id="couponAddErr" role="alert"></div>
                </div>
                <div className="modal-footer">
                    <button type="submit" className="btn btn-main-type" style={{"display":"block","margin":"0 auto","float":"none"}}>Add Coupon</button>
                </div>
                </form>
            </div>
            </div>
        </div>
    )

}

export default CreateCouponModal
