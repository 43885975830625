import React, { useEffect, useState, useRef } from "react";
import PageNavigation from "../PageNavigation/PageNavigation";
import {connectStripe, getTaxRates, submitStripe} from "../../Actions/Dashboard/stripe";

const Stripe = ({ setPage, data, pullData, coupons, display }) => {
    const [help, showHelp] = useState(false);
    const [taxRates, setTaxRates] = useState([]);
    const [taxRateId, setTaxRateId] = useState("");
    const [taxRateRate, setTaxRateRate] = useState("");
    const [taxRateName, setTaxRateName] = useState("");
    const [inclusive, setInclusive] = useState("");
    const [referralDiscount, setReferralDiscount] = useState(data.stripe.referral_discount);
    const [requireBilling, setRequireBilling] = useState(data.stripe.require_billing);
    const [requireLogin, setRequireLogin] = useState(data.stripe.require_login ? data.stripe.require_login : false);
    const [enableDonations, setEnableDonations] = useState(data.stripe.enable_donations ? true : false);

    const pullTaxRates = () => {
        getTaxRates(setTaxRates, setTaxRateId);
    }

    const callSubmitStripe = (event) => {
        event.preventDefault();
        var form = {
            tax: taxRateId,
            inclusive: inclusive,
            coupon: referralDiscount,
            requireBilling: requireBilling,
            requireLogin: requireLogin,
            enableDonations: enableDonations,
            rate: taxRateRate,
            display: taxRateName
        }
        console.log(referralDiscount)
        submitStripe(form, pullData, pullTaxRates);
    }

    useEffect(() => {
        pullTaxRates();
    }, [])


    useEffect(() => {
        if (taxRateId && taxRateId !== "" && taxRateId !== "new") {
            for (var i = 0; i < taxRates.length; i++) {
                if (taxRates[i].id === taxRateId) {
                    setTaxRateRate(taxRates[i].percentage);
                    setInclusive(taxRates[i].inclusive);
                    setTaxRateName(taxRates[i].display_name);
                }
            }
        } else {
            setTaxRateRate("");
            setInclusive("");
            setTaxRateName("");
        }
    }, [taxRateId])


    return (
        <div id="stripe" style={{display: display === true ? true : 'none'}}>
            <div className="container-fluid row">
                <div className="col-md-12">
                    <h1 className="mt-4">Connections</h1>
                    <PageNavigation pages={[{title: "Discord", active: false,  onclick: () => {setPage("discord")} }, {title: "Payment Gateways", active: true, onclick: () => {}}, {title: "Tracking Pixels", active: false, onclick: () => {setPage("pixels")}}]} />

                        <div className="user-corner-panel">

                            <svg style={{"verticalAlign":"middle","marginRight":"10px","cursor":"pointer"}} onClick={() => {showHelp(!help)}} width="30"
                                height="30" viewdiv="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                d="M14.8261 0C6.63787 0 0 6.63787 0 14.8261C0 23.0143 6.63787 29.6522 14.8261 29.6522C23.0143 29.6522 29.6522 23.0143 29.6522 14.8261C29.6522 6.63787 23.0143 0 14.8261 0ZM14.8261 2.69565C21.5255 2.69565 26.9565 8.12663 26.9565 14.8261C26.9565 21.5255 21.5255 26.9565 14.8261 26.9565C8.12663 26.9565 2.69565 21.5255 2.69565 14.8261C2.69565 8.12663 8.12663 2.69565 14.8261 2.69565Z"
                                fill="#808191" />
                                <path fillRule="evenodd" clipRule="evenodd"
                                d="M14.832 20.2174C15.5764 20.2174 16.1798 20.8208 16.1798 21.5652C16.1798 22.2564 15.6595 22.8261 14.9892 22.904L14.832 22.913C14.0817 22.913 13.4782 22.3096 13.4782 21.5652C13.4782 20.874 13.9986 20.3043 14.6689 20.2264L14.832 20.2174ZM18.4572 7.2585L18.6701 7.46842C20.4876 9.31629 20.4778 12.2194 18.73 14.0234L18.4132 14.332C17.9546 14.769 17.4886 15.1181 16.7977 15.6071C16.1485 16.0936 16.0071 16.3252 16.0071 17.05C16.0071 17.7944 15.4036 18.3978 14.6592 18.3978C13.9149 18.3978 13.3114 17.7944 13.3114 17.05C13.3114 15.3795 13.9064 14.4052 15.1813 13.4499L15.7904 13.0084C16.0136 12.8429 16.1825 12.7104 16.3343 12.5795L16.6725 12.2654C17.4784 11.5231 17.555 10.3359 16.883 9.50925L16.7483 9.35868L16.6144 9.2259C15.5484 8.22739 13.8659 8.20049 12.7665 9.12547L12.5889 9.28844L11.9297 9.94753C11.4033 10.4738 10.5499 10.4737 10.0236 9.94732C9.53775 9.4614 9.50046 8.69686 9.91164 8.16817L10.0238 8.0412L10.683 7.38212C12.8001 5.26548 16.2709 5.21072 18.4572 7.2585Z"
                                fill="#808191" />
                            </svg>
                            <div className="help-dropdown" style={{display: help === true ? 'block' : 'none'}}>
                                <ul>
                                <li><a target="_blank" href="https://ternarydev.notion.site/Ternary-4adf412dd4444fcb90c0a457d2a3607c">Helpful Articles</a>
                                </li>
                                <li><a target="_blank" href="https://docs.ternarydev.com/ternary-developments/change-log">Release Notes</a></li>
                                <li><a href="mailto:support@ternarydev.com">Contact Us</a></li>
                                </ul>
                            </div>


                            <a className="settings-button" onClick={() => {setPage('subscription')}}>
                                <svg style={{"verticalAlign":"middle","cursor":"pointer"}} width="30" height="30" viewdiv="0 0 30 30"
                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                    d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM15 2.72729C21.7781 2.72729 27.2727 8.22198 27.2727 15C27.2727 21.7781 21.7781 27.2727 15 27.2727C8.22198 27.2727 2.72729 21.7781 2.72729 15C2.72729 8.22198 8.22198 2.72729 15 2.72729Z"
                                    fill="#808191" />
                                <path fillRule="evenodd" clipRule="evenodd"
                                    d="M14.9999 5.45459C10.4812 5.45459 6.81812 9.11771 6.81812 13.6364C6.81812 18.1551 10.4812 21.8182 14.9999 21.8182C19.5186 21.8182 23.1818 18.1551 23.1818 13.6364C23.1818 9.11771 19.5186 5.45459 14.9999 5.45459ZM14.9997 8.18188C18.0122 8.18188 20.4543 10.624 20.4543 13.6364C20.4543 16.6489 18.0122 19.091 14.9997 19.091C11.9873 19.091 9.54517 16.6489 9.54517 13.6364C9.54517 10.624 11.9873 8.18188 14.9997 8.18188Z"
                                    fill="#808191" />
                                <path fillRule="evenodd" clipRule="evenodd"
                                    d="M15.0066 19.0911C19.4226 19.0911 22.7977 20.6431 24.9792 23.743C25.4126 24.3589 25.2647 25.2095 24.6488 25.6429C24.0329 26.0764 23.1822 25.9284 22.7488 25.3125C21.1085 22.9817 18.5787 21.8183 15.0066 21.8183C11.4268 21.8183 8.89371 22.9866 7.25357 25.328C6.82148 25.9448 5.97116 26.0946 5.35433 25.6625C4.7375 25.2304 4.58774 24.3801 5.01983 23.7632C7.20065 20.65 10.5808 19.0911 15.0066 19.0911Z"
                                    fill="#808191" />
                                </svg>
                            </a>
                        </div>
                </div>
                {data.stripe.connected === true || data.authorizeNet ? (
                    <>
                        {
                            data.stripe.connected ? (
                                <>
                                 <form onSubmit={(event) => {callSubmitStripe(event)}} id="stripe-settings-form">
                                    <div className="form-row">
                                        <div className="form-group">
                                            <h2 className="mt-4" style={{"fontSize":"23px","marginBottom":"35px"}}>Payment Settings</h2>
                                            <label for="tax">Tax:</label>
                                            <select id="tax" className="select" value={taxRateId} onChange={(event) => {setTaxRateId(event.target.value)}}>
                                                <option value="">No Tax</option>
                                                <option value="new">New Tax Rate</option>
                                                {taxRates.map(taxRate => {
                                                    return (
                                                        <option value={taxRate.id}>{taxRate.display_name}</option>
                                                    )
                                                    
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    

                                    {
                                        taxRateId !== '' ? (
                                            <>
                                                <div className="form-row tax-rate">
                                                    <div className="form-group">
                                                        <label for="taxRate">Tax Rate:</label>
                                                        <div className="input-group mb-3">
                                                            <div className="input-group-addon">
                                                                <span className="input-group-text">%</span>
                                                            </div>
                                                            <input value={taxRateRate} onChange={(event) => {setTaxRateRate(event.target.value)}} placeholder="13" step=".01"
                                                                type="number" min="0" name="taxRate" className="form-control" id="taxRate" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row tax-rate">
                                                    <div className="form-group">
                                                    <label for="taxDisplay">Tax Display Name:</label>
                                                    <input value={taxRateName} onChange={(event) => {setTaxRateName(event.target.value)}} placeholder="HST" type="text"
                                                        name="displayName" className="form-control" id="taxDisplay" />
                                                    </div>
                                                </div>

                                                <div className="form-row tax-rate">
                                                    <div className="form-group">
                                                        <label for="taxInclusive">Tax Inclusive:</label>
                                                        <select value={inclusive} onChange={(event) => {setInclusive(event.target.value)}} name="taxInclusive" id="taxInclusive" className="select">
                                                                <option value="true">Inclusive</option>
                                                                <option value="false">Exclusive</option>
                                                        </select>
                                                        <small className="form-text text-muted">Tax rates can either be exclusive or
                                                        inclusive. An exclusive tax is not included in the invoice subtotal, whereas
                                                        an inclusive tax is. <a
                                                            href="https://stripe.com/docs/billing/taxes/tax-rates#inclusive-vs-exclusive-tax"
                                                            target="_blank">More info here</a></small>
                                                    </div>
                                                </div>
                                            </>
                                        ) : null
                                    }
                                    

                                    <div className="form-row">
                                        <div className="form-group">
                                            <label for="referralDiscount">Coupon Applied to Referrals:</label>
                                            <select value={referralDiscount} onChange={(event) => {setReferralDiscount(event.target.value)}} className="select" id="referralDiscount" name="coupon">
                                                <option value="">No Discount</option>
                                                {coupons.map(coupon => {
                                                    return (
                                                        <option value={coupon.id}>{coupon.name}</option>
                                                    )
                                                })}
                                            </select>
                                            <small className="form-text text-muted">Create coupon on the coupon page</small>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group">
                                            <label for="requireBilling">Require Billing Address:</label>
                                            <div>
                                                <label className="switch">
                                                    <input name="requireBilling" id="requireBilling" value={requireBilling} checked={requireBilling} onChange={(event) => {setRequireBilling(event.target.checked)}} type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>


                                    <div style={{marginTop:-30}} className="form-row">
                                        <div className="form-group">
                                            <label for="requireLogin">Require Login for Purchase:</label>
                                            <div>
                                                <label className="switch">
                                                    <input name="requireLogin" id="requireLogin" value={requireLogin} checked={requireLogin} onChange={(event) => {setRequireLogin(event.target.checked)}} type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{marginTop:-30}} className="form-row">
                                        <div className="form-group">
                                            <label for="requireLogin">Enable Donations:</label>
                                            <div>
                                                <label className="switch">
                                                    <input name="enableDonations" id="enableDonations" value={enableDonations} checked={enableDonations} onChange={(event) => {setEnableDonations(event.target.checked)}} type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            {enableDonations ? (
                                                    <small style={{marginTop:-30}} className="form-text text-muted">You can access your donations page here <a href={`${data.domain}/donate`} target="_blank">{data.domain}/donate</a></small>
                                                ) : null}
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary">Save</button>
                                        </div>
                                    </div>
                                </form>
                                </>
                            ) : (
                                <form onSubmit={(event) => {callSubmitStripe(event)}} id="stripe-settings-form">

                                    <div style={{marginTop:30}} className="form-row">
                                        <div className="form-group">
                                            <label for="requireBilling">Require Billing Address:</label>
                                            <div>
                                                <label className="switch">
                                                    <input name="requireBilling" id="requireBilling" value={requireBilling} checked={requireBilling} onChange={(event) => {setRequireBilling(event.target.checked)}} type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>


                                    <div style={{marginTop:-30}} className="form-row">
                                        <div className="form-group">
                                            <label for="requireLogin">Require Login for Purchase:</label>
                                            <div>
                                                <label className="switch">
                                                    <input name="requireLogin" id="requireLogin" value={requireLogin} checked={requireLogin} onChange={(event) => {setRequireLogin(event.target.checked)}} type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary">Save</button>
                                        </div>
                                    </div>
                                </form>
                            )
                        }
                       
                    </>
                    ) : (
                        <form id="stripe-form">
                            <div className="form-row">
                                <div className="col-sm-12">
                                <div className="row">
                                    <div className="d-flex justify-content-center">
                                    <div className="addProductexisting">
                                        <div className="row container-fluid"
                                        style={{"paddingLeft":"0px !important","paddingRight":"0px !important","marginLeft":"-0px !important","marginRight":"0px !important"}}>
                                        <div className="col-md-12 d-flex justify-content-center"
                                            style={{"marginBottom":"30px"}}>
                                            <svg width="39" height="38" viewBox="0 0 39 38" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                d="M36.434 6.90796H1.89388C0.940078 6.90796 0.16687 7.68117 0.16687 8.63497V32.8131C0.16687 35.6561 2.3935 37.9941 5.18342 37.9941H33.1445C35.9344 37.9941 38.161 35.6561 38.161 32.8131V8.63497C38.161 7.68117 37.3878 6.90796 36.434 6.90796ZM3.62089 10.362H34.707V32.8131C34.707 33.7852 33.9881 34.5401 33.1445 34.5401H5.18342L5.00477 34.5287C4.24078 34.4311 3.62089 33.7158 3.62089 32.8131V10.362Z"
                                                fill="#333333" />
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                d="M32.3267 0H6.00119C5.16133 0 4.39941 0.444892 3.97157 1.14791L0.373735 7.8154C-0.246673 8.96601 0.58663 10.362 1.89385 10.362H36.434C37.7412 10.362 38.5745 8.96601 37.9541 7.8154L34.4352 1.28923C34.08 0.538319 33.2505 0 32.3267 0ZM31.6796 3.454L33.5413 6.90802H4.78659L6.6483 3.454H31.6796Z"
                                                fill="#333333" />
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                d="M19.1644 13C20.0036 13 20.6953 13.6668 20.7898 14.5258L20.8008 14.7273V20.7727H26.5281C27.4318 20.7727 28.1644 21.5461 28.1644 22.5C28.1644 23.3858 27.5327 24.1159 26.7189 24.2157L26.5281 24.2273H20.8008V30.2727C20.8008 31.2267 20.0682 32 19.1644 32C18.3252 32 17.6336 31.3332 17.5391 30.4742L17.5281 30.2727V24.2273H11.8008C10.8971 24.2273 10.1644 23.4539 10.1644 22.5C10.1644 21.6142 10.7961 20.8841 11.61 20.7843L11.8008 20.7727H17.5281V14.7273C17.5281 13.7733 18.2607 13 19.1644 13Z"
                                                fill="#333333" />
                                            </svg>
                                        </div>
                                        <div className="col-md-12 d-flex justify-content-center">
                                            <button style={{"marginTop":"-10px"}} type="button"
                                            onClick={() => {connectStripe()}}
                                            className="btn btn-primary">Connect stripe</button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </form>
                    )
                }
            </div>
        </div>
    )
}


export default Stripe
