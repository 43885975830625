import bootbox from "bootbox";
import $ from "jquery"

export const getOrganization = (setData) => {
  $.get("/dashboard/info",
    (data, status) => {
      if (status == "success") {
        if (data.success == true) {
          setData(data);
          CreateSidebar();
          return;
        } else {
          return window.location.href = "/logout";
        }
      } else {
        return window.location.href = "/logout";
      }
    });
}


// Function to download CSV 
const exportCSVFile = (headers, items, fileTitle) => {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);

  var csv = convertToCSV(jsonObject);

  var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

  var blob = new Blob([csv], {
    type: 'text/csv;charset=utf-8;'
  });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

function convertToCSV(objArray) {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';

  for (var i = 0; i < array.length; i++) {
    var line = '';
    for (var index in array[i]) {
      if (line != '') line += ','

      line += array[i][index];
    }

    str += line + '\r\n';
  }

  return str;
}


// Function to export table to CSV file
export const exportTableToCSV = (filename, allOptions) => {
  try {
    if (filename == "Users") {
      var selectedUsers = document.getElementsByClassName("checkbox-input");
      var allSelected = document.getElementsByClassName("checkbox-input-all-users")[0];
      let usersArr = [];
      let headers = ["Name", "Customer Email", "Customer ID", "Discord ID", "Discord Username", "Key", "Referral Code", "Total Referrals"]
      if (allSelected.checked == true) {
        for (var i = 0; i < allOptions.length; i++) {
          let user = allOptions[i];
          var userObject = {
            "name": user.firstName ? `${user.firstName} ${user.lastName}` : "n/a",
            "customerEmail": user.customerEmail,
            "customerId": user.customerId,
            "discordId": user.discordId,
            "discordUsername": user.discordUsername ? user.discordUsername : "n/a",
            "key": user.key,
            "referral": user.referral,
            "totalReferrals": user.totalReferrals
          };
          // for (var j = 0; j < user.subscriptions.length; j++) {
          //   headers.push(`${user.subscriptions[j].id} Plan`)
          //   headers.push(`${user.subscriptions[j].id} Roles`)
          //   userObject[`${user.subscriptions[j].id}_Plan`] = user.subscriptions[j].plan;
          //   if (user.subscriptions[j].discordRoles) {
          //     userObject[`${user.subscriptions[j].id}_Roles`] = JSON.stringify(user.subscriptions[j].discordRoles.filter(Boolean))
          //   } else {
          //     userObject[`${user.subscriptions[j].id}_Roles`] = JSON.stringify([])
          //   }
          // }
          usersArr.push(userObject);  
        }
      } else {
        for (var i = 0; i < selectedUsers.length; i++) {
          if (selectedUsers[i].checked == true) {
            let row = selectedUsers[i].parentNode.parentNode.parentNode;
            let user = JSON.parse(row.getAttribute("data-identify"));
            var userObject = {
              "name": user.firstName ? `${user.firstName} ${user.lastName}` : "n/a",
              "customerEmail": user.customerEmail,
              "customerId": user.customerId,
              "discordId": user.discordId,
              "discordUsername": user.discordUsername ? user.discordUsername : "n/a",
              "key": user.key,
              "referral": user.referral,
              "totalReferrals": user.totalReferrals
            };
            // for (var j = 0; j < user.subscriptions.length; j++) {
            //   headers.push(`${user.subscriptions[j].id} Plan`)
            //   headers.push(`${user.subscriptions[j].id} Roles`)
            //   userObject[`${user.subscriptions[j].id}_Plan`] = user.subscriptions[j].plan;
            //   userObject[`${user.subscriptions[j].id}_Roles`] = JSON.stringify(user.subscriptions[j].discordRoles.filter(Boolean))
            // }
            usersArr.push(userObject);
          }
        }
      }  
      if (usersArr.length > 0) {
        return exportCSVFile(headers, usersArr, filename);
      } else {
        return bootbox.alert("Please select user(s) to export");
      }
    } else if (filename == "Products") {
      var selectedReleases = document.getElementsByClassName("checkbox-input-release");
      let releaseArr = [];
      let headers = [
        "Add Ons",
        "Currency",
        "Interval",
        "Interval Count",
        "Name",
        "Password Slug",
        "Plan ID",
        "Price",
        "Product ID",
        "Role Removal",
        "Roles",
        "Stock",
        "Upgrades"
      ]
      for (var i = 0; i < selectedReleases.length; i++) {
        if (selectedReleases[i].checked == true) {
          let row = selectedReleases[i].parentNode.parentNode.parentNode;
          let release = JSON.parse(row.getAttribute("data-identify"));
          releaseArr.push({
            "Add Ons": JSON.stringify(release.addOns.filter(Boolean)),
            "Currency": release.currency,
            "Interval": release.interval,
            "Interval Count": release.intervalCount,
            "Name": release.name,
            "Password Slug": release.password,
            "Plan ID": release.planId,
            "Price": release.price,
            "Product ID": release.productId,
            "Role Removal": JSON.stringify(release.roleRemoval.filter(Boolean)),
            "Roles": JSON.stringify(release.roles.filter(Boolean)),
            "Stock": release.stock,
            "Upgrades": JSON.stringify(release.upgradeTo.filter(Boolean))
          });
        }
      }
      if (releaseArr.length > 0) {
        console.log(releaseArr)
        return exportCSVFile(headers, releaseArr, filename);
      } else {
        return bootbox.alert("Please select product(s) to export");
      }
    }

    else if (filename == "Cancels") {
      let headers = ["Date", "Name", "Email", "Discord", "Discord ID", "Key", "Reason", "Feedback"]
      let cancelArr = [];
      for (var i = 0; i < allOptions.length; i++) {
        let cancel = allOptions[i];
        var userObject = {
          date: new Date(cancel.timestamp * 1000).toLocaleDateString(),
          name: cancel.user.firstName ? `${cancel.user.firstName} ${cancel.user.lastName}` : "n/a",
          email: cancel.user.customerEmail,
          discordUsername: cancel.user.discordUsername ? cancel.user.discordUsername : "n/a",
          discordId: cancel.user.discordId,
          key: cancel.user.key,
          reason: cancel.reason,
          feedback: cancel.feedback
        };
        cancelArr.push(userObject);  
      }

      if (cancelArr.length > 0) {
        return exportCSVFile(headers, cancelArr, filename);
      } else {
        return bootbox.alert("Please select product(s) to export");
      }

    }

  }
  catch (err) {
    console.log(err);
  }
}


export const exportSpecificUser = (currentUser) => {
  let usersArr = [];
  let headers = ["Name", "Customer Email", "Customer ID", "Discord ID", "Key", "Referral Code", "Total Referrals"]
  var userObject = {
    "name": currentUser.firstName ? `${currentUser.firstName} ${currentUser.lastName}` : "n/a",
    "customerEmail": currentUser.customerEmail,
    "customerId": currentUser.customerId,
    "discordId": currentUser.discordId,
    "key": currentUser.key,
    "referral": currentUser.referral,
    "totalReferrals": currentUser.totalReferrals
  };
  // for (var i = 0; i < currentUser.subscriptions.length; i++) {
  //   headers.push(`${currentUser.subscriptions[i].id} Plan`)
  //   headers.push(`${currentUser.subscriptions[i].id} Roles`)
  //   userObject[`${currentUser.subscriptions[i].id}_Plan`] = currentUser.subscriptions[i].plan;
  //   userObject[`${currentUser.subscriptions[i].id}_Roles`] = JSON.stringify(currentUser.subscriptions[i].discordRoles.filter(Boolean))
  // }
  usersArr.push(userObject);
  return exportCSVFile(headers, usersArr, "user");
 
}



const CreateSidebar = () => {
  if ($(".sideshow").length) {
    $(".sideshow").on("click", function() {
      $(this).children("i").toggle();
      if ($(this).children("i").eq(0).css("display") == "none") {
        $("#wrapper").addClass("toggled");
        $(this).css("left", "235px");
      } else {
        $("#wrapper").removeClass("toggled");
        $(this).css("left", "-5px");
      }
    });
    
    window.setInterval(function() {
      if (!$("#wrapper").hasClass("toggled")) {
        $(".sideshow").css("left", "-5px");
      }
    }, 1);
  
    $(window).resize(function(){
      if ($(window).width() > 992) {
    
        // if ($(".sideshow").children("i").eq(0).css("display") != "none") {
        //   $(".sideshow").children("i").toggle();
        //   $("#wrapper").removeClass("toggled");
        //   $(".sideshow").css("left", "235px");
        // }
  
      }
    })
    return;
  } else {
    setTimeout(CreateSidebar, 50);
  }
}



