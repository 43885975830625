import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Dashboard from "./Screens/Dashboard";
import Login from "./Screens/Login";
import Register from "./Screens/Register";
import $ from "jquery"
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
$.fn.dataTable.ext.errMode = 'message';

const App = () => {
  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/dashboard/payments">
            <Dashboard page={"transactions"} />
          </Route>
          <Route path="/dashboard/payments">
            <Dashboard page={"transactions"} />
          </Route>
          <Route path="/dashboard/products">
            <Dashboard page={"releases"} />
          </Route>
          <Route path="/dashboard/rewards">
            <Dashboard page={"rewards"} />
          </Route>
          <Route path="/dashboard/coupons">
            <Dashboard page={"coupons"} />
          </Route>
          <Route path="/dashboard/users">
            <Dashboard page={"users"} />
          </Route>
          <Route path="/dashboard/feedback">
            <Dashboard page={"feedback"} />
          </Route>
          <Route path="/dashboard/posts">
            <Dashboard page={"posts"} />
          </Route>
          <Route path="/dashboard/discord">
            <Dashboard page={"discord"} />
          </Route>
          <Route path="/dashboard/stripe">
            <Dashboard page={"stripe"} />
          </Route>
          <Route path="/dashboard/pixels">
            <Dashboard page={"pixels"} />
          </Route>
          <Route path="/dashboard/team">
            <Dashboard page={"team"} />
          </Route>
          <Route path="/dashboard/settings">
            <Dashboard page={"settings"} />
          </Route>
          <Route path="/dashboard/account">
            <Dashboard page={"subscription"} />
          </Route>
          <Route path="/dashboard">
            <Dashboard page={"overview"} />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/">
            <Login />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}



export default App;
