import $ from "jquery"
import bootbox from "bootbox";
var couponTable;

// Pull 
export const getCoupons = (setData, setLoading) => {
    $.get("/api/dashboard/coupons",
    (data, status) => {
        if (status == "success") {
            if (data.success == true) {
                setData(data.coupons);
                setLoading(false);
                createCouponTable();
            } else {
                window.location.href = "/logout";
            }
        } else {
            window.location.href = "/logout";        
        }
    });
}


export const callAddCoupon = () => {
    $("#addCouponModal").modal('toggle');
}

export const createCoupon = (form, pullData) => {
    document.getElementById("coupon-add-form").getElementsByClassName("btn-main-type")[0].disabled = true;
    document.getElementById("coupon-add-form").getElementsByClassName("btn-main-type")[0].innerHTML = "...";
    $.ajax({
        url: "/api/dashboard/coupons",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(form),
        success: function (data) {
            if (data.success) {
                $("#addCouponModal").modal('toggle');
                document.getElementById("coupon-add-form").getElementsByClassName("btn-main-type")[0].disabled = false;
                document.getElementById("coupon-add-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Add Product";
                pullData();
                bootbox.alert("Successfully Added Coupon");
            } else {
                $("#couponAddErr").text(data.error);
                $("#couponAddErr").show();
                $("#couponAddErr").delay(5000).hide(1);
                document.getElementById("coupon-add-form").getElementsByClassName("btn-main-type")[0].disabled = false;
                document.getElementById("coupon-add-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Add Product";
            }
        }
    }).fail(function (jqXHR, textStatus, error) {

        $("#couponAddErr").text("Failed request... Please try again");
        $("#couponAddErr").show();
        $("#couponAddErr").delay(5000).hide(1);
        document.getElementById("coupon-add-form").getElementsByClassName("btn-main-type")[0].disabled = false;
        document.getElementById("coupon-add-form").getElementsByClassName("btn-main-type")[0].innerHTML = "Add Product";
    });
}



export const callDeleteCoupon = (pullCoupons) => {
    var couponId;
    var selectedCoupons = document.getElementsByClassName("checkbox-input-coupon");
    for (var i = 0; i < selectedCoupons.length; i++) {
        if (selectedCoupons[i].checked == true) {
            let row = selectedCoupons[i].parentNode.parentNode.parentNode
            console.log(row);
            couponId = row.getAttribute("id");
            break;
        }
    }

    if (couponId) {
        bootbox.confirm({
            message: `Are you sure you want to delete coupon`,
            centerVertical: true,
            buttons: {
                confirm: {
                    label: 'Yes',
                    className: 'btn-main-type'
                },
                cancel: {
                    label: 'No',
                    className: 'btn-second-type'
                }
            },
            callback: function (result) {
                if (result == true) {
                    $.ajax({
                        url: `/api/dashboard/coupons/${couponId}`,
                        type: "DELETE",
                        contentType: 'application/json',
                        success: function (data) {
                            if (data.success) {
                                bootbox.alert("Successfully Deleted Coupon", () => {
                                    pullCoupons();
                                })
                            } else {
                                bootbox.alert(data.error, () => {
                                    pullCoupons();
                                });
                            }
                        }
                    }).fail(function (jqXHR, textStatus, error) {
                        bootbox.alert("Failed request... Please try again", () => {
                            window.location.reload();
                        });
                    });
                }
            }
        })
        return;
    } else {
        bootbox.alert("Please select coupon you would like to delete");
    }
};


// Function to create datatable for coupon
const createCouponTable = (data) => {
    if ($("#couponTable").length) {
        couponTable = $('#couponTable').DataTable({
            ordering: false,
            lengthChange: false,
            initComplete: function (settings, json) {
                $('body').find('.dataTables_scrollBody').addClass("scrollbar");
                $("#couponTable").wrap("<div class='userTableScroll' style='overflow:auto; width:100%;position:relative;'></div>");
            }
        });
    
        $("#coupon-search").on("keyup change", function () {
            couponTable.search(this.value).draw();
        });

      
      

    } else {
        setTimeout(createCouponTable, 50);
    }
}